import { comma } from "../../../utils/util";
import { TagStockStatus } from "../../badge-and-tag";

export const myModalStockNormalColumn = () => [
    // {
    //     title: "รหัสสินค้า",
    //     dataIndex: "stcode",
    //     key: "stcode",
    //     width: 100, 
    //     align: "left",
    //     sorter: true, 
    //     render: (v) => String(v)?.replace("/VAT", "")
    // },
    // {
    //     title: "ชื่อสินค้า",
    //     dataIndex: "stname",
    //     key: "stname", 
    //     align: "left",
    //     sorter: true,  
    // },    
    {
        title: "สถานที่เก็บ",
        dataIndex: "location_name",
        key: "location_name", 
        align: "left",
        sorter: true, 
        width: 140,
    }, 
    {
        title: "ราคาซื้อเฉลี่ย",
        dataIndex: "price",
        key: "price", 
        align: "right",
        sorter: true, 
        width: 120, 
        onCell: (_) => {
            return { className: '!pe-5'}
        },
        render:(v) => comma(Number( v || 0), 2, 2),
    },
    {
        title: "สต๊อก",
        dataIndex: "location_qty",
        key: "location_qty",
        align: "left",
        sorter: true,
        width: 120,
        render:(v) => <TagStockStatus result={v} />
    },  
];

export const myModalStockTiresColumn = () => [ 
    {
      title: "สถานที่เก็บ",
      dataIndex: "location_name",
      key: "location_name", 
      align: "left",
      sorter: true, 
      width: 140,
    }, 
    {
      title: "ปีที่ผลิต",
      dataIndex: "mfyear",
      key: "mfyear",
      width: 90,
      sorter: true,
      render:(v) => !!v && v !== 'n/a' ? v : "N/A"
    },
    {
      title: "ไตรมาส",
      dataIndex: "quarter",
      key: "quarter",
      width: 90,
      render:(v) => !!v && v !== 'n/a' ? `Q${v}` : "N/A"
    },  
    {
      title: "สต๊อก",
      dataIndex: "tires_qty",
      key: "tires_qty",
      align: "left",
      sorter: true,
      width: 120,
      render:(v) => <TagStockStatus result={v} />
    },
    {
      title: "ราคาซื้อเฉลี่ย",
      dataIndex: "price",
      key: "price",
      align: "right",
      sorter: true,
      width: 100,
      onCell: (_) => {
        return { className: '!pe-5'}
      },
      render:(v) => comma(Number( v || 0), 2, 2),
    },
    // {
    //     title: "รหัสสินค้า",
    //     dataIndex: "stcode",
    //     key: "stcode",
    //     width: 100, 
    //     align: "left",
    //     sorter: true, 
    // },
    // {
    //   title: "ชื่อสินค้า",
    //   dataIndex: "stname",
    //   key: "stname", 
    //   align: "left",
    //   sorter: true,  
    // },  
];

export const myModalStockVatNormalColumn = () => [
    {
        title: "สต๊อก",
        dataIndex: "location_qty",
        key: "location_qty",
        align: "left",
        sorter: true,
        width: 120,
        render:(v) => <TagStockStatus result={v} />
    },  
];

export const myModalStockVatTiresColumn = () => [
    {
      title: "ปีที่ผลิต",
      dataIndex: "mfyear",
      key: "mfyear",
      width: 90,
      sorter: true,
      render:(_, v) => v.mfyear
    },
    {
      title: "ไตรมาส",
      dataIndex: "quarter",
      key: "quarter",
      width: 90,
      render:(v) => `Q${v}`
    },  
    {
      title: "สต๊อก",
      dataIndex: "tires_qty",
      key: "tires_qty",
      align: "left",
      sorter: true,
      width: 120,
      render:(v) => <TagStockStatus result={v} />
    },  
];