/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Divider, Drawer, Flex, Form, Popover } from 'antd';
import { Input, InputNumber, message, Radio, Row, Spin, Table, Typography } from 'antd';
import React from 'react'; 
import { TableSearchValue } from '../../form';
import { columnPayment  } from './model.moule';
import { CloseOutlined } from '@ant-design/icons';

import "./MyDrawer.css";
import { comma } from '../../../utils/util';
import OptionService from "../../../service/Options.service.js"; 
import BillingPaymentService from "../../../service/BillingPayment.service.js"; 
import { CancelButton } from '../cancel/index.js';
import { BillingPaymentButtonView } from '../billing-payment/index.js';



const emptyText = { emptyText:<span>ไม่มีข้อมูลรายการ</span> };
const dividerProp = { orientation:"left", style:{marginBlock:10}, className:'!border-black' }; 

const optrequest = OptionService();
const apirequest = BillingPaymentService();
export default function MyDrawerView({source, show, value, close, getContainer=null}) {
    const [form] = Form.useForm();

    const [openDrawer, setOpenDrawer] = React.useState(show); 
    const [dataHeader, setDataHeader] = React.useState({}); 
    const [dataList, setDataList] = React.useState([]); 
    

    const [loading, setLoading] = React.useState(false);  

    const [drawerWidth, setDrawerWidth] = React.useState(75);
    const [payRecordSelect, setPayRecordSelect] = React.useState([]);
    const container = () => document.getElementById("billing-payment");

    const containerStyle = {
      position: 'relative', 
      height: 'calc( 100% - 24px )'
    };



    const headStyle = {
        borderBottom: `2px solid var(--success)`,
        backgroundColor: `var(--success)`, 
    }

    const bodyStyle = {
        border: `2px solid var(--success)`
    }

    const footStyle = {
        borderTop: `2px solid var(--success)`, 
    } 

    const handleCancel = () => {
        setPayRecordSelect( [] );

        setOpenDrawer(false); 
    } 
 

    const handleSetMessage = (content, option={}) => ({content: content, getContainer: container, ...option})

    const handleConfigMessageNoti = () => {
        message.config({
          top: 8,
          prefixCls: 'my-message',
          getContainer: container
        });
    } 

    const initeial = async() => {
        const { rpocode } = source || {};
        const res = await optrequest.optionsBillingPayment({p:"repair-order-bill", code:rpocode});

        setDataList( res.data.data );
        setPayRecordSelect([]); 
        // console.log( source );
    }

    React.useEffect( () => { 
        initeial(); 
    }, [source])
    
    // Update width on window resize
    React.useEffect(() => {
        const updateDrawerWidth = () => {
            if (window.innerWidth < 600) {
                setDrawerWidth(100);
            } else if (window.innerWidth < 1024) {
                setDrawerWidth(75);
            } else {
                setDrawerWidth(60);
            }
        };
        updateDrawerWidth();
        window.addEventListener('resize', updateDrawerWidth);

        handleConfigMessageNoti();
        return () =>  {
            window.removeEventListener('resize', updateDrawerWidth);
            message?.destroy();
        }
    }, []);
 
    const handlePaymentRecord = (rec) => {
        // console.log( rec );
        setPayRecordSelect( rec );
    }

    const handleCustom = ( key, rec, iconStyle, bntStyle ) => {
        // console.log( rec );
        return (<>
            <Popover
                placement="topLeft" 
                title={"แนะนำการใช้งาน"}
                content={!payRecordSelect ? "กรุณาเลือกรายการชำระก่อน" : "" }
                arrow={{ pointAtCenter: true }} 
                trigger={"hover"}
                
            >
                <BillingPaymentButtonView
                    selects={payRecordSelect} 
                    disabled={ payRecordSelect?.length < 1 || !payRecordSelect  } 
                    value={()=> {}}
                    close={(v)=>{
                        // console.log( payRecordSelect )
                        
                        initeial();
                    }}
                />
            </Popover> 
        </>)
    }   

    const field_infomation = (<>
        <Card style={{ backgroundColor: '#f3f4f6'}} id="billing-payment-head-list">
            <TableSearchValue
            title='รายการชำระ' 
            // notSelect={true}
            onSelectedRow={handlePaymentRecord}
            onCustom={handleCustom}
            clears={payRecordSelect}
            tbProps={{
                id:"tb-rpo-payment",
                rowKey:"id",
                dataSource:dataList,
                columns:columnPayment(),
                pagination: false,
                locale:{...emptyText, emptyText:<span>ไม่มีข้อมูลการชำระเงิน</span>},
                // summary: (rec) => totalPayment(rec)
            }}
        />
        </Card>
    </>);

    const totalPayment = (rec) => {
        if(rec?.length < 1 ) return <></>
        // console.log( rec )
        // const { payment_totals, payment_remain, payment_amount } = rec;
        const amount = rec?.reduce( (a, v) => a += Number(v?.payment_amount || 0), 0 ) ;
        const remain = Number( source?.price_total ) - amount;
        // const totals = rec?.reduce( (a, v) => a += Number(v?.payment_totals || 0), 0 ) ;
        return (<>
        <Table.Summary.Row> 
            <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดที่ต้องชำระ</Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text className='text-black'>{ comma(Number( source?.price_total ),2,2) }</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row> 
        <Table.Summary.Row> 
            <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>{remain < 0 && <span className='text-red-500 font-thin'>( ชำระเกิน )</span>} รวมยอดชำระ</Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text type="danger">{ comma(amount,2,2) }</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row> 
        <Table.Summary.Row> 
        { remain >= 0 
            ? <>
                <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดคงเหลือ</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text className='text-red-500'>{ comma(remain,2,2) }</Typography.Text>
                </Table.Summary.Cell>          
            </>
            : <>
                <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดชำระเกิน</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text className='text-green-700'>{ comma(remain * (-1),2,2) }</Typography.Text>
                </Table.Summary.Cell>          
            </>
        }
        </Table.Summary.Row> 
        </>)
    }

    const footer = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    {/* <CancelButton value={handleRemove} /> */}
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button className='bn-center bn-secondary-outline min-w-24' onClick={() => handleCancel()} >ปิด</Button>
                    {/* <Button className={`bn-center bn-success-outline min-w-24`} onClick={()=>handleConfirm()} >ยืนยันการชำระ</Button> */}
                </Flex>
            </Col>
        </Row>
    </>);

    return (
        <>
            <Drawer
                title={<Flex  gap={3}>
                    <Typography.Text className='text-white m-0'>การชำระเงิน</Typography.Text>
                    { !!source?.payment_code && <Typography.Text className='text-white m-0'>เลขที่ใบวางบิล {source?.payment_code}</Typography.Text>}
                </Flex>}
                closeIcon={<CloseOutlined className='text-white' />}
                onClose={()=>handleCancel()}
                open={openDrawer}
                key="repair-order-billing-payment"
                id="repair-order-billing-payment"
                footer={footer}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                width={`${drawerWidth}%`}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( false );  
                    }
                }}
                maskClosable={false}
                getContainer={() => (!!getContainer && typeof getContainer === "function") ? getContainer() : false}
                push={false}
            >
                <div id="repair-order-billing-payment-main" style={containerStyle}> 
                    <Spin spinning={loading}> 
                        <Divider {...dividerProp}>ข้อมูลรายการ</Divider>
                        {field_infomation}           
                    </Spin>
                </div>
            </Drawer>
        </>
    )
}
