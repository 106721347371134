/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Modal, Card, Form, Typography, Flex, Button, Drawer } from "antd";
import { Row, Col, Space } from "antd";
// import { Input } from "antd";
import { TbArrowBack, TbBuildingWarehouse,  TbShoppingCartCopy, TbTransferIn } from "react-icons/tb";
import { TableSearchValue } from '../../form/index.js';
import OptionService from '../../../service/Options.service.js';
// import { v4 as uuidv4 } from 'uuid';

import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { myModalItemsColumn } from "./model.module.js";
// import { TagIs } from '../../badge-and-tag/index.js';
import MyModalItemAmount from './MyModalItemAmount.jsx';
import { SelectStockLocation } from '../../select/index.js';

const optrequest = OptionService();
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };


export default function MyModalItems({show, close, values, hasQt = true, selected={}}) {
    // const plainOptions = [
    //     {label:<TagIs result={1} />, value: 1 },
    //     {label:<TagIs result={0} />, value: 0 },
    // ];
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    };

    const context = useTbSearchContext();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const [loading,  setLoading] = React.useState(true); 

    const [openModal, setOpenModal] = React.useState(show);
    const [openAmountSelected, setOpenAmountSelected] = React.useState(false);
    const [isAmountOpened, setIsAmountOpened] = React.useState(false);

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    // const [optionsLocation, setOptionsLocation] = React.useState([]);

    const [dataSource, setDataSource] = React.useState([]); 
    const [dataSelect, setDataSelect] = React.useState([]); 
    // const [resultStock, setResultStock] = React.useState([]);

    const [inited, setInited] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    // const [checkedList, setCheckedList] = React.useState([]); 

    const keySelected = React.useMemo(() => {

      return selected.stcode;
    }, [selected]); 

    // const onChange = (list) => {
    //   setCheckedList(list);
    // };
 
    const handleClose = () =>{ 
        // setOpenModal(false);
        // close(false)
        setTimeout( () => { close(false);  }, 240);
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (_, criteria) => { 
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = async (record, key) => { 
        const keySelect = record.map( m => m?.key_code );
        const rowlost = dataSelect.find( f => !keySelect.includes( f.key_code ) );
        if( !!rowlost && Number( rowlost?.qty || 0 ) > 0 ) {
            const result = await modal.confirm({title: 'จำนวนสินค้าที่กรอกไปแล้วจะถูกยกเลิก', content: 'ยืนยันที่จะทำรายการต่อหรือไม่'});
            if( !result ){
                context.clickSelect( 'key_code', dataSelect, true );
                return;
            } 
        }
        // console.log( record, key );
        if( record?.length === 1 ){
            const newRecord = record?.map( m => ({ ...m, qty:  Number(selected?.amount || 0) }));
            setDataSelect([...newRecord]);  
        } else {
            const newRecord = record?.map( m => ({ ...m, qty: m?.qty || 0 }));
            setDataSelect([...newRecord]); 
        }
    }

    const handleChoosed = () => {
        const qty = dataSelect?.reduce( (a,v) => a += Number( v.qty ), 0);
        // console.log( qty );
        if( qty !== Number(selected?.amount) && hasQt ){ 
            Modal.error( { content: "จำนวนที่กรอกยังไม่เพียงพอสำหรับที่ต้องการใช้" } );
            return;
        }
        
        typeof values === "function" && values( dataSelect ); 
        setOpenModal(false);
    } 

    const handleOpenAmountItems = () => {
        setOpenAmountSelected(true);
        setIsAmountOpened(true); 
    }

    const handleCheckStock = () => {
        const { stock, amount } = selected;
        if( !!stock && stock?.length > 0 && !checked ){
          context.clickSelect( 'key_code', stock, true );
        
          if( stock?.length === 1 ){
            setDataSelect( stock.map( m =>({...m,  qty:  Number(amount)}) ) );
          }else {
            setDataSelect( stock );
          }
          handleOpenAmountItems();
          setChecked(true);
        }
    }

    const handleSearch = React.useCallback( async () => {
        if( !inited )  return;
      const parm = { criteria:{ ...criteria, stcode:keySelected }, tbparams, p:"stock-items" };
      setLoading(true);
      try { 
        const res = await optrequest.optionsStockPost(parm, { ignoreLoading:true } )
        const { data:{ source, tbparams } } = res.data;
        
        setPageValue(tbparams?.pagination);
        // setPaing( state => ( {...state, ...pagination }) );
        setDataSource(source);

        setTimeout( () => { 
            handleCheckStock();
            setLoading(false) ;
        }, 200 );
      } catch(err) {
        setTimeout( () => setLoading(false), 200 );
      } 
    }, [criteria, tbparams]);

    const handleAmountChange = (v) => {
        const newSource = [...v];
        // setResultStock([...newSource]);
        setDataSelect([...newSource]);
    }

    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);
 

    React.useEffect(() => {
        const initeil = async() => {
            // const [locationRes ] = await Promise.all([
            //     optrequest.optionsStock({p:"location-stock-with-item", code:keySelected})
            // ]);
            // setOptionsLocation( locationRes.data.data ); 

            setCriteria({stcode: keySelected});
            setInited(true); 
        } 
        initeil();     


        return () => {  setInited(false) };
    }, []);

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbBuildingWarehouse style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>เลือกสต๊อกสินค้า</Typography.Text>
        </Flex>
    </>);

    const modalFooter = (<> 
        <Row>
          <Col span={12}>{
            openAmountSelected && <Button
                className="bn-center bn-secondary items-center"
                icon={<TbArrowBack style={{fontSize:'1rem'}} />}
                style={{ width: 160 }}
                onClick={() => setOpenAmountSelected(false)}
            >กลับ</Button>
          }</Col>
          <Col span={12}>
            <Flex justify="flex-end">{
                !openAmountSelected
                ?(
                    <Button
                        className="bn-center bn-primary items-center"
                        icon={<TbTransferIn style={{fontSize:'1rem'}} />}
                        style={{ width: 160 }}
                        onClick={() => handleOpenAmountItems()}
                        disabled={dataSelect.length < 1}
                    >ยืนยันเลือกสต๊อก</Button>                    
                )
                :(
                    <Button
                        className="bn-center bn-success items-center"
                        icon={<TbShoppingCartCopy style={{fontSize:'1rem'}} />}
                        style={{ width: 160 }}
                        onClick={() => handleChoosed()}
                    >ยืนยันการทำรายการ</Button>
                )
              }
            </Flex>
          </Col>
        </Row>
    </>);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>

            <Col xs={24} sm={24} lg={24}> 
                <Form.Item label='สถานที่เก็บ' name='location'>
                    <SelectStockLocation keySelected={keySelected} />
                </Form.Item>                     
            </Col> 

            {/* <Col xs={24} sm={24} lg={10}>
                <Form.Item label="สต๊อก vat" name='vat' > 
                    <Checkbox.Group options={plainOptions} value={checkedList} onChange={onChange} className='modal-checkbox' name='vat' />
                </Form.Item>
            </Col>  */}
        </Row>   
    </>);

    return (<> 
    <div className='modal-suppliers' id="modal-area"> 
        <Modal
            open={openModal}
            title={modalTitle}
            afterClose={() => handleClose() }
            onCancel={() => setOpenModal(false) } 
            maskClosable={false}
            // style={{ top: 10, height: 'calc(100vh - 10px)' }}
            style={{ top: 10 }}
            width={1060}
            className='mymodal'
            footer={modalFooter}
            // containerStyle={{ height: 'calc(100vh - ( 10px * 2) )' }}
        >
            <div style={containerStyle}>  
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                            {modalSearch}
                        </Form>
                    </Card>
                    <Card style={{minHeight:490}}>
                        <TableSearchValue 
                            title='รายการข้อมูลสินค้า'
                            multi={true}
                            pagingDefault={tbparamsDefault?.pagination}
                            onPageChange={(page)=>setTbparams(page)}
                            onSelectedRow={handleRowSelect}
                            tbProps={{
                                rowKey:"key_code",
                                dataSource:dataSource,
                                columns:myModalItemsColumn(),
                                pagination: pageValue,
                                locale:{...emptyText},
                                loading
                            }}
                        />
                    </Card>
                </Space>      
                {isAmountOpened && 
                    <Drawer
                        title={`สต๊อกที่เลือก ${dataSelect.length} รายการ`}
                        onClose={() => { setOpenAmountSelected(false); }}
                        open={openAmountSelected} 
                        width='100%'
                        className="responsive-drawer" 
                        afterOpenChange={(e)=>{ !e && setIsAmountOpened(false); }}
                        getContainer={false}
                    >
                        <MyModalItemAmount master={selected} list={dataSelect} change={handleAmountChange} hasQt={hasQt} />
                    </Drawer>
                } 
            </div>
        </Modal>
    </div>
    {contextHolder}
    </>)
}