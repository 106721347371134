import { message, Select, Tag, Tooltip } from 'antd';
import React from 'react';
// import { filterOption } from '../../../utils/util';
import { TbLocationSearch } from 'react-icons/tb'; 
import { v4 as uuidv4 } from 'uuid';
import OptionService from "../../../service/Options.service";

const optrequest = OptionService();
export default function MySelect({ name="location", keySelected = undefined, onChange, id=null, style={}, className="" }) {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() =>{ 
    const init = async () => {
      try{
        const [ optionsRes ] = await Promise.all([
          optrequest.optionsStock({p:"location-stock-with-item", code:keySelected}),
        ]);

        setOptions(optionsRes.data.data)
      } catch ( e ){
        console.log(e);
        message.error("เกิดข้อผิดพลาดจากการดึงข้อมูลรุ่นรถยนต์");
      } 
    } 
    init(); 
  }, [keySelected]);

  const handleChange = (value, option) => {
    console.log(value, option)
    if( typeof onChange  === "function") {
      onChange(value, option);
    }
  }

  return (
  <Select
      mode='multiple'
      allowClear
      autoClearSearchValue={false}
      id={ id || "select-location" } 
      tagRender={(props) => {
        const res = { ...props, isMaxTag: 1 };
        delete res.isMaxTag;
        const newProp = {...res, ismaxtag: 1};
        // console.log( {newProp} );
        return <Tag { ...newProp  } color='var(--primary)' icon={<TbLocationSearch />} className='flex gap-1 items-center' >{props.label}</Tag>}
      }      
      style={{...{ width: '100%', height: '50px' }, ...style}}
      placeholder="เลือกสถานที่เก็บ"
      maxTagCount= 'responsive'
      options={options}
      suffixIcon={<TbLocationSearch style={{fontSize:'1rem', color:'#000'}} />}
      getPopupContainer={() => document.getElementById('modal-area')}
      maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={
            <div className='flex flex-wrap gap-1'>
                {omittedValues.map(({ label, i }) => (
                    <Tag color='var(--primary)' key={uuidv4()} icon={<TbLocationSearch />} className='flex gap-1 items-center' >{label}</Tag>
                ))} 
            </div>
          }
            color='white'
            placement="bottomRight"
          >
            <Tag color='var(--primary)' icon={<TbLocationSearch />} className='flex gap-1 items-center m-0' >{omittedValues.length}+</Tag>
          </Tooltip>
      )}
      onChange={handleChange}
      className={className}
  ></Select>)
}
