/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Input, Space, Select,Badge } from "antd";
import { QctPageLayout } from "../../components/layout";
import { FormSearchValue, TableSearchValue } from "../../components/form";
// import { Typography } from 'antd';
import ItemService from "../../service/Item.service";

import { accessColumn } from "./model";
import useSearch from "../../store/hook/use-search.hook";

const itemService = ItemService();

function ItemsAccess() {
  const navigate = useNavigate();

  const stSearch = useSearch("items-access");
  const { criteria: cria, tbparams: parm } = stSearch.gettingSearch();

  const tbparamsDefault = {
    ...parm,
    pagination: { ...{ current: 1, pageSize: 10 }, ...parm?.pagination },
  };
  const criteriaDefault = cria || {};
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const [criteria, setCriteria] = useState({ ...criteriaDefault });

  const [tbparams, setTbparams] = useState({ ...tbparamsDefault });
  const [pageValue, setPageValue] = useState({ ...tbparamsDefault.pagination });

  const formSearch = (
    <FormSearchValue
      title="ค้นหา"
      onValues={(value) => handleCriteria(value)}
      defaultValue={criteriaDefault}
    >
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสสินค้า" name="stcode">
          <Input placeholder="ใส่รหัสสินค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อสินค้า" name="stname">
          <Input placeholder="ใส่ชื่อสินค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="ใส่ประเภทสินค้า" name="typename">
          <Input placeholder="ใส่ประเภทสินค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="ยี่ห้อรถ" name="stcar_brand">
          <Input placeholder="ใส่ยี่ห้อรถ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="รุ่นรถ" name="stcar_model">
          <Input placeholder="ใส่รุ่นรถ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="สถานะการใช้งาน" name="active_status">
        <Select
              size="large"
              options={[
                {
                  value: "Y",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "N",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams((page) => ({
      ...page,
      pagination: { ...page?.pagination, current: 1 },
      current: 1,
    }));
  };

  const handlePaging = (page) => {
    setTbparams(page);
    // setTbparams(page.pagination);
  };

  const handleSearch = () => {
    const parm = { criteria, tbparams };
    itemService
      .search(parm, { ignoreLoading: Object.keys(parm.criteria).length !== 0 })
      .then((res) => {
        const {
          data: { source, tbparams },
        } = res.data;

        stSearch.updateSearch(parm);
        setPageValue(tbparams?.pagination);
        // setPaing( state => ( {...state, ...pagination }) );
        setDataSource(source);

        const { order, field } = tbparams;
        setTimeout(() => {
          setColumns((state) => stSearch.columnOrder(state, order, field));
        }, 80);
      })
      .catch((e) => console.dir(e));
  };

  const handleRowSelect = (record, key) => {
    // console.log(record, key);
  };

  const handleCreatData = () => {
    navigate("manage/create", {
      state: { config: { mode: "create", acname: "สร้าง" } },
    });
  };

  const handleUpdateData = (keys) => {
    navigate("manage/edit", {
      state: { config: { mode: "edit", acname: "แก้ไข", code: keys } },
    });
  };

  useEffect(() => {
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  useEffect(() => {
    const init = () => {
      const { order, field } = tbparamsDefault;
      // console.log( storeColumn );
      const cols = accessColumn();
      let newCol = cols;
      if (!!field) {
        newCol = cols.map((col) => {
          if (col.key === field) col["sortOrder"] = order;
          else delete col["sortOrder"];
          return col;
        });
      }

      const storeColumn = stSearch.gettingColumn();
      if (!!storeColumn && storeColumn?.length > 0) {
        newCol = newCol?.map((m) => {
          const newHide = storeColumn?.find(
            (f) => m?.hide !== undefined && f?.key === m?.key
          );
          // console.log( {newHide, m} )
          if (!!newHide) {
            return { ...m, hide: !!newHide?.hide };
          } else return m;
        });
      }
      // console.log( newCol );
      setColumns(newCol);
      // console.log(  order, field  );
    };

    init();

    return () => {};
  }, []);

  return (
    <>
      <QctPageLayout title={"ระบบสินค้า"}>
        <Space direction="vertical" className="flex gap-4">
          {formSearch}
          <TableSearchValue
            title="รายการระบบสินค้า"
            pagingDefault={tbparamsDefault?.pagination}
            onPageChange={handlePaging}
            onSelectedRow={handleRowSelect}
            onCreate={handleCreatData}
            onUpdate={handleUpdateData}
            store={stSearch}
            tbProps={{
              rowKey: "stcode",
              dataSource: dataSource,
              columns: columns,
              pagination: pageValue,
            }}
          />
        </Space>
      </QctPageLayout>
    </>
  );
}

export default ItemsAccess;
