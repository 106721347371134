/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Card, Descriptions } from "antd";
import { Space } from "antd"; 
import { TableSearchValue } from '../../form/index.js';
import OptionService from '../../../service/Options.service.js';
// import { v4 as uuidv4 } from 'uuid'; 
import { columnStock, totalValue } from "./model.module.js";
// import { TagIs } from '../../badge-and-tag/index.js';

const optrequest = OptionService();

const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };


export default function AppFormStocks({selected={}}) {
    // const plainOptions = [
    //     {label:<TagIs result={1} />, value: 1 },
    //     {label:<TagIs result={0} />, value: 0 },
    // ];
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    };
 
    
    const [loading,  setLoading] = React.useState(true); 
  
    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    // const [optionsLocation, setOptionsLocation] = React.useState([]);

    const [dataSource, setDataSource] = React.useState([]);
    const [dataValue, setDataValue] = React.useState([]);
    // const [resultStock, setResultStock] = React.useState([]);

    const [inited, setInited] = React.useState(false); 

    // const [checkedList, setCheckedList] = React.useState([]); 

    const keySelected = React.useMemo(() => {

      return selected.stcode;
    }, [selected]);
 

    const handleSearch = React.useCallback( async () => {
        if( !inited )  return;
      const parm = { criteria:{ ...criteria, stcode:keySelected }, tbparams, p:"stock-items" };
      setLoading(true);
      try { 
        const res = await optrequest.optionsStockPost(parm, { ignoreLoading:true } )
        const { data:{ source, tbparams } } = res.data;
        
        const total_stock = source?.reduce( (a,v) => a += Number( v?.location_qty ), 0 );
        const total_stock_vat = source?.length > 0 ? source[0].stock_vat_qty : 0;

        setDataValue( { total_stock, total_stock_vat });

        setPageValue(tbparams?.pagination);
        // setPaing( state => ( {...state, ...pagination }) );
        setDataSource([...source]);

        // console.log( source );
        setTimeout( () => { 
            setLoading(false) ;
        }, 200 );
      } catch(err) {
        setTimeout( () => setLoading(false), 200 );
      } 
    }, [criteria, tbparams]);
 
    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);
 

    React.useEffect(() => {
        const initeil = async() => {
            // const [locationRes ] = await Promise.all([
            //     optrequest.optionsStock({p:"location-stock-with-item", code:keySelected})
            // ]);
            // setOptionsLocation( locationRes.data.data ); 

            setCriteria({stcode: keySelected});
            setInited(true); 
        } 
        initeil();     


        return () => {  setInited(false) };
    }, []);

    const handleAdjust = (r) => {
        console.log( r );
        if( !!r ) handleSearch();
    }
 
    const sectionSummary = (<> 
        <Descriptions 
            title={false} 
            colon={false} 
            layout="vertical"  
            size='small' 
            column={{xs:1, sm:2, md:2, lg:2, xl:5, xxl:5}} 
            items={ totalValue(selected, dataValue, dataSource, handleAdjust) } 
        />
    </>);

    return (<> 
    <div className='modal-suppliers' id="modal-area">  
        <div style={containerStyle}>  
            <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                <Card style={{backgroundColor:'#f0f0f0' }}>
                    { sectionSummary }
                </Card>
                <Card style={{minHeight:490}}>
                    <TableSearchValue 
                        title='รายการข้อมูลสินค้า'
                        multi={true}
                        pagingDefault={tbparamsDefault?.pagination}
                        onPageChange={(page)=>setTbparams(page)} 
                        notSelect={true}
                        tbProps={{
                            rowKey:"key_code",
                            dataSource:dataSource,
                            columns:columnStock(),
                            pagination: pageValue,
                            locale:{...emptyText},
                            loading
                        }}
                    />
                </Card>
            </Space> 
        </div> 
    </div>
    </>)
}