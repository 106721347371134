import dayjs from "dayjs";
const cellIsManage = () => ({
  onCell: (recoed, index) => ({
    className: `${Number( recoed?.ismanage || 0 ) === 0 ? 'text-gray-500' : ''}`,
  })
});

export const myModalColumn = () => [
    {
        title: "รูปแบบการชำระ",
        dataIndex: "payment_name",
        key: "payment_name", 
        align: "left",
        ...cellIsManage(),
        sorter: true, 
    },
    {
        title: "ประเภทการชำระ",
        dataIndex: "payment_type",
        key: "payment_type", 
        align: "left",
        ...cellIsManage(),
        sorter: true,
    },
    {
        title: "สร้างโดย",
        dataIndex: "created_name",
        key: "created_name", 
        width: "18%",
        align: "left",
        ...cellIsManage(),
        sorter: true,
    },
    {
        title: "วันที่สร้าง",
        dataIndex: "created_date",
        key: "created_date", 
        width: "18%",
        align: "left",
        ...cellIsManage(),
        sorter: true,
        render: (v) => dayjs(v).format("DD/MM/YYYY")
    }
];
 