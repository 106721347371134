import { Flex, Tag } from "antd";
import { TbBasketFilled } from "react-icons/tb";
// import dayjs from "dayjs";
import { TagStockStatus } from "../../badge-and-tag";
import { comma } from "../../../utils/util";
export const myDrawerColumn = () => [
    {
        title: "รหัสสินค้า",
        dataIndex: "stcode",
        key: "stcode",
        width: 90, 
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อสินค้า",
        dataIndex: "stname",
        key: "stname", 
        align: "left",
        sorter: true,  
    },    
    {
        title: "รหัสสินค้า VAT",
        dataIndex: "stcode_vat",
        key: "stcode_vat",
        width: 90, 
        align: "left",
        sorter: true,
        render: (v) => v?.replace("/VAT", "")
    },
    {
        title: "ชื่อสินค้า VAT",
        dataIndex: "stname_vat",
        key: "stname_vat", 
        align: "left",
        width: 180,
        wrap: true,
        onCell: () => {
            return ({
                style: {textWrap: 'auto'}
            })
        },
        sorter: true, 
    },    
    // {
    //     title: "ชื่อสินค้าสำหรับผู้ขาย",
    //     dataIndex: "material_code",
    //     key: "material_code", 
    //     align: "left",
    //     sorter: true, 
    //     width: 100,
    // },
    {
        title: "สต๊อก",
        dataIndex: "stock_qty",
        key: "stock_qty", 
        align: "left",
        sorter: true, 
        width: 80,
        render:(v) => <TagStockStatus result={v} />
    },
    {
        title: "สต๊อก VAT",
        dataIndex: "stock_vat_qty",
        key: "stock_vat_qty", 
        align: "left",
        sorter: true, 
        width: 80,
        render:(v) => <TagStockStatus result={v} />
    },
    {
        title: "ราคาเฉลี่ยต่อหน่วย",
        dataIndex: "price_balance",
        key: "price_balance", 
        align: "right", 
        width: 100,
        className: '!pe-3',
        render:(v) => comma(Number(v || 0))
    },
    {
        title: "ประเภทสินค้า",
        dataIndex: "typename",
        key: "typename",
        width: 180, 
        align: "left",
        sorter: true,
        render: (data) => (
        <Flex>
            <Tag icon={<TbBasketFilled />} color="var(--primary)" className="m-0 flex items-center gap-1">
                {data}
            </Tag>    
        </Flex>
        ),
    },   
];


export const myDrawerItemsColumn = () => [
    {
        title: "รหัสสินค้า",
        dataIndex: "stcode",
        key: "stcode",
        width: '20%', 
        align: "left",
        sorter: true, 
    },
    {
        title: "รหัสสินค้า VAT",
        dataIndex: "stcode_vat",
        key: "stcode_vat",
        width: '20%', 
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อสินค้า",
        dataIndex: "stname",
        key: "stname", 
        align: "left",
        sorter: true,  
    },     
    // {
    //     title: "สต๊อก",
    //     dataIndex: "stock_qty",
    //     key: "stock_qty", 
    //     align: "left",
    //     sorter: true, 
    //     width: 80,
    //     render:(v) => <TagStockStatus result={v} />
    // },
    {
        title: "สต๊อก VAT",
        dataIndex: "stock_vat_qty",
        key: "stock_vat_qty",
        align: "left",
        sorter: true, 
        width: '15%',
        render:(v) => <TagStockStatus result={v} />
    },
    // {
    //     title: "ราคาเฉลี่ยต่อหน่วย",
    //     dataIndex: "price_balance",
    //     key: "price_balance", 
    //     align: "right", 
    //     width: 100,
    //     className: '!pe-3',
    //     render:(v) => comma(Number(v || 0))
    // },    
]