/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Modal, Card, Form, Typography, Flex, Divider, Descriptions, Row, Col, Button, message, Spin, Table, Popover } from "antd";
import { Space } from "antd";

import { carInfo, columnView, columnViewDetail, columnViewDetailEditable, cusItem, infoVat, stockVat, vildateListForTaxInvoice } from "./model.module.js";  
import TaxInvoiceService from '../../../service/TaxInvoice.service.js'; 
import { TbCheck, TbPencil, TbReceipt, TbReceiptTax, TbTrash } from 'react-icons/tb';

import "./MyModal.css";
import { address, comma } from '../../../utils/util.js';
import useConfirm from '../../../store/hook/use-confirm.hook.js';
import { ComponentEdit } from '../../table/TableEditable.js';
import { ItemStockDrawerButton } from '../../drawer/stock-items-vat/index.js';
import { encode as ecode} from 'base-64';
import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs";
import { Authenticate }  from "../../../service/Authenticate.service.js"; 

const apirequest = TaxInvoiceService();
const athn = Authenticate();
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูล กรุณาเลือกรายการสินค้า</span> };
export default function MyModal({show, close, values, source}) { 
    const confirms = useConfirm();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = React.useState(show);
    const [loading,  setLoading] = React.useState(true);  
    

    const [dataHeader, setDataHeader] = React.useState({});
    // const [dataList, setDataList] = React.useState([]);
    const [dataTaxs, setDataTaxs] = React.useState([]);
    const [dataListValid, setDataListValid] = React.useState(false);

    const [dataSelectd, setDataSelected] = React.useState({});

    const [invType, setInvType] = React.useState(undefined);

    const [isInit, setIsInit] = React.useState(false);


    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };

    const isRowForcus = (record) => dataSelectd?.uuid === (record?.uuid || null);

    const allowSubmit = () => { 
        const { rpodate } = dataHeader;
        const type = athn.getType(); 
        const thisDate = dayjs(rpodate).format("YYYY-MM-DD 00:00:00");
        const currDate = dayjs(Date.now()).format("YYYY-MM-DD 00:00:00");

        // console.log( {type, rpodate})
        if( ((new Date(thisDate)).getTime() >= (new Date(currDate)).getTime()) ) return true;

        if( ["admin", "ผู้จัดการสาขา"].includes(type?.toLowerCase()) ) return true;

        if( ((new Date(thisDate)).getTime() < (new Date(currDate)).getTime()) && ["admin", "ผู้จัดการสาขา"].includes(type?.toLowerCase()) ) return true;
 
        return false; 
    }

    const handleConfirm = async (val) => {
        // console.log( val );
        const allow = allowSubmit();
        // console.log( { allow });
        // return;
        if( !allow ) {
            await confirms.error("วันที่ใบรายการซ่อมต้องไม่น้อยกว่าวันที่ปัจจุบัน หรือ ตรวจสอบสิทธิ์การตัดสต๊อก");

            // throw new Error("วันที่ใบรายการซ่อมต้องไม่น้อยกว่าวันที่ปัจจุบัน หรือ ตรวจสอบสิทธิ์การตัดสต๊อก");
            return;
        }
        setLoading(true);
        try {

            // return;
            const payload = { head: {...dataHeader, ...val }, list: dataTaxs }
            // console.log(  payload );
            apirequest.create(payload, { ignoreLoading : true }).then(  async (res) => {
                
                const result = await confirms.confirm({content:"ต้องการที่จะปริ้นท์ใบกำกับภาษีหรือไม่"});
                if( !!result ) {
                    // console.log( res.data, res );
                    const { last_id } = res.data.data;
                    handlePrintTaxInvoice(last_id);
                }
                
                if( !!values ) values( { ...val } ); 
                handleClose();
                message.success("สร้างใบกำกับภาษีเสร็จสิ้น.");
            }).catch( e => {
                console.log( e );
                const { data } = e?.response || {};
                Modal.error({content: data?.option || 'เกิดข้อผิดพลาดในการร้องขอข้อมูล'})
            })
            
        } catch (error) {
            console.log( error );
            Modal.error({content: "เกิดข้อผิดพลาดจากการแก้ไขข้อมูล"})
        } finally {
            setLoading( false );
        }
    }

    const handleClose = () =>{ 
        setOpenModal(false); 
        // setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    } 

    const handlePrintTaxInvoice = (code) => { 
        // const newWindow = window.open('', '_blank');
        // newWindow.location.href = `/receipt-print/${code}`;
        // const url = `/receipt-print/${code}/1`;
        // console.log( code );
        const url = `/receipt-print/${ecode(code)}`;
        // const newWindow = window.open('', url, url);
        const newWindow = window.open(url, '_blank', 'width=960,height=820' ); 
        newWindow.location.href = url;
    }

    const handleCancel = async (code) => {
        try { 
          const result = await confirms.confirm({content: 'คุณต้องการยกเลิกใบกำกับภาษีหรือไม่'});
          if(!!result){  
            await apirequest.deleted(code);
            if( !!values ) values();
            handleClose();
            message.success("ยกเลิกใบกำกับภาษีเรียบร้อย");
          }
        } catch ( er ){
          console.log( er ); 
          const { data:{ option } } = er.response;
          message.error(`Error request. ${option}`);
        }
    }

    const handleValuechange = ( v, f) => {
        // console.log({f})
        // setDataHeader( h => ({...h, ...f}));
    }

    const handleTypeChange = (e) => {
        const val = e.target.value;
        setInvType( Number( val ) );
    } 

    const handleItemValue = (value) => {
        // console.log( value ); 
        const { rpocode } = dataHeader;
        const newValue = value.map( m =>{   
            return ({
                ...m,
                rpocode: !m?.rpocode ? rpocode : m.rpocode,
                // amount: Number(m?.qty || 0),
                rpodetail: !m?.rpodetail ? m.stname : m.rpodetail,
                uuid: m?.uuid || uuidv4()
            });
        });
        const  d = [[...dataTaxs, ...newValue]]
        setDataTaxs(s => [...s, ...newValue] );
        setDataListValid( vildateListForTaxInvoice(d) );
    }

    const handleCustomer = (val, key, customer_type) => {
        // console.log( val, key, customer_type );
        if(!val) return;
        const { taxnumber, taxno } = val;
        const addr = address(val);
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            taxno:taxno || taxnumber,
            customer_type,
            address:addr,
        };
        form.setFieldsValue( newIntieial ); 
        setDataHeader( s => ({ ...s, ...newIntieial }) );
    }

    React.useEffect(() => {
        try {
            const { head, taxs } = source;

            if( !!Number(head?.taxinvoice_id )){
                setDataHeader( s => ({...head}) );
                
                // setIsInit( true );
            }

            else if( !!Number(head?.invtype ) ) {
                setInvType( Number(head?.invtype) );
                setDataHeader( head );
                
                // setIsInit( true );
            }           
            
            else if( !Number(head?.taxinvoice_id) || !Number(head?.invtype) ){
                const { price_total } = head;
                const vat = Number( price_total || 0 ) * 0.07;
                const grand_total = Number( price_total || 0 ) + vat;
                const newHead = { ...head, invtype: 0, vat, grand_total };
                // console.log( newHead );
                
                setDataHeader( newHead );
                
                setInvType( 0 );
                setDataListValid( vildateListForTaxInvoice(taxs) );
                setTimeout( () => form.setFieldsValue({...newHead}), 400 );
                // setIsInit( true );

            } 

            
            // setDataList( list );
            setDataTaxs( taxs );
            setDataSelected( {} );
            setIsInit( true );
        } catch ( error ) {
            Modal.error({content:'เกิดข้อผิดพลาดในหารร้องขอข้อมูล'});
        } finally { 
            setLoading( false );
        }


        return () => { setIsInit(false) };
    }, [source, form]); 

    React.useEffect(() => {
        // console.log( invType )
        try {
            switch( invType ){
                case 0: 
                    setDataHeader( setInvStyle1() );
                break;
                case 1: 
                    setDataHeader( setInvStyle2() );
                break;
                default: return;
            } 
        } catch ( error ) {
            Modal.error({content:'เกิดข้อผิดพลาดในหารร้องขอข้อมูล'});
        } finally { 
            setLoading( false );
        }
    }, [ invType, dataTaxs ]); 



    const handleSave = async (row) => {
        const newData = (r) => {
            // console.log( r);
            const itemDetail = [...dataTaxs];
            const newData = [...itemDetail];
            
            const ind = newData.findIndex((item) => r?.uuid === item?.uuid);
            if (ind < 0) return itemDetail;
            const item = newData[ind];
            const discount = Number( r?.discount || 0); 
            const total =  ( ( Number(r?.amount || 0 ) * Number(r?.price ||  0) ) - discount )

            newData.splice(ind, 1, { 
                ...item, 
                ...r,
                discount,
                price_total: total,
                edited: true
            });

            const price_total = newData.reduce( (a,v) => a += ( ( Number(v.amount || 0 ) * Number(v?.price ||  0) ) - Number(v?.discount ||  0) ), 0);

            setDataHeader(state => ({
                ...state,
                price_total
            }));

            const { invtype } = newData;

            setInvType( Number( invtype || 0 ) );
            setDataListValid( vildateListForTaxInvoice(newData) );
            return newData;
        };
 
        const data = [...newData(row)];
        setDataTaxs(data);
    };

    const handleClickCell = (record) => {
        // console.log( record)
        if( dataSelectd?.uuid === (record?.uuid || null) ) {
            setDataSelected( {} );

        }else {
            setDataSelected( record );

        }
        // context.clickSelect( "stcode", record);
    }

    const handleRemove = async () => {
        if( !dataSelectd?.uuid ) return;

        const result = await confirms.deleted();
        if( !result ) return;
        try { 
            const { uuid:key } = dataSelectd;
            const newData = dataTaxs?.filter( soc => soc.uuid !== key);

            const price_total = newData.reduce( (a,v) => a += ( ( Number(v.amount || 0 ) * Number(v?.price ||  0) ) - Number(v?.discount ||  0) ), 0);

            setDataHeader(state => ({
                ...state,
                price_total
            }));
            setDataListValid( vildateListForTaxInvoice(newData) );
            setDataTaxs(newData);
            setDataSelected( {} );

        } catch ( e ) {
            message.error("เกิดข้อผิดพลาด");
        }
    }

    const setInvStyle1 = () => {
        const { head } = !isInit ? source : { head: dataHeader };
        // console.log( head );
        // if( ( head?.taxinvoice_id || null ) !== null ) return;
        const { price_total } = head;
        const vat = Number( price_total || 0 ) * 0.07;
        const grand_total = Number( price_total || 0 ) + vat;
        const newHead = { invtype: 0, ...head, vat, grand_total };
        return newHead;
    }

    const setInvStyle2 = () => {

        const { head } = !isInit ? source : { head: dataHeader };
        
        // if( head?.taxinvoice_id !== null && head?.taxinvoice_id !== undefined ) return;
        const { price_total } = head;
        const vat = Number( price_total || 0 ) * 0.07;
        const grand_total = Number( price_total || 0 );
        const newHead = { invtype: 1, ...head, price_total: price_total - vat, vat, grand_total };
        return newHead;
    }

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbReceiptTax  style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>{!dataHeader?.taxinvoice_id ? 'ออกใบกำกับภาษี' : 'แก้ไขใบกำกับภาษี' }</Typography.Text>
        </Flex>    
    </>); 

    const information_items = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Descriptions title={false} layout="vertical"  size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={cusItem(dataHeader, handleCustomer)} />
    </>);

    const information_cars = (<>
        <Divider {...dividerProp}>ข้อมูลรถยนต์</Divider>
        <Descriptions title={false} layout="vertical"  size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={carInfo(dataHeader)} />
    </>);

    const information_vat = (<>
        <Divider {...dividerProp}>ข้อมูลเอกสาร</Divider>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={infoVat(dataHeader, handleTypeChange)} />
    </>);

    const information_list = (<>
        <Divider {...dividerProp}> รายการสินค้า</Divider>
        <Space className="w-full" direction='vertical'>
            <Flex className='w-full'> 
                <Flex className='w-full sm:w-1/2'>{'\u00A0'}</Flex>
                <Flex className='w-full sm:w-1/2' justify='end' gap={8}>
                    <Button 
                        size='small' 
                        icon={<TbTrash />}  
                        className='bn bn-danger-outline'
                        disabled={!dataSelectd?.uuid}
                        onClick={handleRemove}
                    >ลบ</Button> 
                    <ItemStockDrawerButton 
                        text='เพิ่ม'
                        // selects={dataTaxs}
                        selects={[]}
                        value={handleItemValue}
                        buttonProp = {{
                            size:'small',
                            icon: <TbPencil />,
                            className:'bn bn-primary-outline'
                        }}
                        container={false}
                    />
                </Flex>
            </Flex>
            <Table 
                components={ComponentEdit}
                columns={columnViewDetailEditable(
                    handleSave, 
                    columnViewDetail(handleClickCell)
                )}
                rowClassName={(record) => (isRowForcus(record) ? 'ant-table-row-selected' : '')}
                dataSource={dataTaxs} 
                pagination={false} 
                scroll={{ x: 'max-content' }}
                size='small' 
                rowKey="uuid" 
                id="list-data"
                summary={ (_) => totalPrice()}
                onRow={(rec) => {
                    return !stockVat( rec ) || Number( rec?.stock_vat_qty || 0 ) < 1 ? {
                        style: { backgroundColor: '#f0f0f0', color: '#888686'}
                    } : { } 
                }}
                locale={{...emptyText}}
            />
        </Space>
    </>);

    const information_view_list = (<>
        <Divider {...dividerProp}> รายการสินค้า</Divider> 
            <Table  
                columns={columnView(handleClickCell)}
                dataSource={dataTaxs} 
                pagination={false} 
                scroll={{ x: 'max-content' }}
                size='small' 
                rowKey="uuid" 
                id="list-data"
                summary={ (_) => totalPrice(4)}
                locale={{...emptyText}}

            /> 
    </>);

    const modalFooter = (<> 
        <Row>
            <Col span={24}>{/* Ignore */}</Col>
            <Col span={24}>
                <Flex justify="flex-end" gap={4}>
                    { !!dataHeader?.taxinvoice_id && <>
                        <Button 
                            className={`bn-center bn-secondary-outline`} 
                            onClick={()=>handleCancel(dataHeader.taxinvoice_id)}  
                        >คืนสต๊อกVAT</Button>

                        <Button
                            icon={<TbReceipt />}  
                            className='bn bn-primary-outline'
                            onClick={()=>handlePrintTaxInvoice(dataHeader.taxinvoice_id)}
                        >
                            <Typography.Text>พิมพ์กำกับภาษี</Typography.Text>
                        </Button> 
                    </>
                    }
                    { !dataHeader?.taxinvoice_id && 
                          <Popover
                          placement="topRight" 
                          title={
                            !!dataTaxs?.length < 1 && "แนะนำการใช้งาน" 
                          }
                          content={!!dataTaxs?.length < 1 && "กรุณาเลือกรายการสินค้าอย่างน้อย 1 รายการ"}
                          arrow={{
                            pointAtCenter: true,
                          }} 
                          trigger={"hover"}
                        >
                          <div>
                          <Button
                                className="bn-center bn-primary"
                                icon={<TbCheck />}
                                form="form-tax-invoice"
                                htmlType='submit'
                                // onClick={() => handleConfirm()}
                                disabled={!dataListValid}
                            >สร้างใบกำกับภาษี</Button>        
                          </div>
                   
                        </Popover>

                    }

                </Flex>
            </Col>
        </Row> 
    </>);

    const totalPrice = (span = 6) => (<>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={span} rowSpan={3} className='!bg-white align-top'>
            {/* <label>หมายเหตุ</label> */}
            <pre 
                className='border-0 italic' 
                style={{fontSize: 'clamp(11px, 0.7vw, 13px)',padding: '8px 10px'}} 
            >{dataHeader?.remark}</pre>
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.price_total || 0),2,2) } บาท</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ภาษีมูลค่าเพิ่ม { dataHeader?.vat > 0 && '(7%)'}</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
                <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.vat || 0),2,2) } บาท</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row> 
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ยอดรวมสุทธิ</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
            <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.grand_total || 0),2,2) } บาท</Typography.Text> 
            </Table.Summary.Cell> 
        </Table.Summary.Row>
    </>);

    return (
        <>
        <div className='modal-tax-invoice-area' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( false );  
                    }
                }}
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)'  }}
                width={1240}
                className='modal-tax-invoice mymodal'
                footer={<>{modalFooter}</>}
                styles={{
                    body:{
                    overflow: 'auto',
                    height: '100%',
                    maxHeight: '100%',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#eaeaea transparent',
                    paddingLeft: '10px',
                }}}
            >
            <Spin spinning={loading} >
                <div style={containerStyle}> 
                    <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                        <Card style={{backgroundColor:'#f0f0f0' }}> 
                            <Form 
                                form={form} 
                                layout="vertical" 
                                autoComplete="off" 
                                name='form-tax-invoice' 
                                onFinish={handleConfirm} 
                                onFinishFailed={ (e) => {
                                    const { errorFields } = e;
                                    const [ fail ] = errorFields || [];
                                    const [ name ] = fail?.name || [];
                                    console.log( name );
                                    document.getElementById(`form-tax-invoice_${name}`)?.focus();
                                    message.warning("กรอกข้อมูลให้ครบถ้วน");
                                
                                }}
                                onValuesChange={handleValuechange} 
                            >
                                {information_vat}
                                {information_cars} 
                                {information_items}
                            </Form> 
                            { !Number(dataHeader?.taxinvoice_id || 0) ? information_list : information_view_list }
                            {/* {information_list} */}
                        </Card> 
                    </Space>       
                </div> 
            </Spin>
            </Modal>                
        </div>

        </>
    )
}

