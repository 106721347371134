import React from 'react';
import { Descriptions, Divider, Space, Table, Typography } from 'antd';

import { infoPurchase, infoGoodsReceipt, supItem, columnDetailView } from './model';
import { TableSearchValue } from '../../components/form';
import { comma } from '../../utils/util';
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };

export default function ManageView({initeial}) {

  const [headValue, setHeadValue] = React.useState({});
  const [listValue, setListValue] = React.useState([]);
  
  const [isVat, setIsVat] = React.useState(false);

  const information_purchase = (<>
    <Divider {...dividerProp}>ข้อมูลใบสั่งซื้อ</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={infoPurchase(headValue)} />
  </>);

  const information_goodsreceipt = (<>
    <Divider {...dividerProp}>ข้อมูลใบรับสินค้า</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={infoGoodsReceipt(headValue)} />
  </>);

  const supplier = (<>
    <Divider {...dividerProp}>ข้อมูลผู้ขาย</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={supItem(headValue)} />
  </>);


  const lists = (<>
      <Divider {...dividerProp}>รายละเอียด</Divider>
      <TableSearchValue
          title='รายการใบสั่งซื้อ' 
          notSelect={true}
          tbProps={{ 
              rowKey:"id",
              dataSource:listValue,
              columns:columnDetailView(),
              pagination: false,
              locale:{...emptyText},
              summary:(v) => total_price(v),
          }}
      />
  </>);

  const total_price = (list) => {
    return summary(list);
  }
  const summary = (source) =>  {
    const price_total = source?.reduce( (a,v) => a += Number(v?.total_price || 0), 0);
    const vat = isVat ? price_total * 0.07 : 0;
    const grand_total = price_total + vat;
    return(<>
    <Table.Summary.Row>
        <Table.Summary.Cell colSpan={9} align='end' className='!pe-3'>ราคารวม</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} >
          <Typography.Text type="danger">{ comma(Number(price_total < 0 ? '-' : price_total ),2,2) }</Typography.Text>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell align='start' >บาท</Table.Summary.Cell> */}
    </Table.Summary.Row>
    <Table.Summary.Row>
        <Table.Summary.Cell colSpan={9} align='end' className='!pe-3'>ภาษีมูลค่าเพิ่ม</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} >
          <Typography.Text type="danger">{ comma(Number(vat < 0 ? '-' : vat ),2,2) }</Typography.Text>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell align='start' >บาท</Table.Summary.Cell> */}
    </Table.Summary.Row>
    <Table.Summary.Row>
        <Table.Summary.Cell colSpan={9} align='end' className='!pe-3'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} >
          <Typography.Text type="danger">{ comma(Number(grand_total < 0 ? '-' : grand_total ),2,2) }</Typography.Text>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell align='start' >บาท</Table.Summary.Cell> */}
    </Table.Summary.Row>
  </>)
  }

  React.useEffect(() => {
    const { head, list } = initeial;
    setHeadValue(head);
    setListValue(list);
    setIsVat( !!Number( head?.isvat ) );
  }, [initeial]);

  return (
    <Space direction='vertical' className='w-full desc-goods-receipt'>
      {information_purchase}
      {supplier}
      {information_goodsreceipt}
      {lists}
    </Space>
  )
}
