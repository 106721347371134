/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbBasketCheck, TbCheck, TbPencil, TbPrinter, TbRotateClockwise } from "react-icons/tb";
import { Button, Typography, message } from 'antd';
import dayjs from "dayjs"; 
import RepairOrderManageForm from './MyManageForm';
import ReapirOrderManageView from './MyManageView';

import { TaxInvoiceModal } from '../../components/modal/tax-invoice';

import RepairOrderService from "../../service/RepairOrder.service";
import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model";
import useConfirm from '../../store/hook/use-confirm.hook';
import { TbReceipt } from 'react-icons/tb';
// import { CancelButton } from '../../components/drawer/cancel';
import TaxInvoiceService from '../../service/TaxInvoice.service';
import OptionService from '../../service/Options.service';
import { Authenticate }  from "../../service/Authenticate.service.js"

const formName = "sup-form";
const apirequest = RepairOrderService();
const taxrequest = TaxInvoiceService();
const optrequest = OptionService();
const athn = Authenticate();

function Manage() {
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirm();
  const { config:{ mode, acname, code, backTo } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData);
  const [taxsValue, setTaxsValue] = useState(formData);
  const [resultValidate, setResultValidate] = useState(false);
  

  const [openTaxInvoice, setOpenTacInvoice] = React.useState( false );
  const [showTaxInvoice, setShowTacInvoice] = React.useState( false );

  const [actionButton, setActionButton] = React.useState( "create" );

  const allowSubmit = (source) => { 
    const { rpodate } = source;
    const type = athn.getType(); 
    const thisDate = dayjs(rpodate).format("YYYY-MM-DD 00:00:00");
    const currDate = dayjs(Date.now()).format("YYYY-MM-DD 00:00:00");
    // console.log( { source, rpodate, type });
    if( ((new Date(thisDate)).getTime() >= (new Date(currDate)).getTime()) ) return true;

    if( ["admin", "ผู้จัดการสาขา"].includes(type?.toLowerCase()) ) return true;

    if( ((new Date(thisDate)).getTime() < (new Date(currDate)).getTime()) && ["admin", "ผู้จัดการสาขา"].includes(type?.toLowerCase()) ) return true;



    return false; 
  }

  const handleSubmit = async (value) =>{ 
    try {  
      const { head } = value;
      const payload = {...value, id:code};
      // debugger;
      // console.log( payload );
      // return;
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      let newMode = mode;
              
      const allow = allowSubmit(head);
      // console.log( { allow });
      // return;
      if( !allow && actionButton === "stock" ) {
          confirm.error("วันที่ใบรายการซ่อมต้องไม่น้อยกว่าวันที่ปัจจุบัน หรือ ตรวจสอบสิทธิ์การตัดสต๊อก");
          return;
      }
      //if( formValue?.head?.status === 'ยกเลิก' ) newMode = 'create';
      
      const res = await apirequest[newMode](payload);
      setFormValue({...value});
      
      if( actionButton !== "stock")
        message.success("Request create done.");

      // const result = mode === "update" ? await confirm.confirm({content: "คุณต้องการที่จะตัดสต๊อกเลยหรือไม่"}) : false;
      const result = actionButton === "stock";
      if(!!result) {
        handleSubmitStock(payload, !false);
      } else {
        const { last_code } = res.data.data;
        handleToView(last_code); //navigate(-1);
      }
    } catch ( er ){
      console.log( er );
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  const handleSubmitStock = async (value, confirmFlag = true) =>{ 
    try {  
      const payload = {...value};
      const result = confirmFlag ? await confirm.confirm({content: "คุณต้องการที่จะตัดสต๊อกหรือไม่"}) : true;
      if(!!result) {
        await apirequest.submit_stock(payload);
        setFormValue({...value}); 
         
        // console.log( value, "asdasdasdasdasd" );
        // navigate(-1);
        // await initeil();
        handleToView(payload?.id);
        message.success("Adjust stock done.");
      } 

    } catch ( er ){
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  const handleToEdit = async (code, confirmFlag = true) =>{ 
    try {  
      const result = confirmFlag ? await confirm.confirm({content: "คุณต้องการที่จะไปหาแก้ไขหรือไม่"}) : true;
      if(!!result) {
        const config = { mode:"update", acname: `ใบวางบิล #${code}`, code };
        navigate("/repair-orders/manage", {state:{config}});
      } 
    } catch ( er ){
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  const handleToView = async (key) =>{  
    // console.log( key );
    const config = { mode:"view", acname: `ใบวางบิล #${key}`, code: key };
    navigate("/repair-orders/manage", {state:{config}}); 
  }

  // const handleToPrintData = () => {  
  //   const url = `/repair-order-print/${code}`;
  //   const newWindow = window.open('', url, url);
  //   newWindow.location.href = url;
  // }
  const handlePrintData = (code) => { 
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/repair-order-print/${code}`;
    // const url = `/repair-order-print/${code}/1`;
    const url = `/repair-order-print/${code}`;
    // const newWindow = window.open('', url, url );
    const newWindow = window.open(url, '_blank', 'width=960,height=780' ); 

    newWindow.location.href = url;
  }

  const handleCancleSubmitStock = async () =>{ 
    try {  
      const payload = {code, p:"stock-cancel"};
      let result = false;
      const { status, payment_auto, payment_now, payment_cond } = formValue.head;
      if( ["ชำระเงินไม่ครบ","ชำระเงินครบแล้ว"].includes( status ) ){
        result = await confirm.warninged({
          content: <>
            <span>ใบรายการซ่อมมีการชำระแล้ว ต้องการที่จะคืนสต๊อกหรือไม่ </span>
            <br />
            <span className='text-red-600' style={{fontSize: 'clamp(11px, .7vw, 12px)'}}>
            * ถ้าเป็นการชำระแบบเงินสด หรือชำระทันทีหลังจากตัดสต๊อกระบบขะทำการยกเลิกใบรายการซ่อมทันที  
            </span>
          </>
        }); 
        if( !result ) return;
      }


      result = !result ? await confirm.confirm({content: "คุณต้องการที่จะคืนสต๊อกหรือไม่"}) : result;
      if(!!result) {
        await optrequest.optionsStockPut(payload).then( async(res) => {
          
          if( Number(payment_auto || 0 ) === 1 || Number(payment_now || 0 ) === 1 || payment_cond === "เงินสด" ){
            navigate("/repair-orders");
          } else {
            await initeil(); 
          }          
          message.success("Return stock done.");
        }).catch( e => {
          const { data } = e.response;
          message.error(data?.option || "Error request.")
        })
        

      } 

    } catch ( er ){
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  // const handleCancel = async (v) => {
  //   try { 
  //     // const result = await confirm.confirm({content: 'คุณต้องการยกเลิกรายการหรือไม่'});
  //     // if(!!result){  
  //       await apirequest.deleted(v, code);
  //       navigate(-1);
  //       message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
  //     // }
  //   } catch ( er ){
  //     console.log( er ); 
  //     const { data:{ option } } = er.response;
  //     message.error(`Error request. ${option}`);
  //   } 
    
  // }

  const handleTaxInvoice = async () => {
    try {
      const { head:h } = formValue;
      console.log( {th: h?.taxinvoice_id, h})
      const loadData = !!Number(h?.taxinvoice_id) ? taxrequest.get(Number(h?.taxinvoice_id)) : optrequest.optionsTaxInvoice({p:"default", code }, { ignoreLoading : false });
      const res = await loadData;
      const { data:{ head, list, taxs } } = res.data; 
      setTaxsValue({head, list, taxs});
      setOpenTacInvoice( true );
      setShowTacInvoice( true );
    } catch ( e ) {
      console.log( e );
      message.error("เกิดข้อผิดพลาด")
    }

    // console.log(res);

  }

  const initeil = async () =>{
    try {
      if( mode !== "create" && !!code){
        const res = await apirequest.get(code);
        // console.log( res );
        const { data:{ head, list, payment } } = res.data; 
        head.rpodate = !!head.rpodate ? dayjs(head?.rpodate) : null; 
        setFormValue({head, list, payment});
      } 
    } catch ( er ){
      console.log( er );
      message.error("error request.")
    }
  }

  useEffect( () => {

    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<> 
      { !Number(formValue.head.stock_submit || 0 ) && mode === "update" && 
        <>
          {/* { formValue.head.status !== 'ยกเลิก' &&  <CancelButton value={handleCancel} text='ยกเลิกใบรายการซ่อม' /> } */}
          { 
            formValue.head.status !== 'ยกเลิก' && <Button
              icon={<TbBasketCheck style={{fontSize:'1rem'}}/>} 
              className='bn-center bn-success-outline'
              onClick={()=> setActionButton("stock")}
              form={formName}
              htmlType='submit'
              disabled={ !resultValidate }
            >ยืนยัน/ตัดสต๊อก</Button>
          }
          <Button
              icon={<TbCheck style={{fontSize:'1rem'}}/>} 
              className='bn-center bn-primary' 
              onClick={()=> setActionButton("update")}
              form={formName}
              htmlType='submit'
              disabled={ !resultValidate }
          >ยืนยัน/บันทึก</Button>          
        </>
      }
      { ((!Number(formValue.head.stock_submit || 0 ) && mode === "create") || (formValue.head.active_status === 'N' && mode === "update" ) ) &&
        <Button
            icon={<TbCheck style={{fontSize:'1rem'}}/>} 
            className='bn-center bn-primary' 
            onClick={()=> setActionButton("update")}
            form={formName}
            htmlType='submit'
            disabled={ !resultValidate }
        >ยืนยัน/บันทึก</Button>
      }
    </>
    :<> 
      { ((!!Number(formValue.head.stock_submit || 0 ) && formValue.head.status !== 'ยกเลิก') || (Number(formValue.head.stock_vat_submit) === 1) ) &&
      <>
        <Button
          icon={<TbPrinter style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-info-outline'
          onClick={()=>handlePrintData(code)}
        >
          <Typography.Text>พิมพ์</Typography.Text>
        </Button>
        <Button
          icon={<TbReceipt style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-warning-outline'
          onClick={()=> {
            handleTaxInvoice()
          }}
        >
          <Typography.Text>ใบกำกับภาษี</Typography.Text>
        </Button>
      </>
      }
      { (Number(formValue.head.stock_submit || 0 ) === 0 || formValue.head.status === 'ยกเลิก') &&
      <>

        <Button
          icon={<TbPrinter style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-info-outline'
          onClick={()=>handlePrintData(code)}
        >
          <Typography.Text>พิมพ์</Typography.Text>
        </Button>
        <Button
          icon={<TbPencil style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-primary-outline'
          onClick={()=>handleToEdit(code)}
        >
          <Typography.Text>แก้ไข</Typography.Text>
        </Button>
      </>
      }
      { (!!Number(formValue.head.stock_submit || 1 ) && formValue.head.status !== 'ยกเลิก') &&
      <>
      {/* <Popover
        placement="topRight" 
        title={
          formValue?.payment?.length > 0 && "ข้อความแนะนำ" 
        }
        content={formValue?.payment?.length > 0 && "ไม่สามารถคืนสต๊อกได้เนื่องจากมีการชำระเงินแล้ว" }
        arrow={{
          pointAtCenter: true,
        }} 
        trigger={"hover"}
      > */}
        <Button
          icon={<TbRotateClockwise style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-danger-outline'
          onClick={()=>handleCancleSubmitStock()}
          // disabled={ !Number( formValue?.head?.payment_auto ) && ["ชำระเงินไม่ครบ","ชำระเงินครบแล้ว"].includes( formValue.head.status ) }
        >
          <Typography.Text>คืนสต๊อกจริง</Typography.Text>
        </Button>        
      {/* </Popover> */}


      </>
      }
      {/* { formValue.head.status !== 'ยกเลิก' &&
        <Button
          icon={<TbPrinter style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-info-outline'
          onClick={()=>handleToPrintData()}
        >
          <Typography.Text>พิมพ์</Typography.Text>
        </Button>
      }
      { !["ชำระเงินไม่ครบ","ชำระเงินครบแล้ว"].includes( formValue.head.status ) && formValue.head.status !== 'ยกเลิก' &&
        <CancelButton value={handleCancel} text='ยกเลิกใบรายการซ่อม' />
      } */}

    </>
  }
  </>); 

  return (<>
    <QctPageLayout title={`ใบรายการซ่อมสินค้า - ${acname}`} back={true} backto={ backTo || "/repair-orders" } footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <ReapirOrderManageView 
        initeial={formValue} 
        reload={ async (v) => { 
          // console.log( v );
          !!v && await initeil();
        }} 
        />
      : (<TbSearchProvider>
          <RepairOrderManageForm formName={formName} submit={handleSubmit} initeial={formValue} validate={(v) => setResultValidate(v)} mode={mode} action={actionButton}/>
        </TbSearchProvider>)
    } 
    </QctPageLayout> 

    { showTaxInvoice && <TaxInvoiceModal show={openTaxInvoice} close={ () => {
      setShowTacInvoice(false) 

      setTimeout( () => { setOpenTacInvoice(false) }, 400);
    }} source={taxsValue} values={() => initeil()} />}

  </>)
}

export default Manage