/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AutoComplete, Col, DatePicker, Divider, Form, Input } from 'antd';
import { Modal, Row, Space, Table, Typography } from 'antd';
 
// import { StockItemsModal } from '../../components/modal/stock-items';
import { ItemForPurchaseOrderModal } from '../../components/modal/purchase-order/index.js';

import { address, comma, filterComplete } from '../../utils/util';

import { formData, columnRepairOrder } from "./model";
import { ComponentEdit } from '../../components/table/TableEditable';
import { TableSearchValue } from '../../components/form';  

import { useTbSearchContext } from "../../store/context/table-search.context"
import dayjs from "dayjs";

import OptionService from "../../service/Options.service.js"
import { CustomersInput } from '../../components/modal/customers';
import { PAYMENT_CONDITION } from '../../constant/constant.js';
import useConfirm from '../../store/hook/use-confirm.hook.js';

const DATEFORMAT = 'DD/MM/YYYY';
const optrequest = OptionService();
export default function ManageForm({choosed, initeial, formSet}) { 
    const confirms = useConfirm();
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const context = useTbSearchContext();  
    const [formValue, setFormValue] = React.useState(formData);
    const [listSouce, setListSouce] = React.useState([]);

    const [listRepairOrder, setListRepairOrder] = React.useState([]);
    const [customers, setCustomers] = React.useState(undefined);
    const [openItemsList, setOpenItemsList] = React.useState(false);

    const [optPaymentCond, setOptPaymentCond] = React.useState([]);
    const [optCarBrand, setOptCarBrand] = React.useState([]);
    const [optCarModel, setOptCarModel] = React.useState([]);

    const [isVat, setIsVat] = React.useState(true); 

    const onFinish = (values) => {
        if( listSouce.length < 1) return;
        

    };
 

    useEffect( () => { 
        const { head, list } = initeial;
        const init = {...head, isvat:!(Number( head?.vat || 0) === 0)};
        formSet.setFieldsValue(init);
        setFormValue(head);
        setListSouce(list);
        // setIsVat(init.isvat);
        return () => {};
    }, [initeial]);

    useEffect( () => {
        try {
            const loadRepairOrder = async () => {
                if( !customers?.cuscode ) {

                    setListRepairOrder([]);
                    return;
                } 
                optrequest.optionsBillingNote({code: customers?.cuscode || "", p: "repair-order-option" })
                .then( res => {
                    const { data } = res.data;
                    const totalPrice = data.reduce( (acc, val) => acc + Number(val.price_total), 0 );
                    // console.log( data, totalPrice )
                    setFormValue(  f => ({...f, price_total: totalPrice}));
                    setListRepairOrder( data );
 
                    if( typeof choosed === "function"){
                        choosed( data );
                    }
                })
                .catch( e => { 
                    const { data } = e?.response || {};
                    Modal.error({content: data?.option || 'เกิดข้อผิดพลาดในการร้องขอข้อมูล'})
                }); 
            }

            loadRepairOrder();        
        } catch( e ){
            console.log( e );
        }

    }, [customers]);


    const handleCustomer = (val, key, customer_type) => {
        // console.log( val );
        if(!val) return;
        const { taxnumber } = val; 
        const addr = address(val); 
        const valueForm = formSet?.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            address:addr, 
            remark: valueForm.remark,
            taxno:taxnumber,
            customer_type
        };
        formSet?.setFieldsValue( newIntieial );
        setCustomers( {...val, customer_type} );
        setFormValue( s => ({...s, ...newIntieial})); 
    }

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลใบวางบิล</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ใบวางบิล" name="bndate" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={DATEFORMAT} placeholder='กำหนดวันที่ใบวางบิล' />
              </Form.Item>
            </Col> 
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่กำหนดชำระ" name="payment_duedate">
                <DatePicker style={{ width: "100%", height:40 }} format={DATEFORMAT} placeholder='กำหนดวันที่ชำระ' />
              </Form.Item>
            </Col> 
        </Row> 
    </>)

    const customer = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="รหัสลูกค้า" htmlFor="cuscode-1"  name="cuscode" rules={[formRole]} >
                    <CustomersInput 
                        selected={handleCustomer} 
                        placeholder='เลือกลูกค้า' 
                        id='cuscode-1' 
                        className='!bg-zinc-50 !text-black' 
                        value={formValue.cuscode}
                        countRpo={true}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={18} lg={18}>
                <Form.Item label="ชื่อลูกค้า" name="cusname" rules={[formRole]} >
                    <Input disabled placeholder='ชื่อลูกค้า' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เลขผู้เสียภาษี/เลขบัตรประชาชน"  name="taxnumber" >
                    <Input placeholder='เลขผู้เสียภาษี' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ติดต่อ"  name="contact_name" >
                    <Input placeholder='ติดต่อ' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เบอร์โทร"  name="contact_tel" >
                    <Input placeholder='เบอร์โทร' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="อีเมล"  name="contact_email" >
                    <Input placeholder='อีเมล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item label="ที่อยู่ลูกค้า"  name="address" rules={[formRole]} >
                    <Input.TextArea placeholder='ที่อยู่' rows={2} />
                </Form.Item>
            </Col>
        </Row> 
    </>);

    const cars = (<>
        <Divider {...dividerProp}>ข้อมูลรถยนต์</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item label="ทะเบียนรถ"  name="car_no" >
                    <Input placeholder='กรอกทะเบียนรถ' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item label="ยี่ห้อรถ" name="car_brand" >
                    {/* <Input disabled placeholder='กรอกยี่ห้อรถ' /> */}
                    <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarBrand}
                        filterOption={filterComplete}
                        placeholder='กรอกกรอกยี่ห้อรถ'
                    >
                    </AutoComplete>                    
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item label="รุ่น"  name="car_model" >
                    {/* <Input placeholder='กรอกรุ่น' /> */}
                    <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarModel}
                        filterOption={filterComplete}
                        placeholder='กรอกรุ่น'
                    >
                    </AutoComplete>
                </Form.Item>
            </Col>
        </Row> 
    </>);

    const lists = (<>
        <Divider {...dividerProp}>รายละเอียด</Divider>
        <TableSearchValue
            title='รายการใบวางบิล'
            // onDelete={handleDelete}
            // onCreate={()=> setOpenItemsList(true)}
            notSelect={true}
            tbProps={{
                components:ComponentEdit,
                rowKey:"rpocode",
                dataSource:listRepairOrder,
                columns:columnRepairOrder(),
                pagination: false,
                locale:{...emptyText},
                summary: (record) => totalValue
            }}
        />
    </>);

    const totalValue = (<>
        <Table.Summary.Row> 
            <Table.Summary.Cell colSpan={5} align='start' className='!pe-4 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
              <Typography.Text type="danger">{ comma(Number(formValue?.price_total || 0),2,2) }</Typography.Text>
            </Table.Summary.Cell> 
        </Table.Summary.Row>
    </>)

    return (<>
    <Space direction='vertical' className='w-full'>
        <Form
            form={formSet} 
            layout='vertical'
            name='my-form'
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)} 
            onFinish={onFinish}
        >
            {information}
            {customer} 
            {lists}
        </Form>
    </Space> 
    </>)
}
