import { Button } from 'antd'
import React from 'react'
import MyDrawer from './MyDrawer'
import { TbSquareRoundedX } from 'react-icons/tb';
import { forwardRef } from 'react';

const MyDrawerButton = forwardRef(( {value, text="ยกเลิก", disabled=false, buttonProp={}, noPopupConfirm = false}, ref) => {
    const [show, setShow] = React.useState( false );
    
    const hancleOpen = () => {
        // console.log( 111  )
        setShow(true);
    }
    
    const hancleConfirmCancel = (v) => { 
        if( typeof value === 'function'){
            value( v ); 
        }
    }
    return (
        < > 
            <Button  
                ref={ref}
                icon={<TbSquareRoundedX style={{fontSize:'1.4rem'}}/>} 
                className='bn-center bn-secondary-outline'
                onClick={() => hancleOpen()}
                disabled={disabled}
                {...buttonProp}
            >{text}</Button> 

            { !!show && <MyDrawer show={show} close={()=>setShow(false)} value={hancleConfirmCancel} noPopupConfirm={noPopupConfirm} /> }
        </>
    )
});

export default MyDrawerButton; 
