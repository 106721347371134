/* eslint-disable no-unused-vars */
import React from 'react'
import { TableSearchValue } from '../../form'
import { ComponentEdit } from '../TableEditable'
import { columnDetail, columnsDetailsEditable } from './model.module';
import OptionService from '../../../service/Options.service';
import { Button, Checkbox, Flex, Table, Typography } from 'antd';
import { comma } from '../../../utils/util';
import useConfirm from '../../../store/hook/use-confirm.hook';
import { v4 as uuidv4 } from 'uuid'; 
import dayjs from "dayjs";
import { TbCirclePlus, TbTrashXFilled } from 'react-icons/tb';

const optservice = OptionService();
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
export default function AdjustStock({onChange, defaultList=[], formData={}}) {
    const confirms = useConfirm();
    const [listSource, setListSource] = React.useState([]);
 
    const [optionLocation, setOptionLocation] = React.useState([]);

    const [selected, setSelected] = React.useState(null)

    const handleDelete = async (e) => {
        try { 
            const { uuid } = selected;
            const result = await confirms.deleted();

            if( !result ) return;
            const newData = listSource?.filter( soc => soc.uuid !== uuid)
            setListSource(newData); 
            if( typeof onChange === "function"){
                setSelected(null)
                onChange( newData );
            }
        } catch( e ) {
            console.warn(e);

            confirms.error("ทำรายการไม่สำเร็จ")
        }
    }

    const handleCreate = (e) => {
        const addNew = [
            ...listSource,
            {
                uuid:uuidv4(),
                location_code: null,
                mfdate:null,
                quarter:null,
                mfyear:null,
                qty: 0,
                adjust_qty: 0,
                remark: null,
            }
        ]
        setListSource(addNew);

        if( typeof onChange === "function"){
            onChange( addNew );
        }
    }

    const handleSave = async (row, index, fm) => {
        const newData = async (r) => {
            const itemDetail = [...listSource];
            const newData = [...itemDetail];
            // console.log( row )
            const ind = newData.findIndex((item) => r?.uuid === item?.uuid);
            if (ind < 0) return itemDetail;
            const item = newData[ind];

            const isDup = listSource.some( s => {
                // console.log( s.mfdate === r.mfdate );
                // console.log( dayjs(s.mfdate).format("YYYYMMDD"), dayjs(r.mfdate).format("YYYYMMDD"), dayjs(s.mfdate).format("YYYYMMDD") === dayjs(r.mfdate).format("YYYYMMDD") );
                return (
                    Number(s.location_code) === Number( r.location_code)
                    && dayjs(s.mfdate).format("YYYYMMDD") === dayjs(r.mfdate).format("YYYYMMDD")
                    && s.uuid !== r.uuid
                )}
            );
            
            if( isDup ) {
                await confirms.warn("สถานที่เก็บและรายการยางของ สัปดาห์/ปี/ไตรมาส ถูกเลือกแล้วแล้ว");
                r.location_code = null;
                r.mfdate = null;
                fm?.setFieldValue("location_code", null);
                fm?.setFieldValue("mfdate", null);
                await fm?.validateFields();
            }
 
            r.mfweek  = !!r.mfdate ? dayjs(r.mfdate).format("w") : null;
            r.mfyear  = !!r.mfdate ? dayjs(r.mfdate).format("YYYY") : null;
            r.quarter = !!r.mfdate ? dayjs(r.mfdate).format("Q") : null;
            r.wyqdate = !!r.mfdate ? dayjs(r.mfdate) : null; 

            newData.splice(ind, 1, { ...item, ...r});  
            return newData;
        };

        try {
            const data = [...( await newData(row) )];

            // console.log( data );
            setListSource(data);  

            if( typeof onChange === "function"){
                onChange( data );
            }            
        } catch (e) {
            // console.log('Save failed:', e);
        } 
    };

    const handleClickCell = (record) => {
        if(!!selected && selected?.uuid === record?.uuid) {
            setSelected(null)
        } else {
            setSelected( {...record });
        } 
        // context.clickSelect( "stcode", record);
    }

    const handleRowSelectRender = (im, rc, index) => {

       return <Flex gap={8}>
            <Checkbox checked={!!selected?.uuid && selected?.uuid === rc?.uuid} />
            {index + 1}
        </Flex> 
    } 

    const totalPrice = (list) => {
        if( list?.length < 1) return undefined;
        const total = list?.reduce( (acc, val) => acc += Number( val?.money || 0), 0);
        const remain = Number(formData?.price_total || 0 ) - total;
        return (<>
            <Table.Summary.Row> 
                <Table.Summary.Cell colSpan={2} align='center' className='align-top text-end !font-bold'>ราคารวม</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-6 text-end border-right-0 align-top !font-bold' style={{borderRigth:"0px solid"}} >
                    <Typography.Text className='!font-bold'>{ comma(Number(formData?.price_total || 0 ),2,2) }</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} align='start' className='align-top !font-bold'>บาท</Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align='center' className='align-top text-end !font-bold'>ยอดชำระรวม</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-6 text-end border-right-0 align-top !font-bold' style={{borderRigth:"0px solid"}} >
                    <Typography.Text type="danger" className='!font-bold' >{ comma(total,2,2) }</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} align='start' className='align-top !font-bold'>บาท</Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>  
                <Table.Summary.Cell colSpan={2} align='center' className='align-top text-end !font-bold'>คงเหลือ</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-6 text-end border-right-0 align-top !font-bold' style={{borderRigth:"0px solid"}} >
                    <Typography.Text type="danger"  className='!font-bold'>{ remain >= 0 && !!formData?.price_total ? comma(remain,2,2) : "เลือกหรือกำหนดราคาสินค้า" }</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} align='start' className='align-top !font-bold'>บาท</Table.Summary.Cell>
            </Table.Summary.Row>
        </>)
    }

    const custom = (key, rec, iconStyle, bntStyle) => (<> 
        <Button 
            icon={<TbTrashXFilled style={iconStyle} />} 
            style={bntStyle} 
            className='bn-danger-outline' 
            disabled={!selected}  
            onClick={handleDelete}
        >
          <Typography.Text>ลบ</Typography.Text>
        </Button>
        
        <Button
            icon={<TbCirclePlus style={iconStyle} />} 
            style={bntStyle} 
            className='bn bn-primary' 
            onClick={handleCreate} 
        >
          <Typography.Text>เพิ่ม</Typography.Text>
        </Button> 
    </>);

    React.useEffect( () => { 
        const initeial = async () => {
            const [ optionLocationRes ] = await Promise.all( [ optservice.optionsLocation({p:"location-option"}) ]);

            setOptionLocation( optionLocationRes.data.data );
        }

        initeial();
        return () => {};
    }, [])

    React.useEffect( () => { 
        const initeial = async () => {
            if( defaultList?.length > 0 ){
                const newList = defaultList?.map( m => {
                    return {
                        ...m,
                        qty: Number( m.qty ),
                        adjust_qty: Number( m.adjust_qty ),
                        remark: null
                    }
                });
                setListSource( newList ); 
                console.log( newList );
            }
        }

        initeial();
        return () => {};
    }, [defaultList]);
 
    return ( 
        <TableSearchValue 
            title='รายการ' 
            // notSelect={true}
            onCustom={custom}
            tbProps={{
                components:ComponentEdit,
                rowKey:"uuid",
                dataSource:listSource, 
                columns:columnsDetailsEditable(
                    handleSave, 
                    columnDetail(handleClickCell, optionLocation, handleRowSelectRender)
                ),
                pagination: false,
                locale:{...emptyText},
                rowClassName:(record) => ( (!!selected && selected?.uuid === record?.uuid)  ? 'ant-table-row-selected' : ''),
                onRow:() => {},
                // summary: (r) => r?.length > 0 ? totalPrice(r) : null,
            }}
        /> 
    )
}
