// import { useState } from 'react';
import { PROJECT_KEY } from "../../constant/constant";
import {decode as dcode, encode as ecode} from 'base-64';
const useSearch = (key = undefined) => {
  const updateSearch = (criteria) => {
    if( !key ) return;

    // console.log( criteria );
    // console.log( JSON.stringify( criteria ) );
    const encoder = new TextEncoder();
    const utf8Array = encoder.encode(JSON.stringify( criteria ));

    let binaryStr = '';
    for (let byte of utf8Array) {
        binaryStr += String.fromCharCode(byte);
    }
    // sessionStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(JSON.stringify( criteria )));
    // sessionStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(binaryStr));
    localStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(binaryStr));
  };

  const gettingSearch = () => { 
    // const value = sessionStorage.getItem(`${PROJECT_KEY}_${key}_cri`);
    const value = localStorage.getItem(`${PROJECT_KEY}_${key}_cri`);
    if( !value ) return {};

    const binaryStr = dcode(value); 
    // Convert the binary string to a Uint8Array
    const utf8Array = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
        utf8Array[i] = binaryStr.charCodeAt(i);
    }

    // Decode the UTF-8 byte array to a string
    const decoder = new TextDecoder();  
    return JSON.parse( decoder.decode(utf8Array) );
  }; 

  const updateColumn = (column) => {
    if( !key ) return;

    // console.log( criteria );
    // console.log( JSON.stringify( criteria ) );
    const encoder = new TextEncoder();
    const utf8Array = encoder.encode(JSON.stringify( column ));

    let binaryStr = '';
    for (let byte of utf8Array) {
        binaryStr += String.fromCharCode(byte);
    }
    // sessionStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(JSON.stringify( criteria )));
    // sessionStorage.setItem(`${PROJECT_KEY}_${key}_col`, ecode(binaryStr));
    localStorage.setItem(`${PROJECT_KEY}_${key}_col`, ecode(binaryStr));
    
  };

  const gettingColumn = () => { 
    // const value = sessionStorage.getItem(`${PROJECT_KEY}_${key}_col`);
    const value = localStorage.getItem(`${PROJECT_KEY}_${key}_col`);
    // console.log( value );
    if( !value ) return undefined;

    const binaryStr = dcode(value); 
    // Convert the binary string to a Uint8Array
    const utf8Array = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
        utf8Array[i] = binaryStr.charCodeAt(i);
    }

    // Decode the UTF-8 byte array to a string
    const decoder = new TextDecoder();  
    const res = JSON.parse( decoder.decode(utf8Array) );

    // console.log( res );
    return res?.length < 1 ? undefined : res;
  }; 

  const columnOrder = (column, order, field) => { 
    return  !!field ? column.map( col => {
      if( col.key === field ) col["sortOrder"] = order;
      else delete col["sortOrder"];
      return col;
    }) : column
  }
  return { 
    updateSearch,
    gettingSearch, 
    columnOrder,

    updateColumn,
    gettingColumn,
  };
};

export default useSearch;