/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Input, AutoComplete, Space, Button, InputNumber, Select, DatePicker, Switch } from "antd";
import { CheckOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { MenuOutlined } from '@ant-design/icons'; 
import { 
  useSortable, 
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const EditableContext = React.createContext(null);

const RowDragContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm(); 

  return (
    <Form form={form} component={false} autoComplete="off">
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    fieldType,
    required,
    readonly,
    type,
    autocompleteOption,
    modalSelect,
    optionsItems,
    childProps = {},
    formProps = {},
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const [autoOpen, setAutoOpen] = useState(false);
    const inputRef = useRef(null);
    const selectRef = useRef(null);
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
 
    type = type || 'input';
    // const textAreaRef = useRef(null);
    const form = useContext(EditableContext);
    // console.log(optionsItems);
    useEffect(() => {
      if (editing && !readonly) { 
        inputRef.current?.focus(); 

        inputRef.current?.select();
        // console.log( inputRef.current );
      }
      if( autoOpen ){
        selectRef.current?.focus(); 
      }
    }, [editing, readonly]);

    useEffect(()=>{
      if( type === "switch"){
        form.setFieldValue(dataIndex, !!Number(record[dataIndex]));
        // console.log( record )
      }

    }, [form])
    
    const toggleEdit = () => {
      setEditing(!editing && !readonly);
      setAutoOpen(!autoOpen);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        const s = {
          ...record,
          ...values,
          key:dataIndex,
        };
        
        // console.log( s );
        
        handleSave(s, dataIndex, form);
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      } finally {
        toggleEdit();
      }
    };

    const selected = async () => {
      try { 
        const values = await form.validateFields();
        // toggleEdit();
        // console.log( values, values[dataIndex])
        handleSave({
          ...record,
          ...values,
          key:dataIndex,
        }, dataIndex, form);
      } catch (errInfo) {
        toggleEdit();
        // console.log('Save failed:', errInfo);
      } finally {
        toggleEdit();
      }
    };

    const switced = async (e) => {
      try {
        const values = await form.validateFields();
        // toggleEdit(); 
        handleSave({
          ...record,
          ...values,
          key:dataIndex,
        }, dataIndex, form);
      } catch (errInfo) {
        // console.log('Save failed:', errInfo);
      }
    };

    const changed = async (date, value) => {
      try { 
        // const values = await form.validateFields();
        // toggleEdit();
        // console.log( date, value )
        if( !value ) {
          toggleEdit();
        } 
        handleSave({
          ...record, 
          key:dataIndex,
          [dataIndex]: date,
        }, dataIndex, form);
      } catch (errInfo) {
        toggleEdit();
        // console.log('Save failed:', errInfo);
      } finally {
        toggleEdit();
      }
    };

    let childNode = children;
    if (editable) { 
        childNode = (editing || ['switch'].includes(type)) ? (
          <Form.Item 
            style={{ margin: 0, }}
            name={dataIndex}
            rules={[ { required: !!required, message: `${title} is required.`,  }, ]}
            {...formProps}
          >
            {(type === 'input') && (
              <Input 
                placeholder="กรอกข้อมูล"
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                style={{height:32, minWidth: "none", textAlign:"end", ...restProps.style}}
                className='ant-input'
                autoComplete="off"
                readOnly={readonly}
              />
            )}

            {(type === 'textarea') && (<textarea rows={2} ref={inputRef} onBlur={save} style={{width:"100%", height:64,}} className='ant-input' readOnly={readonly} ></textarea>)}

            {(type === 'autocomplete') && (
              <AutoComplete
                children={<Input ref={inputRef} style={{height:32, ...restProps.style}} />} 
                onBlur={save}
                onSelect={save}
                options={autocompleteOption}
                placeholder="Enter value"
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            )}

            {(type === 'modal-select') && (
              <Space.Compact style={{ width: '100%', height:32,  minWidth: "none" }} onBlur={selected} >
                  <Input readOnly placeholder='Choose value' value={record[dataIndex]} style={{height:32, ...restProps.style}}  />
                  <Button 
                  className='bn-secondary bn-center bn-action' 
                  size='small' 
                  icon={<SearchOutlined />} 
                  onClick={(e) => modalSelect(e, record)} 
                  style={{
                    minWidth:40, 
                    height:32, 
                    borderRadius:6, 
                    borderStartStartRadius: 0,
                    borderEndStartRadius: 0,
                  }} 
                  ></Button>
              </Space.Compact> 
            )}

            {(type === 'number') && (
              <InputNumber
                placeholder="กรอกข้อมูล"
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                style={{height:32,  minWidth: "none", textAlign:"end", ...restProps.style}}
                className='width-100 input-30 !text-end'
                autoComplete="off"
                readOnly={readonly}
                controls={false}
                {...childProps}
              />
            )}

            {(type === 'select') && (
              <Select
                allowClear
                autoClearSearchValue={false}
                style={{ width: '100%' }}
                className="input-30"
                placeholder="เลือกข้อมูล"
                options={optionsItems.map( m => ({ label:m.label, value:m.value }))}
                filterOption={filterOption}
                onSelect={selected}
                disabled={readonly}
                defaultOpen={true}
                ref={selectRef}
                {...childProps}
              />
            )}

            {(type === 'date') && (
              <DatePicker 
                style={{ width: '100%' }}
                className={`input-30 ${childProps?.className}`}
                disabled={readonly}
                open={autoOpen}
                {...childProps}
                onChange={changed}
                onOpenChange={(e) => { !e && toggleEdit(); setAutoOpen(!!e); } }
              />
            )}

            {(type === 'switch') && (
              <Switch 
                // checked={!!Number(record[dataIndex])}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}  
                onChange={switced}
              />
            )}
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={!['switch'].includes(type) ? { 
              paddingRight: 8, 
              height: !!fieldType ? 64 : 32, 
              border:"1px solid #eee", 
              borderRadius:6,
              lineHeight: '1.4rem',
              ...restProps.style
              } : {...restProps.style}}
              onClick={toggleEdit}
              onBlur={toggleEdit}
            >
            {children}
          </div>
        );
    }
    return <td {...restProps} onBlur={ ()=> (type === 'select') && toggleEdit() } >{childNode}</td>;
};

export const RowDragEdit = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const [form] = Form.useForm();
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 999,
        }
      : {}),
  };
  return (
    <Form form={form} component={false} autoComplete="off">
    <EditableContext.Provider value={form}>
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
    </EditableContext.Provider>
    </Form>
  );
};

export const RowDragWithHeader = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = React.useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowDragContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowDragContext.Provider>
  );
};

export const RowDrag  = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  }; 
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};
 

export const ComponentDragWithHeader = {
  body: { row: RowDragWithHeader },
};

export const ComponentDrag = {
  body: { row: RowDrag },
};

export const ComponentDragEdit = {
  body: { row: RowDragEdit, cell: EditableCell },
};

export const ComponentEdit = {
  body: { row: EditableRow, cell: EditableCell },
};