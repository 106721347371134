/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AutoComplete, Col, DatePicker, Divider, Flex, Form, Input, message, Radio } from 'antd';
import { Modal, Row, Space, Table, Typography } from 'antd';
 
// import { StockItemsModal } from '../../components/modal/stock-items';
import { ItemForPurchaseOrderModal } from '../../components/modal/purchase-order/index.js';

import { address, comma, filterComplete } from '../../utils/util';

import { columnsDetailsEditable, columnDetail, formData } from "./model";
import { ComponentEdit } from '../../components/table/TableEditable';
import { TableSearchValue } from '../../components/form'; 
import { TbExclamationCircle } from 'react-icons/tb';

import { useTbSearchContext } from "../../store/context/table-search.context"
import dayjs from "dayjs";

import OptionService from "../../service/Options.service.js"
import { CustomersInput } from '../../components/modal/customers';
import { PAYMENT_CONDITION } from '../../constant/constant.js';
import { CarsInput } from '../../components/modal/cars/index.js';
import useConfirm from '../../store/hook/use-confirm.hook.js';
import { PaymentMethodsInput } from '../../components/modal/payment-methods/index.js';
import { v4 as uuidv4 } from 'uuid';

const DATEFORMAT = 'DD/MM/YYYY';
const optrequest = OptionService();
export default function ManageForm({formName = "form-name", submit, initeial}) { 
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const context = useTbSearchContext();
    const confirms = useConfirm();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const [formValue, setFormValue] = React.useState(formData);
    const [listSouce, setListSouce] = React.useState([]);

    const [customers, setCustomers] = React.useState(undefined);
    const [openItemsList, setOpenItemsList] = React.useState(false);

    const [optPaymentCond, setOptPaymentCond] = React.useState([]);
    const [optCarBrand, setOptCarBrand] = React.useState([]);
    const [optCarModel, setOptCarModel] = React.useState([]);

    const [isVat, setIsVat] = React.useState(true); 

    const onFinish = async (values) => {
        if( listSouce.length < 1) return;
        try{ 
            const result = await confirms.saved();
            if( !result ) return;
        
            const head = {...formValue, ...values};
            
            head.qtdate = dayjs(head?.qtdate).format("YYYY-MM-DD");
            head.confirm_date = dayjs(head?.confirm_date).format("YYYY-MM-DD");

            const list = [...listSouce];
          submit({head, list});
        } catch (e) {
            console.log(e)
            message.error(`เกิดข้อผิดพลาด ${e?.message || 'ไม่สามารถบันทึกได้'}`)
        }
    };


    const handleClickCell = (record) => {
        context.clickSelect( "uuid", record);
    }

    const handleSave = async (row) => {
      const newData = async (r) => {
        // console.log( r );
        if( Number( r?.amount) > Number(r?.stock_qty) && Number( r?.cat_count_stock || 0 ) === 1 ){
            const result = await modal.confirm({title: 'จำนวนสินค้าที่กรอกมากกว่าสต๊อกที่มีอยู่', content: 'ยืนยันที่จะทำรายการต่อหรือไม่'});
            if( !result ) {
                r = {...r, amount: 0};
            }
        }

        const itemDetail = [...listSouce];
        const newData = [...itemDetail];
        
        const ind = newData.findIndex((item) => r?.uuid === item?.uuid);
        if (ind < 0) return itemDetail;
        const item = newData[ind];
        newData.splice(ind, 1, { ...item, ...r});

        // const price_total = newData.reduce( (a,v) => a += ( (Number(v.amount || 0 ) * Number(v?.price ||  0)) * (1-(Number(v?.discount ||  0)/100) ) ), 0);
        const price_total = newData.reduce( (a,v) => a += ( (Number(v.amount || 0 ) * Number(v?.price ||  0)) - Number(v?.discount ||  0) ), 0);
        const vat = price_total * (isVat ? 0.07 : 0);
        const grand_total = price_total + vat;

        setFormValue(state => ({
            ...state, 
            vat,
            price_total,
            grand_total
        }));

        return newData;
      };

    //   const data = await newData(row);
    //   console.log( data );
      setListSouce([...(await newData(row))]);
    };

    const handleDelete = (key) => {
        Modal.confirm({
          title: <Flex align='center' gap={2} className='text-red-700' ><TbExclamationCircle style={{fontSize:'1.5rem'}} />{'ยืนยันที่จะลบรายการสินค้า'}</Flex>, 
          icon:<></>,
          content: 'ต้องการลบรายการสินค้าออกจากรายการใบเสนอราคา ใช่หรือไม่',
          okText: 'ยืนยัน',
          okType: 'danger',
          cancelText: 'ยกเลิก',
          onOk() { 
            setListSouce((state) => state.filter( soc => soc.uuid !== key));
          },
          // onCancel() { },
        });
    }

    const handleItemsChoosed  = (value) => {
        // console.log( value );
        const newData = value?.map( v => ({ 
            ...v, 
            qtdetail: v?.qtdetail || `${v?.stname || ''}${!!v?.material_code ? `/${v?.material_code}` : ''}` ,
            uuid: v?.uuid || uuidv4(), 
        }) );
        setListSouce(s => [...s, ...newData]);
    }

    useEffect( () => { 
        const { head, list } = initeial;
        const init = {...head, isvat:!(Number( head?.vat || 0) === 0)};
        form.setFieldsValue(init);
        setFormValue(head);
        setListSouce(list);
        // setIsVat(init.isvat);

        setCustomers( {...head} );
        return () => {};
    }, [initeial]);

    useEffect( () => {
        // console.log( isVat );
        const vat = (isVat ? 0.07 : 0);
        setFormValue(state => {
            const total = Number( state.total || 0)
            return {
                ...state, 
                vat:vat * total, 
                grand_total: total + (vat * total), 
                vat_percent: vat
            }
        });
    }, [isVat])

    useEffect( () => {
        const initeil = async () => {
            const [
                optPaymentCondRes, 
                // optCarBrandRes, 
                // optCarModelRes
            ] = await Promise.all([
                optrequest.optionsQuotation({p:"payment-condition-option"}),
                // optrequest.optionsQuotation({p:"brand-car-option"}),
                // optrequest.optionsModel(),
            ]);

            const paymentCond = optPaymentCondRes.data.data;
            const optPaymentCondDup = [ ...new Set([ ...(PAYMENT_CONDITION.map(m => JSON.stringify(m))), ...(paymentCond.map(m => JSON.stringify(m))) ]) ];
            setOptPaymentCond( optPaymentCondDup.map( m => JSON.parse(m)) );
            // setOptCarModel( optCarModelRes.data.data?.map( m => ({value: m.modelname })) );

            // const b = [ ...new Set( [...(optCarBrandRes.data.data?.map( m => m.value )), ...(CAR_BRAND_OPTON.map( m => m.value ))] )]?.map( v => ({value:v}));
            // setOptCarBrand( b );
        }

        initeil(); 
        return () => {};
    }, []);
    

    const handleCustomer = (val, key) => {
        // console.log( val );
        if(!val) return;
        const { taxnumber, taxno } = val; 

        const addr = address(val);
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            address:addr, 
            remark: valueForm.remark,
            taxno:taxnumber || taxno, 
            car_no: null,
            car_brand: null,
            car_model: null,
            car_province: null,
            car_type: null,
            car_color: null, 
        };
        form.setFieldsValue( newIntieial );
        setCustomers( {...val} );
        setFormValue( s => ({...s, ...newIntieial}));
    }

    
    const handleCars = (val, key) => {
        // console.log( val, key );
        if(!val) return;
        const { taxnumber } = val;
        const addr = address(val);
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            taxno:taxnumber,
            address:addr,
        };
        form.setFieldsValue( newIntieial );
        setCustomers( {...val} );
        setFormValue( s => ({ ...s, ...newIntieial }) );
    }

    const handlePaymentMethod = (v) => {
        setFormValue( s => ({ ...s, payment_cond: v?.payment_name }) );
        form.setFieldValue("payment_cond", v?.payment_name);
    }

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลใบเสนอราคา</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ใบเสนอราคา" name="qtdate" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={DATEFORMAT} placeholder='กำหนดวันที่ใบเสนอราคา' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ยืนยันราคา :" name="confirm_date" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={DATEFORMAT} placeholder='กำหนดวันที่ยืนยันราคา' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="เงือนไขการชำระเงิน" name="payment_cond"  rules={[{...formRole, required: Number( customers?.customer_type || 0 ) === 1 || !customers }]}>
                    {/* <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optPaymentCond}
                        filterOption={filterComplete}
                        placeholder='กรอกเงือนไขการชำระเงิน'
                    >
                    </AutoComplete> */}
                    <PaymentMethodsInput className="!text-black" selected={handlePaymentMethod} value={formData?.payment_cond} />
                </Form.Item>
            </Col>            
            {/* <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="เลขใบสั่งซื้อลูกค้า" name="pocode" >
                <Input placeholder='กรอกเลขใบสั่งซื้อลูกค้า' style={{ width: "100%", height:40 }} />
              </Form.Item>
            </Col>  */}
        </Row>
        {/* <Row className='!mx-0' gutter={[8,8]}> 
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="ภาษีมูลค่าเพิ่ม (Vat)" name="isvat">
                <Radio.Group onChange={(e) => setIsVat(e.target.value)} className='gap-3 flex-wrap'>
                    <Radio value={true}>มี</Radio>
                    <Radio value={false}>ไม่มี</Radio> 
                </Radio.Group>
              </Form.Item>
            </Col>  
        </Row> */}
    </>)

    const customer = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="รหัสลูกค้า" htmlFor="cuscode-1"  name="cuscode" rules={[formRole]} >
                    <CustomersInput selected={handleCustomer} placeholder='เลือกลูกค้า' id='cuscode-1' className='!bg-zinc-50 !text-black' value={formValue.cuscode} />
                    {/* <Input disabled placeholder='รหัสลูกค้า' className='!bg-zinc-50 !text-black' /> */}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={18} lg={18}>
                <Form.Item label="ชื่อลูกค้า" name="cusname" rules={[formRole]} >
                    <Input disabled placeholder='ชื่อลูกค้า' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เลขผู้เสียภาษี/เลขบัตรประชาชน"  name="taxno" >
                    <Input placeholder='เลขผู้เสียภาษี' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ติดต่อ"  name="contact_name" >
                    <Input placeholder='ติดต่อ' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เบอร์โทร"  name="contact_tel" >
                    <Input placeholder='เบอร์โทร' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="อีเมล"  name="contact_email" >
                    <Input placeholder='อีเมล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item label="ที่อยู่ลูกค้า"  name="address" rules={[formRole]} >
                    <Input.TextArea placeholder='ที่อยู่' rows={2} />
                </Form.Item>
            </Col>
        </Row> 
    </>);

    const cars = (<>
        <Divider {...dividerProp}>ข้อมูลรถยนต์</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={24} md={6} lg={6} rules={[formRole]} >
                <Form.Item label="ทะเบียนรถ"  name="car_no" >
                    <CarsInput selected={handleCars} placeholder='เลือกทะเบียนรถ' id='car_no-1' className='!bg-zinc-50 !text-black' value={formValue.carno} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9}>
                <Form.Item label="ยี่ห้อรถ" name="car_brand" >
                    <Input disabled placeholder='กรอกยี่ห้อรถ' />
                    {/* <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarBrand}
                        filterOption={filterComplete}
                        placeholder='กรอกกรอกยี่ห้อรถ'
                    >
                    </AutoComplete>                     */}
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9}>
                <Form.Item label="รุ่น"  name="car_model" >
                    <Input placeholder='กรอกรุ่น' />
                    {/* <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarModel}
                        filterOption={filterComplete}
                        placeholder='กรอกรุ่น'
                    >
                    </AutoComplete> */}
                </Form.Item>
            </Col>
        </Row> 
    </>);
 
    const lists = (<>
        <Divider {...dividerProp}>รายละเอียด</Divider>
        <TableSearchValue
            title='รายการใบเสนอราคา'
            onDelete={handleDelete}
            onCreate={()=> setOpenItemsList(true)}
            notSelect={true}
            tbProps={{
                components:ComponentEdit,
                rowKey:"uuid",
                dataSource:listSouce,
                columns:columnsDetailsEditable(handleSave, columnDetail(handleClickCell)),
                pagination: false,
                locale:{...emptyText},
                summary: (record) => totalPrice(record)
            }}
        />
    </>);

    const totalPrice = (record) => { 
        const priceTotal = record?.reduce( (a, v) => a += (Number(v?.amount || 0) * Number( v?.price || 0)), 0.00)
        
        
        return (<>
            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={5} rowSpan={3} className='!bg-white align-top'>
                    <Form.Item name="remark" label="หมายเหตุ" className='!m-0'> 
                        <Input.TextArea placeholder='กรอกหมายเหตุ' rows={3} className='!resize-none' />
                    </Form.Item> 
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2} align='end' className='!pe-4 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text type="danger">{ comma(priceTotal,2,2) }</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>
            {/* <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ภาษีมูลค่าเพิ่ม { isVat && '(7%)'}</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
                    <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.vat || 0),2,2) } บาท</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row> 
            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ยอดรวมสุทธิ</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
                <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.grand_total || 0),2,2) } บาท</Typography.Text> 
                </Table.Summary.Cell> 
            </Table.Summary.Row> */}
        </>)
    }

    return (<>
    <Space direction='vertical' className='w-full'>
        <Form
            form={form} 
            layout='vertical'
            name={formName}
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)} 
            onFinish={onFinish}
            onFinishFailed={(e) => {
                const { errorFields } = e
                const [ error ] = errorFields || []
                const [ field_name ] = error?.name || []
                // console.log( { errorFields, error, field_name })

                setTimeout( () => { document.getElementById(`${formName}_${field_name}`)?.focus(); }, 400)
                
                message.error("กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน")
            }}
        >
            {information}
            {customer}
            {false && cars}
            {lists}
        </Form>
    </Space>
    
    { openItemsList && 
        <ItemForPurchaseOrderModal 
            show={openItemsList} 
            values={handleItemsChoosed} 
            close={() => setOpenItemsList(false)}
            // selected={listSouce}
            selected={[]}
            issale={true}
        />
    }
    {contextHolder}
    </>)
}
