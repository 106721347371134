import { Form, Input, message, Radio, Select, Typography } from "antd";
import { comma, filterOption, PROVINCE_OPTIONS } from "../../../utils/util";  
import { CustomersInput } from '../../../components/modal/customers';
export const cusItem = (val, handleCustomer) => [
  {
    key: 'cuscode',
    label: 'รหัสลูกค้า',
    span: { xl: 1, xxl: 1 },
    // labelStyle:lableDesStyle
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.cusname || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3" htmlFor="cuscode-1"  name="cuscode" >
          <CustomersInput selected={handleCustomer} placeholder='เลือกลูกค้า' id='cuscode-1' className='!bg-zinc-50 !text-black' value={val.cuscode} />
        </Form.Item>
      </>
    ), 
  },
  {
    key: 'cusname',
    label: <>ชื่อลูกค้า { (!val.taxinvoice_id) && <strong className="ms-1 text-red-500">*</strong> }</>, 
    // children: <Typography.Text>{val?.cusname || '\u00A0'}</Typography.Text>, 
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.cusname || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3" name='cusname' >
            <Input placeholder='กรอกชื่อลูกค้า' />
        </Form.Item>
      </>
    ),
    // span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    // children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.taxnumber || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='taxnumber' >
            <Input placeholder='กรอกเลขผู้เสียภาษี' />
        </Form.Item>
      </>
    ),
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    // children: <Typography.Text>{val?.contact_name || '-'}</Typography.Text>,
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.contact_name || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='contact_name' >
            <Input placeholder='กรอกติดต่อ' />
        </Form.Item>
      </>
    ),
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    // children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.contact_tel || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='contact_tel' >
            <Input placeholder='กรอกเบอร์โทร' />
        </Form.Item>
      </>
    ),
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    // children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.contact_email || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='contact_email' >
            <Input placeholder='กรอกอีเมล' />
        </Form.Item>
      </>
    ),
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ลูกค้า', 
    // children: <Typography.Text className='text-wrap'>{val?.address || '-'}</Typography.Text>,
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.address || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='address' >
            <Input.TextArea placeholder='กรอกที่อยู่ลูกค้า' rows={3} />
        </Form.Item>
      </>
    ),
  },
];

export const carInfo = (val) => [
  {
    key: 'car_no',
    label: <>ทะเบียนรถ { (!val.taxinvoice_id) && <strong className="ms-1 text-red-500">*</strong> }</>,
    // span: { xl: 1, xxl: 1, lg: 1, md: 1 },
    // contentStyle: { paddingInline: '0 2rem'},
    children: (!!val.taxinvoice_id 
      ? <Typography.Text>{ val?.car_no || '\u00A0'}</Typography.Text>
      : <>
        <Form.Item className="!mb-0 w-full pe-3"  name='car_no' rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]} >
            <Input placeholder='กรอกทะเบียนรถ' />
        </Form.Item>
      </>
    ),
  },
  {
    key: 'car_province',
    label: <>จังหวัด { (!val.taxinvoice_id && !!val.car_no) && <strong className="ms-1 text-red-500">*</strong> }</>,
    // span: { xl: 1, xxl: 1, lg: 1, md: 1 },
    // contentStyle: { paddingInline: '0 2rem'},
    children: ( !!val.taxinvoice_id 
      ? <Typography.Text>{ val?.car_province || '\u00A0'}</Typography.Text>
      : <> 
        <Form.Item className="!mb-0 w-full" name='car_province' rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]} >
          <Select 
            style={{...{ width: '100%', height: '40px' }}} 
            placeholder="เลือกจังหวัด"
            showSearch
            allowClear
            filterOption={filterOption}
            options={PROVINCE_OPTIONS} 
          />
        </Form.Item>
      </>
    ),
  }, 
];

export const infoVat = (val, invTypeChange) => [
  {
    key: 'invcode',
    label: <>เลขที่ใบกำกับภาษี { !val.invcode && <strong className="ms-1 text-red-500">*</strong> }</>,
    // span: { xl: 1, xxl: 1, lg: 1, md: 1 },
    // contentStyle: { paddingInline: '0 2rem'},
    children: (
      !!val.taxinvoice_id ? <Typography.Text>{ val?.invcode || '\u00A0'}</Typography.Text>
      :<>
        <Form.Item className="!mb-0 w-full pe-3"  name='invcode'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
            <Input placeholder='กรอกเลขที่ใบกำกับภาษี' maxLength={7} />
        </Form.Item>
      </>
    ),
  },
  {
    key: 'invtype',
    label: <>รูปแบบใบกำกับภาษี { !val.taxinvoice_id && <strong className="ms-1 text-red-500">*</strong> }</>,
    // span: { xl: 1, xxl: 1, lg: 1, md: 1 },
    // contentStyle: { paddingInline: '0 2rem'},
    children: (
      !!val.taxinvoice_id ? <Typography.Text>{ Number(val?.invtype || 0) === 0 ? "แยก VAT" : "รวม VAT"}</Typography.Text>
      :<>
        <Form.Item className="!mb-0 w-full" name='invtype' rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
          <Radio.Group buttonStyle="solid" disabled={!!val.taxinvoice_id} onChange={invTypeChange} >
            <Radio.Button value={0}>แยก VAT</Radio.Button>
            <Radio.Button value={1}>รวม VAT</Radio.Button> 
          </Radio.Group>
        </Form.Item>
      </>
    ),
  },
  { key: 'no-taxinvoice_id' }
];

const onCell = (handleClick) => ({
  onCell: (recoed, index) => ({
    onClick: () => { (typeof handleClick === "function" ) && handleClick(recoed, index) },
    className: `field-edits hover:cursor-pointer`,  
  })
});

// const calTotalDiscount = (rec) => {
//   const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
//   const discount = 1 - ( Number(rec?.discount ||  0) / 100 ); 
//   return total * discount;
// };

export const columnView = (clickCell = undefined) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 60, 
    style: { borderStartStartRadius: 0},
    ...onCell(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
    ...onCell(clickCell),
    render: (v) => v?.replace( "/VAT" , "")
  },
  // {
  //   title: "รหัสสินค้า VAT",
  //   dataIndex: "stcode_vat",
  //   key: "stcode_vat",
  //   width: 120,
  //   align: "left",
  //   ...onCell(clickCell),
  //   render: (v) => v?.replace( "/VAT" , "")
  // },
  {
    title: "ชื่อสินค้า",
    dataIndex: "rpodetail",
    key: "rpodetail", 
    align: "left", 
    // ...onCell(clickCell), 
    editable: true,
  },
  // {
  //   title: "สต๊อก vat",
  //   dataIndex: "stock_vat_qty",
  //   key: "stock_vat_qty",
  //   width: 90,
  //   align: "right",
  //   className: "!pe-3",
  //   style:{borderStartEndradius: 0},
  //   ...onCell(clickCell),
  //   render: (_, rec) => <>{ stockVatRender( rec )}</>,
  // },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount", 
    width: 120,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    onCell: () => ({
      style: {
        maxWidth: 120
      }
    }),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคาชื้อ",
    dataIndex: "price",
    key: "price", 
    width: 120,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด",
    dataIndex: "discount",
    key: "discount",
    width: 120,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "price_total",
    key: "price_total",
    width: 160,
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell), 
    // render: (_, rec) => <>{ comma( calTotalDiscount({...rec, qty: rec?.amount || 0 }),  2, 2 )} บาท</>,
    render: (v) => <>{ comma( Number( v || 0 ),  2, 2 )} บาท</>,
  }
];

export const columnViewDetail = (clickCell = undefined) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 60, 
    style: { borderStartStartRadius: 0},
    ...onCell(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
    ...onCell(clickCell),
  },
  {
    title: "รหัสสินค้า VAT",
    dataIndex: "stcode_vat",
    key: "stcode_vat",
    width: 120,
    align: "left",
    ...onCell(clickCell),
    render: (v) => v?.replace( "/VAT" , "")
  },
  {
    title: "ชื่อสินค้า VAT",
    dataIndex: "rpodetail",
    key: "rpodetail", 
    align: "left", 
    // ...onCell(clickCell), 
    editable: true,
  },
  {
    title: "สต๊อก vat",
    dataIndex: "stock_vat_qty",
    key: "stock_vat_qty",
    width: 90,
    align: "right",
    className: "!pe-3",
    style:{borderStartEndradius: 0},
    ...onCell(clickCell),
    render: (_, rec) => <>{ stockVatRender( rec )}</>,
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount", 
    width: 110,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    onCell: () => ({
      style: {
        maxWidth: 110
      }
    }),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคาชื้อ",
    dataIndex: "price",
    key: "price", 
    width: 110,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด",
    dataIndex: "discount",
    key: "discount",
    width: 110,
    align: "right",
    className: "!pe-3",
    // ...onCell(clickCell),
    editable: true,
    type:'number',
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "price_total",
    key: "price_total",
    width: 150,
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell), 
    // render: (_, rec) => <>{ comma( calTotalDiscount({...rec, qty: rec?.amount || 0 }),  2, 2 )} บาท</>,
    render: (v) => <>{ comma( Number( v || 0 ),  2, 2 )} บาท</>,
  }
];

export const columnViewDetailEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};


const stockVatRender = (rec) => {
  const { cat_stock_by_product, cat_count_stock, amount, stock_vat_qty } = rec;
  const has_asone = Number( cat_stock_by_product || 0 ) === 1;
  const has_count = Number( cat_count_stock || 0 ) === 1 && ( has_asone || true ); // ไม่ชัวว่าใช่มั้ยเลย or true ไว้ก่อน
  const has_stock = Number( stock_vat_qty || 0 ) >= Number( amount || 0 );

  if ( has_count && has_stock ) return comma( Number( rec?.stock_vat_qty || 0 ) );
  else if( has_count && !has_stock ) return <span>สต๊อกไม่พอ ({comma( Number( rec?.stock_vat_qty || 0 ) )})</span>
  else return <span>ไม่มีสต๊อก</span>;
}

export const stockVat = (rec) => {
  const { cat_stock_by_product, cat_count_stock, amount, stock_vat_qty } = rec;
  const hasAsone = Number( cat_stock_by_product || 0 ) === 1;
  const hasCount = Number( cat_count_stock || 0 ) === 1;
  const hasStock = Number( stock_vat_qty || 0 ) >= Number( amount || 0 );
  const notCount = Number( cat_count_stock || 0 ) === 0;
  const result = hasCount && hasStock && ( hasAsone || true ); // ไม่ชัวว่าใช่มั้ยเลย or true ไว้ก่อน
  return result || notCount;
}

export const vildateListForTaxInvoice = ( list ) => {
  if( !Array.isArray(list) ) return false;
  
  if( list?.length < 1 ) return false;
  
  const not_stock = list.some( s => Number( s.cat_count_stock || 0 ) === 1 && Number( s.stock_vat_qty || 0 ) <  Number( s.amount || 0 ));

  if( not_stock ) message.warning("มีสต๊อก vat ไม่เพียงพอ");

  const result = list.some( s => !stockVat(s) ); 
  return !result;
}