// import { Flex, Tag } from "antd";
// import { TbBasketFilled } from "react-icons/tb";
import dayjs from "dayjs";
import { TagStockStatus } from "../../badge-and-tag"; 
import { comma } from "../../../utils/util";
import { Typography } from "antd";
export const myModalColumn = () => [
    {        
        title: "เลขใบสั่งซื้อ",
        dataIndex: "purcode",
        key: "purcode",
        width: 130, 
        align: "left",
        sorter: true, 
    },
    {
        title: "รหัสผู้ขาย",
        dataIndex: "supcode",
        key: "supcode",
        width: 130, 
        align: "left",
        sorter: true,
    },
    {
        title: "ชื่อผู้ขาย",
        dataIndex: "supname",
        key: "supname", 
        align: "left",
        sorter: true,  
    },
    {
        title: "ติดต่อ",
        dataIndex: "contact",
        key: "contact",  
        align: "left",
        sorter: true,
    },
    {
      title: "วันที่สั่งซื้อ",
      dataIndex: "purdate",
      key: "purdate",
      width: 140,
      align: "left",
      sorter: true,
      render: (v) => dayjs(v).format("DD/MM/YYYY"),
    },
    {
      title: "วันที่ส่งของ",
      dataIndex: "duedate",
      key: "duedate",
      width: 140,
      align: "left",
      sorter: true,
      render: (v) => dayjs(v).format("DD/MM/YYYY"),
    },
]; 

export const myModalItemsColumn = () => [
    {
        title: "รหัสสินค้า",
        dataIndex: "stcode",
        key: "stcode",
        width: 100, 
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อสินค้า",
        dataIndex: "stname",
        key: "stname", 
        align: "left",
        sorter: true,  
    },    
    {
        title: "สถานที่เก็บ",
        dataIndex: "location_name",
        key: "location_name", 
        align: "left",
        sorter: true, 
        width: 140,
    },
    // {
    //     title: "สต๊อก vat",
    //     dataIndex: "isvat",
    //     key: "isvat",  
    //     sorter: true, 
    //     width: 100, 
    //     align: "center",
    //     render:(v) => <TagIs result={v} />
    // },
    {
        title: "ราคาซื้อเฉลี่ย",
        dataIndex: "price",
        key: "price", 
        align: "right",
        sorter: true, 
        width: 120, 
        onCell: (_) => {
            return { className: '!pe-5'}
        },
        render:(v) => comma(Number( v || 0), 2, 2),
    },
    {
        title: "สต๊อก",
        dataIndex: "location_qty",
        key: "location_qty",
        align: "left",
        sorter: true,
        width: 120,
        render:(v) => <TagStockStatus result={v} />
    },  
];

export const myModalStockLocationColumn = () => {
    return [
        {
          title: "ลำดับ",
          dataIndex: "ind",
          key: "ind",
          width: 80, 
          className: "field-edit",
          render: (im, rc, index) => <>{index + 1}</>,
        },
        {
          title: "สถานที่เก็บ",
          dataIndex: "location_name",
          key: "location_name", 
          align: "left",
          className: "field-edit", 
        },
        // {
        //     title: "สต๊อก vat",
        //     dataIndex: "isvat",
        //     key: "isvat",
        //     className: "field-edit",
        //     width: 100, 
        //     align: "center",
        //     render:(v) => <TagIs result={v} />
        // },
        {
            title: "สต๊อก",
            dataIndex: "location_qty",
            key: "location_qty",
            align: "right",
            className: "!pe-3 field-edit",
            width: 120,
            render:(_, v) => comma( Number(v.location_qty) - Number(v?.qty || 0) )
        },
        {
            title: "จำนวน",
            dataIndex: "qty",
            key: "qty",
            width: "20%",
            align: "right",
            className: "!pe-3",
            editable: true,
            type:'number',
        },
    ]
} 

export const myModalStockLocationColumnEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };
  });
  // console.dir( cmm );
  return cmm;
};

export const myModalTiresColumn = () => [
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 100, 
    align: "left",
    sorter: true, 
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname", 
    align: "left",
    sorter: true,  
  },    
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_name",
    key: "location_name", 
    align: "left",
    sorter: true, 
    width: 140,
  },
  // {
  //   title: "สต๊อก vat",
  //   dataIndex: "isvat",
  //   key: "isvat",  
  //   sorter: true, 
  //   width: 100, 
  //   align: "center",
  //   render:(v) => <TagIs result={v} />
  // },
  {
    title: "ปีที่ผลิต",
    dataIndex: "mfyear",
    key: "mfyear",
    width: 90,
    render:(_, v) => v.mfyear
  },
  {
    title: "ไตรมาส",
    dataIndex: "quarter",
    key: "quarter",
    width: 90,
    render:(v) => `Q${v}`
  },  
  {
    title: "ราคา",
    dataIndex: "price",
    key: "price",
    align: "right",
    sorter: true,
    width: 100,
    onCell: (_) => {
      return { className: '!pe-5'}
    },
    render:(v) => comma(Number( v || 0), 2, 2),
  },
  {
    title: "สต๊อก",
    dataIndex: "tires_qty",
    key: "tires_qty",
    align: "left",
    sorter: true,
    width: 120,
    render:(v) => <TagStockStatus result={v} />
  },
];

export const myModalItemsTiresColumn = () => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    className: "field-edit",
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_name",
    key: "location_name", 
    align: "left",
    className: "field-edit", 
  },
  // {
  //   title: "สต๊อก vat",
  //   dataIndex: "isvat",
  //   key: "isvat",
  //   className: "field-edit",
  //   width: 100, 
  //   align: "center",
  //   render:(v) => <TagIs result={v} />
  // },
  {
    title: "ปีที่ผลิต",
    dataIndex: "mfyear",
    key: "mfyear",
    className: "field-edit",
    width: 120,
  },
  {
    title: "ไตรมาส",
    dataIndex: "quarter",
    key: "quarter",
    className: "field-edit",
    width: 120,
    render:(v) => `Q${v}`
  },
  {
    title: "สต๊อก",
    dataIndex: "tires_qty",
    key: "tires_qty",
    align: "right",
    className: "!pe-3 field-edit",
    width: 120,
    render:(_, v) => comma( Number(v.tires_qty) - Number(v?.qty || 0) )
  },
  {
    title: "จำนวน",
    dataIndex: "qty",
    key: "qty",
    width: "20%",
    align: "right",
    className: "!pe-3",
    editable: true,
    type:'number',
  },
];

export const myModalItemsTiresColumnEditable = ( handleSave, column ) => myModalStockLocationColumnEditable( handleSave, column );

export const lableDesStyle = { width: 160 }

export const infoItem = (val) => [
    {
      key: 'stcode',
      label: 'รหัสสินค้า', 
      children: <Typography.Text>{val?.stcode}</Typography.Text>,
      labelStyle:lableDesStyle
    },
    {
      key: 'stname',
      label: 'ชื่อสินค้า',
      children: <Typography.Text>{val?.stname}</Typography.Text>,
      labelStyle:lableDesStyle
    },
    {
      key: 'amount',
      label: 'จำนวนที่ต้องใช้',
      span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
      children: <Typography.Text  className="flex justify-end !pe-5 text-black">{comma(Number(val?.amount || 0))}</Typography.Text>,
      labelStyle:lableDesStyle,
      contentStyle: {backgroundColor: '#999999'}
    },
    {
      key: 'qty',
      label: 'จำนวนที่กรอก',
      span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
      children: <Typography.Text  className="flex justify-end !pe-5 text-black">{comma(Number(val?.qty || 0))}</Typography.Text>,
      labelStyle:lableDesStyle,
      contentStyle: {backgroundColor: '#999999'}
    },
    {
      key: 'remain',
      label: 'เหลือ',
      span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
      children: <Typography.Text  className="flex justify-end !pe-5 text-red-700">{comma(Number(val?.amount || 0) - Number(val?.qty || 0))}</Typography.Text>,
      labelStyle:lableDesStyle,
      contentStyle: {backgroundColor: '#999999'}
    },
];

export const infoItemNotQt = (val) => [
    {
      key: 'stcode',
      label: 'รหัสสินค้า', 
      children: <Typography.Text>{val?.stcode}</Typography.Text>,
      labelStyle:lableDesStyle
    },
    {
      key: 'stname',
      label: 'ชื่อสินค้า',
      children: <Typography.Text>{val?.stname}</Typography.Text>,
      labelStyle:lableDesStyle
    },
    // {
    //   key: 'amount',
    //   label: 'จำนวนที่ต้องใช้',
    //   span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
    //   children: <Typography.Text  className="flex justify-end !pe-5 text-black">{comma(Number(val?.amount || 0))}</Typography.Text>,
    //   labelStyle:lableDesStyle,
    //   contentStyle: {backgroundColor: '#999999'}
    // },
    {
      key: 'qty',
      label: 'จำนวนที่กรอก',
      span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
      children: <Typography.Text  className="flex justify-end !pe-5 text-green-300">{comma(Number(val?.qty || 0))}</Typography.Text>,
      labelStyle:lableDesStyle,
      contentStyle: {backgroundColor: '#999999'}
    },
    // {
    //   key: 'remain',
    //   label: 'เหลือ',
    //   span: { sm: 2, md: 2, lg:  2, xl: 2, xxl: 2 },
    //   children: <Typography.Text  className="flex justify-end !pe-5 text-red-700">{comma(Number(val?.amount || 0) - Number(val?.qty || 0))}</Typography.Text>,
    //   labelStyle:lableDesStyle,
    //   contentStyle: {backgroundColor: '#999999'}
    // },
];