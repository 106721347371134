import { message, Select, Tag } from 'antd';
import React from 'react';
import { filterOption } from '../../../utils/util';
import OptionService from "../../../service/Options.service";

const optrequest = OptionService();
export default function MySelect({ name="status", onChange, value=null, style={}, className="" }) {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() =>{ 
    const init = async () => {
      try{
        const [ optionsRes ] = await Promise.all([
          optrequest.optionsRepairOrder({p:"status"}),
        ]);

        setOptions(optionsRes.data.data?.filter( f => !['ยกเลิก', 'ชำระเงินครบแล้ว', 'ชำระเงินไม่ครบ'].includes(f?.label) ))
      } catch ( e ){
        console.log(e);
        message.error("เกิดข้อผิดพลาดจากการดึงข้อมูล");
      } 
    }

    init(); 
  }, []);

  const handleChange = (e, res) => {
    if( typeof onChange === "function") {
      onChange(e, res);
    }
  }

  return (
      <Select
        mode='multiple'
        defaultValue={value}
        style={{...{ width: '100%', height: '40px' }, ...style}}
        name={name}
        placeholder="เลือกสถานะ"
        className={className}
        showSearch
        filterOption={filterOption}
        options={options}
        onChange={handleChange} 
        autoClearSearchValue={false}
        // tagRender={(props) => <Tag {...props} color='var(--primary)' className='flex gap-1 items-center' >{props.label}</Tag>} 
        tagRender={(props) => {
          const res = { ...props, isMaxTag: 1 };
          delete res.isMaxTag;
          const newProp = {...res, ismaxtag: 1};
          // console.log( {newProp} );
          return <Tag {...newProp} color='var(--primary)' className='flex gap-1 items-center' >{props.label}</Tag>}
        }
        maxTagCount= 'responsive'
      />)
}
