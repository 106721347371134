import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd"; 
import Footer from "./Footer";
import Header from "./Header";

const { Content } = Layout;

function Main({ children }) {

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
  }, [pathname]);

  return ( 
    <Layout>
      <Header></Header>
      <Content className="content-ant sm:px-4 lg:px-8 xl:px-10 2xl:px-12 py-2 flex-col" style={{ overflow: "hidden" }}>
        {children} 
      </Content>
      <Footer />
    </Layout>
  );
}

export default Main;
