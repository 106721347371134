import { Button, Card, Col, Descriptions, Drawer, Flex, Input, InputNumber, message, Row, Space, Typography } from 'antd';
import React from 'react'; 
import useConfirm from '../../../store/hook/use-confirm.hook';
// import { TableSearchValue } from '../../form';
// import OptionService from '../../../service/Options.service.js';
// import { SelectStockLocation } from '../../select/index.js';
import dayjs from 'dayjs';
import { info } from './model.module.js';
import { CloseOutlined } from '@ant-design/icons'; 
import AdjustStockService from "../../../service/AdjustStock.service.js";
import { v4 as uuidv4 } from 'uuid'; 
 
// const optrequest = OptionService();
const adjrequest = AdjustStockService();
// const DATEFORMAT = 'DD/MM/YYYY';
export default function MyDrawerAdjustStock({show, value, close, title="รายการสต๊อก", source=[], record={}, keySelect=undefined}) {
    const confirms = useConfirm();
 

    const [closeResult, setCloseResult] = React.useState(false); 

    const [dataValue, setDataValue] = React.useState([]);
    const [dataHeader, setDataHeader] = React.useState([]);

    const [beforeStock, setBeforeStock] = React.useState(null);
    const [afterStock, setAfterStock] = React.useState(0);
    const [adjustRemark, setAdjustRemark] = React.useState(null);  
    // const [adjustDate, setAdjustDate] = React.useState(dayjs());

    const headStyle = {
        borderBottom: `2px solid var(--primary)`,
        backgroundColor: `var(--primary-90)`, 
    }

    const bodyStyle = {
        border: `2px solid var(--primary)`
    }

    const footStyle = {
        borderTop: `2px solid var(--primary)`, 
    }
    const [openDrawer, setOpenDrawer] = React.useState(show);
 

    const handleCancel = (result = false) => { 
        setCloseResult( result )
        setOpenDrawer(false);
    }
 

    React.useEffect(() => {
        const init = () => {
            if( source?.length > 0 ){ 
                
                const {stockid_vat:stockid, stock_vat_qty:stock_qty, vat_price:price} = source[0];
                const {cat_count_stock, cat_stock_by_product, stcode_vat:stcode} = record;

                setBeforeStock(stock_qty);
                setAfterStock(stock_qty);
                
                setDataHeader({ before_stock:stock_qty, stockid, cat_count_stock, cat_stock_by_product, stcode, price });
            }
        };
    
        init();
    }, [source, record]);
 
    const [drawerWidth, setDrawerWidth] = React.useState(50); 
  
    // Adjust drawer width based on window size
    const updateDrawerWidth = () => {
      if (window.innerWidth < 600) {
        setDrawerWidth(100);
      } else if (window.innerWidth < 1024) {
        setDrawerWidth(55);
      } else {
        setDrawerWidth(50);
      }
    };
  
    // Update width on window resize
    React.useEffect(() => {
      updateDrawerWidth();
      window.addEventListener('resize', updateDrawerWidth);
      return () => window.removeEventListener('resize', updateDrawerWidth);
    }, []);
  

    const handleDataChange = ( res ) => { 
        setDataValue([            {
            uuid:uuidv4(),
            location_code: null,
            qty: beforeStock,
            adjust_qty: res,
            remark: 'adjust stock vat',
        }]);
        setAfterStock(res);
    }

    const handleSubmitAdjust = async () => { 
        const submitData = [...dataValue];
        
        if( submitData.length < 1 ) {
            message.success("ปรับปรุงสต๊อกสำเร็จ");

            handleCancel(false);
            return;
        }

        const result = await confirms.confirm({content: "ยืนยันการปรับสต๊อกหรือไม่"});
        if(!result) return;

        const { cat_count_stock, cat_stock_by_product, price, } = dataHeader;

        const detail = [{  
            cat_count_stock,
            cat_stock_by_product,
            price,
            uuid:uuidv4(),
            location_code: null,
            qty: beforeStock,
            adjust_qty: afterStock,
            remark: 'adjust stock vat',
        }];

        const adjust_total_qty = detail?.reduce( (a, v) =>  a += Number( v?.adjust_qty ), 0)
        const header = {...dataHeader, qty:adjust_total_qty, ajdate: dayjs().format("YYYY-MM-DD HH:mm:ss"), adjustRemark, isvat: true }

        try {
            await adjrequest.adjustNormal( {header, detail} )
            setTimeout( () => handleCancel(true), 400);
        } catch(e) {

            console.log(e);
        } 
    }

    const InfoSection = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'> 
            <Col xs={24} sm={24} lg={24}> 
                <Descriptions 
                    title={false} 
                    colon={false} 
                    layout="vertical"  
                    size='small' 
                    column={{xs:1, sm:2, md:2, lg:4, xl:4, xxl:4}} 
                    items={ info({ ...record, before_stock: beforeStock, after_stock:afterStock }) } 
                /> 
            </Col> 
            <Col xs={24} sm={24} lg={24}>
                <Typography.Text style={{fontSize: 13, fontWeight: 600, color: '#8c8c8c'}}>หมายเหตุ</Typography.Text>
                <Input.TextArea placeholder='กรอกหมายเหตุ' rows={3} className='!resize-none' value={adjustRemark} onChange={(e) => setAdjustRemark(e.target.value)} />
            </Col>
        </Row>   
    </>);

    const commentFooter = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    {/*  */}
                    <Button  className='bn-center bn-secondary-outline min-w-32' onClick={() => handleCancel()} >ปิด</Button>
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button  className='bn-center bn-primary min-w-32' onClick={() => handleSubmitAdjust()} disabled={dataValue.length < 1} >ยืนยัน</Button>
                </Flex>
            </Col>
        </Row>
    </>);

    return (
        <>
            <Drawer
                title={<Typography.Text className='m-0 text-white'>{title}</Typography.Text> }
                maskClosable={false}
                closeIcon={<CloseOutlined className='text-white' />}
                onClose={()=>handleCancel()}
                open={openDrawer}
                key="comment"
                width={`${drawerWidth}%`}
                footer={commentFooter}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( closeResult );  
                    }
                }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        {InfoSection}
                    </Card> 
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'> 
                            <Col xs={24} sm={8} lg={8}>
                                <Typography.Text style={{fontSize: 13, fontWeight: 600, color: '#8c8c8c'}}>จำนวนสต๊อก</Typography.Text>
                                <InputNumber 
                                    placeholder='กรอกจำนวนสต๊อก' 
                                    controls={false} 
                                    value={afterStock}
                                    rootClassName='!w-full'
                                    onChange={handleDataChange} 
                                />
                            </Col>
                        </Row> 
                    </Card> 
                </Space> 
            </Drawer>
        </>
    )
}
