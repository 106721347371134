import React from 'react'
import { Card, Form, Typography, Flex, Divider, Row, Col,  Drawer, Input, Radio } from "antd"; 
import { TbPencil } from 'react-icons/tb';

const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
export default function MyFormManage({form, show, close, title="จัดการข้อมูล"}) {  
    const [openDrawer, setOpenDrawer] = React.useState(show); 
    const headStyle = {
        borderBottom: `2px solid var(--secondary)`,
        backgroundColor: '#d3d3d3',
    }

    const ThisTitle = (<>
        <Flex align='center' gap={4}>
            <TbPencil  style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>{title}</Typography.Text>
        </Flex>    
    </>); 

    const information = (<>
        <Divider {...dividerProp}>ข้อมูล</Divider>
        <Card style={{backgroundColor:'#f0f0f0' }}>
            <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
                <Col xs={24} sm={24} lg={24}>
                    <Form.Item label="รูปแบบการชำระ" name='payment_name'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}  >
                        <Input placeholder='กรอกรูปแบบการชำระ'/>
                    </Form.Item>  
                </Col>
                <Col xs={24} sm={24} lg={24}>
                    <Form.Item label="ประเภทการชำระ" name='payment_type'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
                        <Radio.Group  className='gap-3 flex-wrap' >
                            <Radio value={"เงินสด"}>เงินสด/ทั่วไป</Radio>
                            <Radio value={"โอน"}>โอน</Radio> 
                            <Radio value={"เช็ค"}>เช็ค</Radio>
                            <Radio value={"เครดิต"}>เครดิต</Radio> 
                            <Radio value={"บัตรเครดิต"}>บัตรเครดิต</Radio> 
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                    <Form.Item label="ชำระทันที" name='payment_now'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
                        <Radio.Group  className='gap-3 flex-wrap' >
                            <Radio value={1}>ใช่</Radio>
                            <Radio value={0}>ไม่ใช่</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>            
        </Card> 
    </>); 
  
    return (
        <>
            <Drawer
                title={ThisTitle}
                onClose={() => { setOpenDrawer(false); }}
                open={openDrawer} 
                width='100%'
                className="responsive-drawer" 
                afterOpenChange={(e)=>{ !e && close(false); }}
                getContainer={false}
                styles={{ header: headStyle }}
            > 
                <Form form={form} layout="vertical" autoComplete="off" >
                    {information}
                </Form> 
            </Drawer> 
        </>
    )
}

