import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Badge,
  DatePicker,
  InputNumber,
} from "antd";
import React, { useEffect } from "react";
import EmployeeService from "../../service/Employee.service";
import OptionService from "../../service/Options.service";

const empService = EmployeeService();
const opservice = OptionService();

export default function EmployeeManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const [optEmployee, setOptEmployee] = React.useState([]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    //  console.log(initeial);
    opservice.optionsEmployee().then((res) => {
      let { data } = res.data;
      setOptEmployee(data);
    });
    
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
    else
    {
      empService
      .getcode()
      .then((res) => {
        let { data } = res;
          form.setFieldsValue({
            empcode: data,
          });
      })
      .catch((err) => {});
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>ข้อมูลพนักงาน</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="empcode" label="รหัสพนักงาน" rules={[formRole]}>
              <Input disabled placeholder="กรอกรหัสพนักงาน" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="firstname" label="ชื่อ" rules={[formRole]}>
              <Input placeholder="กรอกชื่อพนักงาน" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="นามสกุล" name="lastname" rules={[formRole]}>
              <Input placeholder="กรอกนามสกุลพนักงาน" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="nickname" label="ชื่อเล่น">
              <Input placeholder="กรอกชื่อเล่น" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="citizen_id" label="เลขประจำตัวประชาชน">
              <Input placeholder="กรอกเลขประจำตัวประชาชน" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="dateofbirth" label="วันเกิด">
              <DatePicker
                style={{
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                size="large"
                placeholder="เลือกวันเกิด"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="tel" label="เบอร์โทร">
              <Input placeholder="กรอกเบอร์โทร" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={3}>
            <Form.Item name="marital_status" label="สถานภาพ">
              <Select
                size="large"
                placeholder="เลือกสถานภาพ"
                showSearch
                filterOption={filterOption}
                options={[
                  {
                    value: "โสด",
                    label: "โสด",
                  },
                  {
                    value: "แต่งงาน",
                    label: "แต่งงาน",
                  },
                  {
                    value: "หย่า",
                    label: "หย่า",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <Form.Item name="no_of_children" label="จำนวนบุตร">
              <InputNumber
                style={{
                  width: "100%",
                }}
                size="large"
                placeholder="กรอกจำนวนบุตร"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="position" label="ตำแหน่ง" rules={[formRole]}>
            <Select
                size="large"
                placeholder="เลือกระดับผู้ใช้"
                showSearch
                filterOption={filterOption}
                options={optEmployee.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
            
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="education" label="ระดับการศึกษาสูงสุด">
              <Select
                size="large"
                placeholder="เลือกระดับการศึกษาสูงสุด"
                showSearch
                filterOption={filterOption}
                options={[
                  {
                    value: "ป.6",
                    label: "ป.6",
                  },
                  {
                    value: "ม.6",
                    label: "ม.6",
                  },
                  {
                    value: "ปวช",
                    label: "ปวช",
                  },
                  {
                    value: "ปวส",
                    label: "ปวส",
                  },
                  {
                    value: "ป.ตรี",
                    label: "ป.ตรี",
                  },
                  {
                    value: "ป.โท",
                    label: "ป.โท",
                  },
                  {
                    value: "ป.เอก",
                    label: "ป.เอก",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider {...dividerProp}>ที่อยู่</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="ที่อยู่" name="cur_address">
              <TextArea rows={3} placeholder="กรอกที่อยู่" />
            </Form.Item>
          </Col>
        </Row>
        <Divider {...dividerProp}>เพิ่มเติม</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="dateofstart" label="วันที่เริ่มเข้างาน">
              <DatePicker
                style={{
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                size="large"
                placeholder="วันที่เริ่มเข้างาน"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="resign_date" label="วันที่ลาออก">
              <DatePicker
                style={{
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                size="large"
                placeholder="วันที่ลาออก	"
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
