/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Form, Input, Space } from 'antd';
import { QctPageLayout } from '../../components/layout';
import { FormSearchValue, TableSearchValue  } from "../../components/form";
// import { Typography } from 'antd';
import { accessColumn } from './model';
import useSearch from '../../store/hook/use-search.hook';
import QuotationService from "../../service/Quotation.service.js"; 

import dayjs from "dayjs";
 
const apirequest = QuotationService();
function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("quotation-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria,
    qtdate: cria?.qtdate?.map(m => dayjs(m)) || null,
    confirm_date: cria?.confirm_date?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 


  const [viewOnly, setViewOnly] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [hasRpocode, setHasRpocode] = useState(undefined);


  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบเสนอราคา" name="qtcode" >
          <Input placeholder="ใส่เลขใบเสนอราคา" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขที่ใบสั่งซื้อ" name="pocode" >
          <Input placeholder="ใส่ชื่อสั่งซื้อ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่ใบเสนอราคา' name='qtdate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่ยืนยันราคา' name='confirm_date'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสลูกค้า" name="cuscode" >
          <Input placeholder="ใส่รหัสลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อลูกค้า" name="cusname" >
          <Input placeholder="ใส่ชื่อลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [qtdate_form, qtdate_to] = criteria?.qtdate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [confirm_date_form, confirm_date_to] = criteria?.confirm_date?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 

    const newPram = {...criteria, qtdate_form, qtdate_to, confirm_date_form, confirm_date_to};
    const parm = { criteria:newPram, tbparams };
    
    apirequest.search(parm, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleRowSelect = (record, key) => {  
    setViewOnly( record?.approved_status !== 'N' && !!key ); 
    setSelected( !!key ? record : undefined ); 
    setHasRpocode( !!key && !!record.rpocode )
  }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, record) => { 
    const config = { mode:"update", acname: `แก้ไข #${record?.qtcode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = (code, record) => { 
    const config = { mode:"view", acname: `เลขที่ #${record?.qtcode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  const handlePrintsData = (code, record) => {
    const url = `/quotation-print/${code}`;
    // const newWindow = window.open('', url, url); 
    const newWindow = window.open(url, '_blank', 'width=960,height=820' ); 
    newWindow.location.href = url;
  }

  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
 

    const { order, field } = tbparamsDefault;
    const cols = accessColumn();
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  return (<>
  <QctPageLayout title={"ใบเสนอราคา"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการข้อมูลใบเสนอราคา'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        onSelectedRow={handleRowSelect}
        onCreate={handleCreatData}
        onUpdate={ (!hasRpocode && !!selected) && handleUpdateData}
        onReView={ viewOnly && !!selected && handleReviewData}
        onPrints={ viewOnly && !!selected && handlePrintsData}
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"qtcode",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access