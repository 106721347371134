/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Form, Input, message, Popover, Space } from 'antd';
import { QctPageLayout } from '../../components/layout';
import { FormSearchValue, TableSearchValue  } from "../../components/form";
import { SelectRepairOrderStatus } from "../../components/select"
// import { Typography } from 'antd';
import { accessColumn } from './model';
import useSearch from '../../store/hook/use-search.hook';
import RepairOrderService from "../../service/RepairOrder.service.js";  

import dayjs from "dayjs"; 
import { CancelButton } from '../../components/drawer/cancel/index.js';
// import { TbReceipt } from 'react-icons/tb';
 
const apirequest = RepairOrderService(); 
function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("repair-order-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria,
    rodate: cria?.rodate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination});  
  const [viewOnly, setViewOnly] = useState(false);
  const [submitStock, setSubmitStock] = useState(false);
  const [selected, setSelected] = useState(undefined);


  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบรายการซ่อม" name="rpocode" >
          <Input placeholder="ใส่เลขใบรายการซ่อม" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสลูกค้า" name="cuscode" >
          <Input placeholder="ใส่รหัสลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อลูกค้า" name="cusname" >
          <Input placeholder="ใส่ชื่อลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่ใบรายการซ่อม' name='rpodate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      {/* <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ออกเอกสารโดย" name="created_name" >
          <Input placeholder="ใส่ชื่อผู้ออกเอกสาร" />
        </Form.Item>
      </Col> */}
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบเสนอราคา" name="pocode" >
          <Input placeholder="ใส่เลขใบเสนอราคา" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="สถานะ" name="status" >
          <SelectRepairOrderStatus />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [rpodate_form, rpodate_to] = criteria?.rpodate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  

    const newPram = {...criteria, rpodate_form, rpodate_to};
    const parm = { criteria:newPram, tbparams };
    
    apirequest.search(parm, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleRowSelect = (record, key) => {  
    setViewOnly( record?.approved_status !== 'N' && !!key );
    setSubmitStock( !!Number(record?.stock_submit || 0 ) && record?.active_status === 'Y' );
    setSelected( !!key ? record : undefined );
  }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, record) => { 
    const config = { mode:"update", acname: `แก้ไข #${record?.rpocode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = (code, record) => { 
    const config = { mode:"view", acname: `ใบรายการซ่อม #${record?.rpocode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  // const handlePrintData = (code, record) => { 
  //   // const newWindow = window.open('', '_blank');
  //   // newWindow.location.href = `/repair-order-print/${code}`;
  //   // const url = `/repair-order-print/${code}/1`;
  //   const url = `/repair-order-print/${code}`;
  //   // const newWindow = window.open('', url, url );
  //   const newWindow = window.open(url, '_blank', 'width=960,height=780' ); 

  //   newWindow.location.href = url;
  // }
  
  
  const handleCancel = async (v) => {
    try { 
      // const result = await confirm.confirm({content: 'คุณต้องการยกเลิกรายการหรือไม่'});
      // if(!!result){  
        const { rpocode: code } = selected;
        await apirequest.deleted(v, code);
        handleSearch();
        message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");

        setSelected( {} );
      // }
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    } 
    
  }
  // const handlePrintReceipt = (code, record) => { 
  //   const newWindow = window.open('', '_blank');
  //   newWindow.location.href = `/receipt-print/${code}`;
  //   // const url = `/repair-order-print/${code}/1`;
  //   // const newWindow = window.open('', url, url);
  //   // newWindow.location.href = url;
  // }

  const customButton = (code, record, istyle, bstyle ) => { 
    const isDisabledCancel = (Number(record?.stock_submit) === 1 || Number(record?.stock_vat_submit === 1));
    // !["ชำระเงินไม่ครบ","ชำระเงินครบแล้ว"].includes( selected?.status ) || 
    return (<>
      <Popover
        placement="topRight" 
        title={ isDisabledCancel && "ข้อความแนะนำ" }
        content={ isDisabledCancel && "ไม่สามารถลบได้เนื่องจากมีการตัดสต๊อกจริง หรือสต๊อกVAT แล้ว" }
        arrow={{pointAtCenter: true}} 
        trigger="hover"
      >
        <div>
          <CancelButton 
            value={handleCancel} 
            text='ลบ' 
            disabled={ isDisabledCancel }
            buttonProp={{ className:'bn-center bn-danger-outline', style: {...bstyle, width:102} }} 
          />  
        </div>
      </Popover> 
    </>) 
  }

  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
 
    const { order, field } = tbparamsDefault;
    const cols = accessColumn();
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  return (<>
  <QctPageLayout title={"ใบรายการซ่อม"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการข้อมูลใบรายการซ่อม'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        onSelectedRow={handleRowSelect}
        onCreate={handleCreatData}
        onUpdate={ !submitStock && !!selected && handleUpdateData}
        onReView={ viewOnly && !!selected && handleReviewData}
        // onPrints={ viewOnly && !!selected && handlePrintData}
        onCustom={ !!selected && customButton }
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"rpocode",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access