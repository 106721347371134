import { Typography } from 'antd';
import React from 'react';
import MyDrawerAdjustStockNormal from './MyDrawerAdjustStockNormal.jsx';
import MyDrawerAdjustStockTires from './MyDrawerAdjustStockTires.jsx';
// import { comma } from '../../../utils/util.js';

import { Authenticate } from '../../../service/Authenticate.service.js';
import { TbPencil } from 'react-icons/tb';

const athn = Authenticate();
const userType = athn.getType(); 
export default function MyDrawerButton({ value, source = [], record = {}, code=null }) {
    const [show, setShow] = React.useState( false );
    const [dataList, setDataList] = React.useState([]);
    const handleClick = () => {
        // console.log( 111  )
        setShow(true);
    }
    
    const hancleConfirmAdjust = (v) => { 
        if( typeof value === 'function'){
            value( v ); 
        }
    }

    // React.useEffect( () => {
    //     const init = () => {
    //         setDataList( source );
    //     }

    //     init();
    // }, [source]);

    React.useEffect( () =>  {
        if( source?.length > 0){
            setDataList( source );     
            // console.log( source, "MyDrawerButton" );
        }
    }, [source])

    return (
        <> 
            { 
                (["admin", "ผู้จัดการสาขา"].includes(userType?.toLowerCase())) && 
                <Typography.Link 
                    className="flex items-center hover:underline"
                    onClick={handleClick}
                >ปรับปรุง<TbPencil /></Typography.Link>
            } 

            { !!show && Number(record?.cat_stock_by_product || 0 ) === 0  && 
                <MyDrawerAdjustStockNormal 
                    show={show} 
                    close={( res )=> {
                        hancleConfirmAdjust( res );
                        setShow(false);
                    }}
                    keySelect={code}
                    source = {dataList}
                    record = {record}
                    title={`รายการสต๊อก #${code}${!!record.stname ? ` - ${record.stname}`  : ''}`}
                /> }
            { !!show && Number(record?.cat_stock_by_product || 0 ) === 1  && 
                <MyDrawerAdjustStockTires 
                    show={show} 
                    close={( res )=> {
                        hancleConfirmAdjust( res );
                        setShow(false);
                    }}
                    keySelect={code}
                    source = {dataList}
                    record = {record}
                    title={`รายการสต๊อก #${code}${!!record.stname ? ` - ${record.stname}`  : ''}`}
                /> }
        </>
    )
}
