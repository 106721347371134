import dayjs from "dayjs";
import { comma } from '../../../utils/util'; 
const calPaymentTotal = (rec) => {
  const total = Number( rec?.payment_amount || 0 ) + Number( rec?.payment_discount || 0 );

  return total;
}

export const columnPayment = () => [
  {
    title: "เลขที่",
    dataIndex: "payment_code",
    key: "payment_code",
    width: '8%', 
  },
  {
    title: "วันที่ชำระ",
    dataIndex: "payment_date",
    key: "payment_date", 
    width: '12%',
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "รูปแบบการชำระ",
    dataIndex: "payment_cond",
    key: "payment_cond",
  }, 
  {
    title: "ยอดที่ชำระ",
    dataIndex: "payment_amount",
    key: "payment_amount",
    className: '!pe-4',
    align: 'right',
    width: '15%',
    render: (v) => comma(Number( v || 0 ), 2, 2)
  }, 
  {
    title: <>ส่วนลด/<br />หักค่าใช้จ่าย</>,
    dataIndex: "payment_discount",
    key: "price_discount",
    className: '!pe-4',
    align: 'right',
    width: '15%',
    render: (v) => comma(Number( v || 0 ), 2, 2)
  }, 
  {
    title: "ยอดรวม",
    dataIndex: "payment_totals",
    key: "payment_totals",
    className: '!pe-4',
    align: 'right',
    width: '15%',
    render: (_, rec) => comma(calPaymentTotal(rec), 2, 2)
  }, 
]