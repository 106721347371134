import React from 'react'
import OptionService from '../../../service/Options.service';
import { AutoComplete } from 'antd';
import { filterComplete } from '../../../utils/util';


const optrequest = OptionService();
export default function MyAutoCompleteTechnician({onChange, value = null}) {
    const [optData, setOptData] = React.useState([]);

    const handleChange = (e, res) => {
      if( typeof onChange === "function") {
        onChange(e, res);
      }
    }

    React.useEffect( () => {
        const initeil = async () => {
            const [
                optDataRes,
            ] = await Promise.all([
                optrequest.optionsRepairOrder({p:"technicians"}), 
            ]);

            const res = optDataRes.data.data;

            setOptData( res ); 
        }

        initeil(); 
        return () => {};
    }, []);
  return (<>
    <AutoComplete
        value={value}
        style={{ height:40, width:'100%' }}
        options={optData}
        filterOption={filterComplete}
        placeholder='กรอกชื่อช่างที่ดูแล'
        onChange={handleChange}
        allowClear
    >
    </AutoComplete> 
  </>)
}
