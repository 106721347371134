import { requestService as api, getParmeter } from "./Request.service"  
const API_URL = {
  OPTION_Model: `/common/options-model.php`, 
  OPTION_Customer: `/common/options-customer.php`,
  OPTION_Business: `/common/options-business.php`,
  OPTION_Itemstype: `/common/options-itemtype.php`,
  OPTION_Itemstypestcode: `/common/options-itemtype-stcode.php`,
  
  OPTION_Itemcategory: `/common/options-itemcategory.php`,
  
  OPTION_SUPPLIER: `/common/options-supplier.php`,
  OPTION_PURCHASEORDER: `/common/options-purchase-order.php`,
  OPTION_LOCATION: `/common/options-location.php`,
  OPTION_QUOTATION: `/common/options-quotation.php`,
  OPTION_ITEMS: `/common/options-items.php`,
  OPTION_BILLINGNOTE: `/common/options-billing-note.php`,
  OPTION_EMPLOYEE: `/common/options-employee.php`,
  OPTION_USER: `/common/options-user.php`,

  OPTION_BILLINGPAYMENT: `/common/options-billing-payment.php`,

  OPTION_CARS: `/common/options-cars.php`,

  OPTION_STOCK: `/common/options-stock.php`,

  OPTION_PAYMENTMETHODS: `/common/options-payment-methods.php`,

  OPTION_REPAIRORDER: `/common/options-repair-order.php`,

  OPTION_TAXINVOICE: `/common/options-tax-invoice.php`,

  OPTION_BANKS: `/common/options-banks.php`,
  OPTION_SALE: `/common/options-sale.php`,
  OPTION_TECHNICIAN: `/common/options-technician.php`,
};
 

const OptionService = () => {
  const optionsModel = (parm = {}) => api.get(`${API_URL.OPTION_Model}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsCustomer = (parm = {}) => api.get(`${API_URL.OPTION_Customer}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsBusiness = (parm = {}) => api.get(`${API_URL.OPTION_Business}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsItemstype = (parm = {}) => api.get(`${API_URL.OPTION_Itemstype}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsItemstypeandStcode = (parm = {}) => api.get(`${API_URL.OPTION_Itemstypestcode}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsItemscategory = (parm = {}) => api.get(`${API_URL.OPTION_Itemcategory}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsEmployee = () => api.get(`${API_URL.OPTION_EMPLOYEE}`, { ignoreLoading : true });
  const optionsUser = () => api.get(`${API_URL.OPTION_USER}`, { ignoreLoading : true });  
  const optionsSale = () => api.get(`${API_URL.OPTION_SALE}`, { ignoreLoading : true });
  const optionsTechnician = () => api.get(`${API_URL.OPTION_TECHNICIAN}`, { ignoreLoading : true });  

  //supplier
  const optionsSupplierTag = (parm = {}) => api.get(`${API_URL.OPTION_SUPPLIER}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsSupplierPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_SUPPLIER, criteria, {...config, cancle: true});
  
  //purchase order
  const optionsPurchaseOrder = (parm = {}) => api.get(`${API_URL.OPTION_PURCHASEORDER}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsPurchaseOrderPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_PURCHASEORDER, criteria, {...config, cancle: true});
  
  //location
  const optionsLocation = (parm = {}) => api.get(`${API_URL.OPTION_LOCATION}?${getParmeter(parm)}`, { ignoreLoading : true });

  //quotation
  const optionsQuotation = (parm = {}) => api.get(`${API_URL.OPTION_QUOTATION}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsQuotationPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_QUOTATION, criteria, {...config, cancle: true});

  //stock
  const optionsStock = (parm = {}) => api.get(`${API_URL.OPTION_STOCK}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsStockPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_STOCK, criteria, {...config, cancle: true});
  const optionsStockPut = (criteria = {}, config = {}) => api.put(API_URL.OPTION_STOCK, criteria, {...config, cancle: true});


  //items
  const optionsItems = (parm = {}) => api.get(`${API_URL.OPTION_ITEMS}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsItemsPut = (payload = {}, config = {}) => api.put(API_URL.OPTION_ITEMS, payload, {...config, cancle: true});

  //billing note
  const optionsBillingNote = (parm = {}) => api.get(`${API_URL.OPTION_BILLINGNOTE}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsBillingNotePost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_BILLINGNOTE, criteria, {...config, cancle: true});

  //cars
  const optionsCars = (parm = {}) => api.get(`${API_URL.OPTION_CARS}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsCarsPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_CARS, criteria, {...config, cancle: true});
  const optionsCarsPut = (criteria = {}, config = {}) => api.put(API_URL.OPTION_CARS, criteria, {...config, cancle: true});

  //payment-methods
  const optionsPaymentMethods = (parm = {}) => api.get(`${API_URL.OPTION_PAYMENTMETHODS}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsPaymentMethodsPost = (criteria = {}, config = {}) => api.post(API_URL.OPTION_PAYMENTMETHODS, criteria, {...config, cancle: true});
  const optionsPaymentMethodsPut = (criteria = {}, config = {}) => api.put(API_URL.OPTION_PAYMENTMETHODS, criteria, {...config, cancle: true});
  const optionsPaymentMethodsDelete = (parm = {}, config = {}) => api.delete(`${API_URL.OPTION_PAYMENTMETHODS}?${getParmeter(parm)}`, {...config, cancle: true});

  //reapir-order
  const optionsRepairOrder = (parm = {}) => api.get(`${API_URL.OPTION_REPAIRORDER}?${getParmeter(parm)}`, { ignoreLoading : true });

  //banks
  const optionsBanks = (parm = {}) => api.get(`${API_URL.OPTION_BANKS}?${getParmeter(parm)}`, { ignoreLoading : true });

  //billing payment
  const optionsBillingPayment = (parm = {}) => api.get(`${API_URL.OPTION_BILLINGPAYMENT}?${getParmeter(parm)}`, { ignoreLoading : true });

  //tax invoice
  const optionsTaxInvoice = (parm = {}, config = {}) => api.get(`${API_URL.OPTION_TAXINVOICE}?${getParmeter(parm)}`, { ignoreLoading : true, ...config });


  return {
    optionsModel,
    optionsCustomer,
    optionsBusiness,
    optionsItemstype,
    optionsItemstypeandStcode,
    optionsItemscategory,
    optionsEmployee,
    optionsUser,

    optionsSupplierTag,
    optionsSupplierPost,
    optionsPurchaseOrder,
    optionsPurchaseOrderPost,

    optionsLocation,

    optionsQuotation,
    optionsQuotationPost,

    optionsStock,
    optionsStockPost,
    optionsStockPut,

    optionsItems,
    optionsItemsPut,

    optionsBillingNote,
    optionsBillingNotePost,

    optionsCars,
    optionsCarsPost,
    optionsCarsPut,

    optionsPaymentMethods,
    optionsPaymentMethodsPost,
    optionsPaymentMethodsPut,
    optionsPaymentMethodsDelete,

    optionsRepairOrder,

    optionsBanks,

    optionsBillingPayment,
    optionsSale,
    optionsTechnician,

    optionsTaxInvoice,
  };
};

export default OptionService;