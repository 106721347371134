/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Flex, Form } from 'antd';
import { Input, InputNumber, message, Radio, Row, Spin, Table, Typography } from 'antd';
import React from 'react'; 
import useConfirm from '../../../store/hook/use-confirm.hook';
import { TableSearchValue } from '../../form';
import { formDefault, listHeader } from './model.moule';
import { CloseOutlined } from '@ant-design/icons';
import { TbReceiptDollar } from 'react-icons/tb';

import "./MyDrawer.css";
import { comma } from '../../../utils/util';
import { PaymentMethodsInput } from '../../modal/payment-methods';
import dayjs from "dayjs"; 
import BillingPaymentService from "../../../service/BillingPayment.service.js"; 
import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { SelectBanks } from '../../select/index.js';

const emptyText = { emptyText:<span>ไม่มีข้อมูลรายการ</span> };
const dividerProp = { orientation:"left", style:{marginBlock:10}, className:'!border-black' }; 
const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' };  

const payrequest = BillingPaymentService();
export default function MyDrawer({headList, show, value, close, getContainer=null}) {
    const confirms = useConfirm();
    const [form] = Form.useForm();

    const context = useTbSearchContext();

    const [openDrawer, setOpenDrawer] = React.useState(show); 
    const [dataHeader, setDataHeader] = React.useState({}); 
    const [methodSelect, setMethodSelect] = React.useState({}); 

    const [installment, setInstallment] = React.useState(0);
    const [bank, setBank] = React.useState(undefined);

    const installmentMonthRef = React.useRef(null);

    const [loading, setLoading] = React.useState(false); 

    const [drawerWidth, setDrawerWidth] = React.useState(75);
    const container = () => document.getElementById("billing-payment");
    const containerStyle = {
      position: 'relative',
    //   overflow: 'hidden',
      height: '100%'
    };



    const headStyle = {
        borderBottom: `2px solid var(--success)`,
        backgroundColor: `var(--success)`, 
    }

    const bodyStyle = {
        border: `2px solid var(--success)`
    }

    const footStyle = {
        borderTop: `2px solid var(--success)`, 
    } 

    const handleCancel = () => {
        setOpenDrawer(false);
    }

    const handleConfirm = async () => {
        try {
            let result = false;
            const f = await form.validateFields();

            const {thai_name:bank_name, keyName} = bank || {};
            
            const {payment_type} = methodSelect;

            const { payment_totals, paid_amount:payment_amount, payment_discount } = dataHeader;

            // console.log( { payment_totals, payment_amount, payment_discount } );
            
            if( payment_amount < 1 ){
                await confirms.error("ยอดชำระต้องมากกว่า 0", { getContainer:container });
                return;
            }

            if( ( payment_amount + payment_discount ) < payment_totals ){
                result = await confirms.warninged({content:"ยอดชำระไม่เพียงพอ ต้องการทำรายการต่อหรือไม่?", getContainer:container});

                if(!result) return;
            }

            result = !result ? await confirms.confirm({title: "ยืนยันการชำระ", getContainer:container}) : result; 
            if( !result ) return;

            const resData = {
                ...formDefault,
                ...dataHeader,
                ...f,
                payment_amount,
                payment_remain: payment_totals - payment_amount - payment_discount,
                payment_date: dayjs(f?.payment_date).format("YYYY-MM-DD"),
                check_date: !!f?.check_date ? dayjs(f?.check_date).format("YYYY-MM-DD") : null,
                [keyName || "none"]:bank_name
            };

            if( payment_type === "บัตรเครดิต"){
                resData.credit_card_installment = Number( installmentMonthRef.current.value || 0 );
            }

            await handleSubmitData(resData).then( res => {
                if( typeof value === 'function'){
                    value( {...resData});
                    context?.clearSelect();
                    handleCancel();
                }
                handleCancel(); 
            }).catch( e => {
                console.log( e );
                message.error(handleSetMessage(e?.message));
            }); 
        } catch ( e ) {
            console.log( e );
            message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        }

    }

    const handleSubmitData = async (res) => {
        try {
            setLoading(true);
            const { payment_totals, payment_amount, payment_discount } = res;
            const isnotyet = payment_totals > (payment_amount + payment_discount);
            const source = headList.sort((a, b) => Number( b?.price_total || 0 ) -  Number( a?.price_total || 0 ));
    
            let paid = (payment_amount + payment_discount);
            const newList = source?.map( (m, seq) => {
                const { price_total, payment_amount:paid_total, payment_remain:remain_total } = m;
                const p = isnotyet ? Math.ceil((price_total / payment_totals) * (payment_amount + payment_discount) / 10) * 10 : price_total;
                let paid_amount = isnotyet && p <= paid ? p : paid;
                
                const payment_remain = price_total - (Number(m?.payment_amount || 0 ) + paid_amount);
                
                paid_amount = payment_remain < 0 ? Number( remain_total || 0 ) : paid_amount;
                const d = {
                    payment_amount: payment_remain < 0 ? price_total : Number(m?.payment_amount || 0 ) + paid_amount,
                    payment_remain: payment_remain < 0 ? 0 : payment_remain,
                    rpocode: m.rpocode,
                    price_total,
                    paid_total,
                    payment: paid_amount,
                    remain: price_total - paid_amount,
                    seq: seq + 1,
                } 
                paid = (paid - paid_amount) < 0 ? 0 : paid - paid_amount;
        
                return {...m, ...d}
            });

            // const head = { }
            // console.log( {head: res, list: newList} );
        
            await payrequest.create( {head: res, list: newList}, { ignoreLoading:true } ); 
        } catch ( e ) {
            console.log( e );
            throw new Error( e );
        } finally {
            setLoading(false);
        }

    }

    const handleFormValueChange = async (value, record) => {
        const f = {...dataHeader};
        const {paid_amount, cash_discount, other, transfer_fee, withholding } = record;
        const {payment_totals} = f;
        const payment_discount = Number( transfer_fee || 0 ) + Number( cash_discount || 0 ) + Number( withholding || 0 ) + Number( other || 0 );
        
        const payment_remain = Number( payment_totals || 0 ) - Number( paid_amount || 0 ) - payment_discount;
        
        const newData = {
            ...f,
            ...record,
            payment_discount,
            // payment_remain,
            transfer_fee,
            cash_discount,
            withholding,
            other,
        }
        // console.log( {  value, record, dataHeader });

        setDataHeader( newData );
        form.setFieldsValue( { 
            ...newData,  
            payment_remain_txt:comma(payment_remain, 2, 2),
            payment_discount_txt:comma(payment_discount, 2, 2),
        } );
        // console.log( {newData});
    }

    const handlePaymentMethod = (v) => {
        setMethodSelect(v);
        const { payment_name } = v;
        setDataHeader( s => ({...s, payment_cond: payment_name }));
        form.setFieldValue("payment_cond", payment_name);

        // credit card
        form.setFieldValue("credit_card_bank", null);
        form.setFieldValue("credit_card_payment", null);
    
        // transfer
        form.setFieldValue("transfer_bank", null);
        form.setFieldValue("transfer_bank_branch", null);
        form.setFieldValue("transfer_refnumber", null);
        
        // check
        form.setFieldValue("check_bank", null);
        form.setFieldValue("check_bank_branch", null);
        form.setFieldValue("check_number", null);
        form.setFieldValue("check_date", null);
        form.setFieldValue("check_result", null); 
        setBank(undefined);

        //set defalut value
        switch(v?.payment_type || ""){
            case "บัตรเครดิต":
                form.setFieldValue("credit_card_payment", "ชำระเต็มจำนวน");
            break;
            case "เช็ค":
                form.setFieldValue("check_result", true);
            break;
            default : //
        }
        
    }

    const handleSetMessage = (content, option={}) => ({content: content, getContainer: container, ...option})

    const handleConfigMessageNoti = () => {
        message.config({
          top: 8,
          prefixCls: 'my-message',
          getContainer: container
        });
    }

    React.useEffect( () => { 
        const payment_amount = headList?.reduce(( acc, v) => acc += Number( v?.payment_amount || 0), 0);
        const payment_totals = headList?.reduce(( acc, v) => acc += Number( v?.price_total || 0), 0) - payment_amount;
        const payment_remain = payment_totals;
        const price_total = headList?.reduce(( acc, v) => acc += Number( v?.price_total || 0), 0);
        const initData = { payment_totals, payment_amount, payment_remain, payment_discount: 0, paid_amount:payment_remain, price_total };
        setDataHeader( {...initData} );
        form.setFieldsValue( {
            ...initData, 
            payment_date: dayjs(),
            payment_totals_txt:comma(Number( initData?.payment_totals || 0), 2, 2),
            payment_remain_txt:comma(Number( initData?.payment_remain || 0), 2, 2),
            payment_discount_txt:comma(Number( initData?.payment_discount || 0), 2, 2),
        } );
    }, [headList, form]);
    
    // Update width on window resize
    React.useEffect(() => {
        const updateDrawerWidth = () => {
            if (window.innerWidth < 600) {
                setDrawerWidth(100);
            } else if (window.innerWidth < 1024) {
                setDrawerWidth(75);
            } else {
                setDrawerWidth(60);
            }
        };
        updateDrawerWidth();
        window.addEventListener('resize', updateDrawerWidth);

        handleConfigMessageNoti();
        return () =>  {
            window.removeEventListener('resize', updateDrawerWidth);
            message?.destroy();
        }
    }, []);

    const method_check = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="ธนาคาร" name="check_bank" rules={[formRole]}>
                    <SelectBanks onChange={(_, rec) => setBank({...rec?.record, keyName:"check_bank_name"})} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="สาขาธนาคาร" name="check_bank_branch" >
                    <Input placeholder='กำหนดวันที่ใบวางบิล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <Form.Item label="เลขที่เช็ค" name="check_number" >
                    <Input placeholder='กำหนดวันที่ใบวางบิล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <Form.Item label="วันที่ในเช็ค" name="check_date" >
                    <DatePicker style={{ width: "100%", height:40 }} format="DD/MM/YYYY" placeholder='กำหนดวันที่ใบวางบิล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="สถานนะเช็ค" name="check_result" valuePropName="checked">
                    <Checkbox style={{height:40}} className='items-center'>ผ่าน</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    </>)

    const method_credit_card = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="ธนาคาร" name="credit_card_bank" rules={[formRole]}>
                    <SelectBanks onChange={(_, rec) =>  setBank({...rec?.record, keyName:"credit_card_bank_name"})} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="การชำระ" name="credit_card_payment" >
                    <Radio.Group  onChange={(e) => setInstallment(e.target.value)} className='gap-3 flex-wrap items-center' style={{height:40}}>
                        <Radio value="ชำระเต็มจำนวน" >เต็มจำนวน</Radio>
                        <Radio value="ผ่อนชำระ" className='flex-1'>
                            <dev className='flex items-center w-full'>
                                <span className='text-nowrap'>ผ่อนชำระ</span>
                                <InputNumber 
                                    ref={installmentMonthRef} 
                                    style={{marginInlineStart: 10, height:'100%', width: '100%' }} 
                                    min={0} 
                                    controls={false} suffix="เดือน" disabled={installment !== "ผ่อนชำระ"} 
                                />
                            </dev>
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    </>)

    const method_transfer = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item label="ธนาคาร" name="transfer_bank" rules={[formRole]}>
                    <SelectBanks onChange={(_, rec) => setBank({...rec?.record, keyName:"transfer_bank_name"})} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <Form.Item label="สาขาธนาคาร" name="transfer_bank_branch" >
                    <Input placeholder='กำหนดสาขาธนาคาร' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <Form.Item label="เลขอ้างอิง" name="transfer_refnumber" >
                    <Input placeholder='กำหนดเลขอ้างอิง' />
                </Form.Item>
            </Col>
        </Row>
    </>);

    const totalValue = (_) => {  
        return(<>
            <Table.Summary.Row> 
                <Table.Summary.Cell colSpan={4} align='start' className='!pe-4 align-top !text-center' >รวมเงินทั้งสิ้น</Table.Summary.Cell>
                <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(dataHeader?.price_total || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(dataHeader?.payment_amount || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(dataHeader?.payment_remain || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>
        </>);
    };

    const field_infomation = (<>
        <Card style={{ backgroundColor: '#f3f4f6'}} id="billing-payment-head-list">
            <TableSearchValue
                showtitle={false}
                multi={true}  
                notSelect={true}
                tbProps={{
                    rowKey:"rpocode",
                    dataSource: headList || [],
                    columns:listHeader(),
                    pagination: false,
                    locale:{...emptyText},
                    summary:totalValue,
                }}
            /> 
        </Card>
    </>);

    const field_payment_method = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="รูปแบบการชำระ" name="payment_cond" rules={[formRole]}>
                <PaymentMethodsInput 
                    className="!text-black" 
                    selected={handlePaymentMethod} 
                    value={ dataHeader?.payment_cond || null }
                    onClose={(v)=> {
                       handleConfigMessageNoti();
                    }}
                />
              </Form.Item>
            </Col> 
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="วันที่ชำระ" name="payment_date" rules={[formRole]}>
                    <DatePicker style={{ width: "100%", height:40 }} format="DD/MM/YYYY" placeholder='กำหนดวันที่ชำระ' />
                </Form.Item>
            </Col>
        </Row>
        { methodSelect?.payment_type === "เช็ค" && method_check  }
        { methodSelect?.payment_type === "บัตรเครดิต" && method_credit_card  }
        { methodSelect?.payment_type === "โอน" && method_transfer  }
          
    
    </>)

    const fields_payment = (<> 

        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'> 
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='ยอดที่ต้องชำระทั้งหมด' name='payment_totals_txt'>
                    <Input className='input-40 !text-black' disabled />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='ยอดชำระ' name='paid_amount'>
                    <InputNumber className='w-full input-40' controls={false} placeholder='กรอก ยอดชำระ' min={0} onFocus={(e)=>e.target.select()} />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='ส่วนลด/หักค่าใช้จ่าย' name='payment_discount_txt'>
                    <Input className='input-40 !text-black' disabled />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='คงเหลือ' name='payment_remain_txt'>
                    <Input className='input-40 !text-black' disabled />
                </Form.Item>                     
            </Col>
        </Row>    
    </>);

    const fields_discount = (<> 
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'> 
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='ค่าธรรมเนียมการโอน' name='transfer_fee'>
                    <InputNumber style={{ width: "100%" }} className='input-40' controls={false} placeholder='กรอก ค่าธรรมเนียมการโอน' min={0} />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='ส่วนลดเงินสด' name='cash_discount'>
                    <InputNumber style={{ width: "100%" }} className='input-40' controls={false} placeholder='กรอก ส่วนลดเงินสด' min={0} />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='หัก ณ ที่จ่าย' name='withholding'>
                    <InputNumber style={{ width: "100%" }} className='input-40' controls={false} placeholder='กรอก หัก ณ ที่จ่าย' min={0} />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={12} lg={6}> 
                <Form.Item label='อื่นๆ' name='other'>
                    <InputNumber style={{ width: "100%" }} className='input-40' controls={false} placeholder='กรอก อื่นๆ' min={0} />
                </Form.Item>                     
            </Col>
            <Col xs={24} sm={24}> 
                <Form.Item label='หมายเหตุ' name='remark'>
                    <Input.TextArea rows={2} ></Input.TextArea>
                </Form.Item>
            </Col>
        </Row>
    </>);

    const footer = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    <Button className='bn-center bn-secondary-outline min-w-24' onClick={() => handleCancel()} >ปิด</Button>
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button className={`bn-center bn-success-outline min-w-24`} onClick={()=>handleConfirm()} >ยืนยันการชำระ</Button>
                </Flex>
            </Col>
        </Row>
    </>);


    return (
        <>
            <Drawer
                title={<Flex  gap={2}>
                    <Typography.Text className='text-white m-0'>การชำระเงิน</Typography.Text>
                    <TbReceiptDollar className='text-white m-0' style={{fontSize:'1.4rem'}}/>
                </Flex>}
                closeIcon={<CloseOutlined className='text-white' />}
                onClose={()=>handleCancel()}
                open={openDrawer}
                key="billing-payment"
                id="billing-payment"
                footer={footer}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                width={`${drawerWidth}%`}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( false );  
                    }
                }}
                maskClosable={false}
                getContainer={() => (!!getContainer && typeof getContainer === "function") ? getContainer() : false}
                push={false}
            >
                <div id="billing-payment-main" style={containerStyle}> 
                    <Spin spinning={loading}>
                        <Form form={form}  layout="vertical" autoComplete="off" onValuesChange={handleFormValueChange} > 
                            <Divider {...dividerProp}>ข้อมูลรายการ</Divider>
                            {field_infomation}

                            <Divider {...dividerProp}>ช่องทางการชำระ</Divider>
                            {field_payment_method}                    

                            <Divider {...dividerProp}>ข้อมูลการชำระ</Divider>
                            {fields_payment}                    

                            <Divider {...dividerProp}>ข้อมูลส่วนลด/หักค่าใช้จ่าย</Divider>
                            {fields_discount} 
                        </Form>                         
                    </Spin>
                </div>
            </Drawer>
        </>
    )
}
