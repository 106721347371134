import { Badge } from "antd";
import { StockInfoButton } from "../../components/drawer/stock-info";
import { TagStockStatus } from "../../components/badge-and-tag";
import { comma } from "../../utils/util";

export const accessColumn = () => [
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    hide: false,
    sorter: true,
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname",
  },
  {
    title: "ราคาขายปลีก",
    dataIndex: "price",
    key: "price",
    hide: false,
    width: 140,
    align: 'right',
    className: '!pe-5',
    sorter: true,
    onCell: (v) => ({
      className:'text-end !pe-5'
    }),
    render:(v) => !!v ? comma( Number( v )) : "-"
  }, 
  {
    title: "Promotion",
    dataIndex: "price_A",
    key: "price_A",
    hide: false,
    width: 140,
    align: 'right',
    className: '!pe-5',
    sorter: true,
    onCell: (v) => ({
      className:'text-end !pe-5'
    }),
    render:(v) => !!v ? comma( Number( v )) : "-"
  }, 
  {
    title: "ประเภทสินค้า",
    dataIndex: "typename",
    key: "typename",
    hide: false,
  },
  {
    title: "สต๊อก",
    dataIndex: "stock_qty",
    key: "stock_qty",
    hide: false,
    width: 140,
    align: 'right',
    className: '!pe-5',
    sorter: true,
    onCell: (v) => ({
      className:'text-end !pe-5'
    }),
    render:(v, record) => <StockInfoButton result={v} record={record} code={record?.stcode} />
  },
  {
    title: "สต๊อก VAT",
    dataIndex: "stock_vat_qty",
    key: "stock_vat_qty",
    hide: false,
    sorter: true,
    align: 'right',
    className: '!pe-5',
    width: 140,
    onCell: (v) => ({
      className:'text-end !pe-5'
    }),
    render:(v, record) => <TagStockStatus result={v} />
  },
  // {
  //   title: "หมวดหมู่สินค้า",
  //   dataIndex: "categoryname",
  //   key: "categoryname",
  //   hide: false,
  // },
  // {
  //   title: "ยี่ห้อรถ",
  //   dataIndex: "brand",
  //   key: "brand",
  //   hide: true,
  // },
  {
    title: "ยี่ห้อรถ",
    dataIndex: "stcar_brand",
    key: "stcar_brand",
    hide: true,
  },
  {
    title: "รุ่นรถ",
    dataIndex: "stcar_model",
    key: "stcar_model",
    hide: true,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    hide: true,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้งาน" />
        )}
      </div>
    ),
  },
];

export const columnsdetail = [
  {
    title: "ปีผลิต",
    dataIndex: "proyear",
    key: "proyear",
  },
  {
    title: "ไตรมาส",
    dataIndex: "quarter",
    key: "quarter",
  },
  {
    title: "สถานที่เก็บ",
    dataIndex: "location",
    key: "location",
  },
].filter((item) => !item.hidden);

export const formData = {
  stcode: null,
  stname: null,
  typecode: null,
  unit: null,
  material_code: null,
  count_stock: null,
  stname_vat: null,
  brand: null,
  stname_per: null,
  stfront: null,
  stseries: null,
  stborder: null,
  stload: null,
  stspeed: null,
  sttw: null,
  stweight: null,
  stwidth: null,
  stlong: null,
  sthigh: null,
  stchange_round: null,
  stchange_time: null,
  stcar_brand: null,
  stcar_model: null,
  remark: null,
  min: null,
  price: null,
  price_A: null,
  price_B: null,
  price_online: null,
  stock_by_product: null,
  active_status: null,
};

export const DEFALUT_CHECK_STEP_FORM = {  
  typecode : null,
  typename : null,
  categorycode : null,
  categoryname : null,
}
