/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext, 
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'; 
import { ComponentDrag } from '../../table/TableEditable.js';

import { Modal, Card, Form, Typography, Flex, Button, Drawer, Tooltip } from "antd";
import { Row, Col, Space } from "antd";
import { Input } from "antd";
import { TbSearch, TbClipboardList, TbCheck } from "react-icons/tb"; 
import { TableSearchValue } from '../../form/index.js';
import OptionService from '../../../service/Options.service.js'; 
// import { v4 as uuidv4 } from 'uuid';

// import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { myModalItemsColumn } from "./model.module.js"; 
import { SelectItemTypeMulti } from '../../select/index.js';

const optrequest = OptionService(); 
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
export default function MyModalItems({show, close, values, selected=[], tags=[]}) {
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    };

    // const context = useTbSearchContext();
    const [form] = Form.useForm();
    const [loading,  setLoading] = React.useState(true); 

    const [openModal, setOpenModal] = React.useState(show);
    const [openViewSelected, setOpenViewSelected] = React.useState(false);
    const [isViewOpened, setIsViewOpened] = React.useState(false);

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    // const [optionsItemsType, setOptionsItemsType] = React.useState([]);

    const [dataSource, setDataSource] = React.useState([]);

    const [dataSelect, setDataSelect] = React.useState([...selected]);

    const [inited, setInited] = React.useState(false);
    const keySelected = React.useMemo(() => {
      // ตัวอย่างการคำนวณหาค่าเฉลี่ยของ list
    //   console.log('Calculating average...');
      const keys = selected.map( key  => key?.stcode );
      return keys;
    }, [selected]); 

    const onDragEnd = ({ active, over }) => {
        // console.log( active, over  );
        if (active.id !== over?.id) {
            setDataSelect((previous) => {
                const activeIndex = previous.findIndex((i) => i.stcode === active.id);
                const overIndex = previous.findIndex((i) => i.stcode === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    }; 

    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
            distance: 1,
          },
        }),
    );

    const handleClose = () =>{ 
        setOpenModal(false);

        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (_, criteria) => { 
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = (record, key) => {

        const newData = [
            ...record.map( im => ({
                ...im, 
                purdetail: im.material_code || im.stname,
                count_stock: Number(im?.cat_count_stock),
                qty: Number(im?.cat_count_stock) === 0 ? 1 : 0,
                amount: Number(im?.cat_count_stock) === 0 ? 1 : 0,
            })),
            ...selected,            
        ]
        // console.log( newData );
        setDataSelect(newData); 
        // handleClose();
    }

    const handleChoosed = () => {
        typeof values === "function" && values( dataSelect );

        handleClose();
    } 

    const handleOpenViewItems = () => {
        setOpenViewSelected(true);
        setIsViewOpened(true); 
    }

    const handleSearch = React.useCallback( async () => {
        if( !inited )  return;
      const parm = { criteria:{...criteria, keyIgnore: keySelected }, tbparams, p:"items" };
      setLoading(true);
      try { 
        const res = await optrequest.optionsStockPost(parm, { ignoreLoading:true } )
        const { data:{ source, tbparams } } = res.data;
        parm.criteria.items_type?.length === 0 && delete parm.criteria.items_type;  
        setPageValue(tbparams?.pagination);
        // setPaing( state => ( {...state, ...pagination }) );
        setDataSource(source);

        setTimeout( () => setLoading(false), 200 );
      } catch(err) {
        setTimeout( () => setLoading(false), 200 );
      } 
    }, [criteria, tbparams]);

    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);
 

    React.useEffect(() => {
        const initeil = async() => {
            // const [ itemTypeRes ] = await Promise.all([
            //     optrequest.optionsPurchaseOrder({p:"items-type"})
            // ]);
        
            // const {data:itemType} = itemTypeRes.data; 
            // setOptionsItemsType( itemType );

            // const initItemType = itemType.filter( it => tags?.includes( it?.label ) ).map( v => v.value)
            form.setFieldsValue({
                search: null,
                // items_type: initItemType
            });

            
            setInited(true);
            setCriteria( state => ({
                ...state, 
                // items_type:initItemType
            }))
        } 
        initeil();     


        return () => {  setInited(false) };
    }, []);

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbClipboardList style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>รายการสินค้า</Typography.Text>
        </Flex>    
    </>);

    const modalFooter = (<> 
        <Row>
          <Col span={24}>{/* Ignore */}</Col>
          <Col span={24}>
            <Flex justify="flex-end">
              <Button
                className="bn-center bn-primary"
                icon={<TbCheck />}
                onClick={() => handleChoosed()}
              >ยืนยัน</Button>
            </Flex>
          </Col>
        </Row> 
    </>);


    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={10}>
                <Form.Item label="ค้นหา" name='search'  >
                    <Input suffix={<TbSearch />} placeholder='ค้นหาข้อมูล สินค้า'/>
                </Form.Item>                        
            </Col> 
            <Col xs={24} sm={24} lg={14}> 
                <Form.Item label='ประเภทสินค้า' name='items_type'>
                    <SelectItemTypeMulti />
                </Form.Item>                     
            </Col> 
        </Row>    
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={12}>
                <Flex className='justify-start'>
                    <Tooltip placement="topLeft" title={`คลิกที่ข้อความเพื่อดูสินค้าที่เลือกทั้งหมด`} arrow={{pointAtCenter: true}}>
                        <Typography.Link onClick={handleOpenViewItems}>สินค้าที่เลือกแล้ว {dataSelect.length} รายการ.</Typography.Link> 
                    </Tooltip>
                </Flex>
            </Col> 
            <Col xs={24} sm={24} lg={12}> 
                <Flex className='justify-start sm:justify-end'>
                    {/* <Typography.Link>มีสินค้าที่เลือกแล้ว {selected.length} รายการ.</Typography.Link> */}
                </Flex>
            </Col>            
        </Row>
    </>);

    return (<> 
    <div className='modal-suppliers' id="modal-area"> 
        <Modal
            open={openModal}
            title={modalTitle}
            afterClose={() => handleClose() }
            onCancel={() => setOpenModal(false) } 
            maskClosable={false}
            // style={{ top: 10, height: 'calc(100vh - 10px)' }}
            style={{ top: 10 }}
            width={960}
            className='mymodal'
            footer={modalFooter}
            // containerStyle={{ height: 'calc(100vh - ( 10px * 2) )' }}
        >
            <div style={containerStyle}>  
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                            {modalSearch}
                        </Form>
                    </Card>
                    <Card style={{minHeight:490}}>
                        <TableSearchValue 
                            title='รายการข้อมูลสินค้า'
                            multi={true}
                            pagingDefault={tbparamsDefault?.pagination}
                            onPageChange={(page)=>setTbparams(page)}
                            onSelectedRow={handleRowSelect}
                            tbProps={{
                                rowKey:"stcode",
                                dataSource:dataSource,
                                columns:myModalItemsColumn(),
                                pagination: pageValue,
                                loading
                            }}
                        />
                    </Card>
                </Space>      
                {isViewOpened && 
                    <Drawer
                        title={`สินค้าที่เลือกทั้งหมด ${dataSelect.length} รายการ`}
                        onClose={() => { setOpenViewSelected(false); }}
                        open={openViewSelected} 
                        width='100%'
                        className="responsive-drawer" 
                        afterOpenChange={(e)=>{ !e && setIsViewOpened(false); }}
                        getContainer={false}
                    >
                        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext items={keySelected} strategy={verticalListSortingStrategy}>
                                <TableSearchValue 
                                    title='รายการสินค้าที่เลือกแล้ว'
                                    provider={false}
                                    tbProps={{
                                        components:ComponentDrag,
                                        rowKey:"stcode",
                                        dataSource:dataSelect,
                                        columns:myModalItemsColumn().map( m => ({...m,  sorter:false})), 
                                        pagination:{position:['none']},
                                        locale:{...emptyText}
                                    }}
                                /> 
                            </SortableContext>
                        </DndContext>
                    </Drawer>
                } 
            </div>
        </Modal>
    </div>         
    </>)
}