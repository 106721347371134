/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Descriptions, Divider, Popover, Row, Space, Table, Typography } from 'antd';

import { columnViewDetail as columnView, infoItem, cusItem, columnPayment, promotionItem, carItem } from "./model"
import { comma } from '../../utils/util';
import { TableSearchValue } from '../../components/form';
import { v4 as uuidv4 } from 'uuid';
import { BillingPaymentButtonView } from '../../components/drawer/billing-payment';
// import { useNavigate } from 'react-router-dom';
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };

export default function ManageView({initeial, reload}) {
  // const navigate = useNavigate();
  const [headValue, setHeadValue] = React.useState({});
  const [listValue, setListValue] = React.useState([]);
  const [paysValue, setPaysValue] = React.useState([]);

  // const [payRecordSelect, setPayRecordSelect] = React.useState(undefined);
  
  // const handleBillingPayment = (rec) => {

  // }

  // const handlePaymentRecord = (rec) => {
  //   setPayRecordSelect( rec );
  // }
  
  // const handleCustom = ( key, rec, iconStyle, bntStyle ) => {
  //   // console.log( rec );
  //   return (<>
  //     <Popover
  //       placement="topLeft" 
  //       title={"แนะนำการใช้งาน"}
  //       content={!payRecordSelect ? "กรุณาเลือกรายการชำระก่อน" : "" }
  //       arrow={{ pointAtCenter: true }} 
  //       trigger={"hover"}
  //     >
  //         <BillingPaymentButtonView
  //           selects={payRecordSelect} 
  //           disabled={!payRecordSelect} 
  //           value={handleBillingPayment}
  //           close={(v)=>{
  //             if( typeof reload === "function" && !!v) {
  //               setPayRecordSelect(undefined);
  //               reload(v)
  //             } 

  //           }}
  //         />
  //     </Popover> 
  //   </>)
  // }

  const information = (<>
    <Divider {...dividerProp}>ข้อมูลใบรายการซ่อม</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={infoItem(headValue)} />
  </>);

  const customer = (<>
    <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:3, xxl:3}} items={cusItem(headValue)} />
  </>); 

  const car = (<>
    <Divider {...dividerProp}>ข้อรถยนต์ลูกค้า</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4}} items={carItem(headValue)} />
  </>); 

  // const promotion = (<>
  //   <Divider {...dividerProp}>โปรโมชั่น</Divider>
  //   <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={promotionItem(headValue)} />
  // </>); 

  const lists = (<>
      <Divider {...dividerProp}>ข้อมูลสินค้า</Divider>
      <TableSearchValue
          title='รายการสินค้า' 
          notSelect={true}
          tbProps={{ 
              rowKey:"uuid",
              dataSource:listValue,
              columns:columnView(),
              pagination: false,
              locale:{...emptyText},
              summary: (record) => totalPrice
          }}
      />
  </>);

  const payments = (<>
      <Divider {...dividerProp}>ข้อมูลการชำระ</Divider>
      <Table
        // title={ () => <>รายการชำระ</> }
        id="tb-rpo-payment"
        rowKey="uuid"
        size='small'
        dataSource={paysValue}
        columns={columnPayment()}
        pagination={false}
        locale={{...emptyText, emptyText:<span>ไม่มีข้อมูลการชำระเงิน</span>}}
        // summary={(rec) => totalPayment(rec)} 
      />
  </>);

  const totalPrice = (<>
    <Table.Summary.Row>
      <Table.Summary.Cell colSpan={5} rowSpan={3} className='!bg-white align-top'>
        <label>โปรโมชั่น</label>
        <pre className='border-0 italic'  style={{fontSize: 'clamp(11px, 0.7vw, 13px)',padding: '8px 10px'}} >{headValue?.promotion}</pre>
      </Table.Summary.Cell>
      <Table.Summary.Cell colSpan={2} align='end' className='!pe-4 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
      <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
        <Typography.Text type="danger">{ comma(Number(headValue?.price_total || 0),2,2) } บาท</Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
    {/* <Table.Summary.Row>
        <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ภาษีมูลค่าเพิ่ม { headValue?.vat > 0 && '(7%)'}</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
            <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(headValue?.vat || 0),2,2) } บาท</Typography.Text>
        </Table.Summary.Cell>
    </Table.Summary.Row>  */}
    {/* <Table.Summary.Row>
        <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ยอดรวมสุทธิ</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
          <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(headValue?.grand_total || 0),2,2) } บาท</Typography.Text> 
        </Table.Summary.Cell> 
    </Table.Summary.Row> */}
  </>);

  const totalPayment = (rec) => {
    if(rec?.length < 1 ) return <></>
    // console.log( rec )
    // const { payment_totals, payment_remain, payment_amount } = rec;
    const amount = rec?.reduce( (a, v) => a += Number(v?.payment_amount || 0), 0 ) ;
    const remain = Number( headValue?.price_total ) - amount;
    // const totals = rec?.reduce( (a, v) => a += Number(v?.payment_totals || 0), 0 ) ;
    return (<>
      <Table.Summary.Row> 
          <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดที่ต้องชำระ</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
            <Typography.Text className='text-black'>{ comma(Number( headValue?.price_total ),2,2) }</Typography.Text>
          </Table.Summary.Cell>
      </Table.Summary.Row> 
      <Table.Summary.Row> 
          <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>{remain < 0 && <span className='text-red-500 font-thin'>( ชำระเกิน )</span>} รวมยอดชำระ</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
            <Typography.Text type="danger">{ comma(amount,2,2) }</Typography.Text>
          </Table.Summary.Cell>
      </Table.Summary.Row> 
      <Table.Summary.Row> 
      { remain >= 0 
          ? <>
            <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดคงเหลือ</Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
              <Typography.Text className='text-red-500'>{ comma(remain,2,2) }</Typography.Text>
            </Table.Summary.Cell>          
          </>
          : <>
            <Table.Summary.Cell colSpan={3} align='start' className='!pe-4 align-top'>ยอดชำระเกิน</Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3} className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
              <Typography.Text className='text-green-700'>{ comma(remain * (-1),2,2) }</Typography.Text>
            </Table.Summary.Cell>          
          </>
      }
      </Table.Summary.Row> 
    </>)
  }

  React.useEffect(() => {
    const { head, list } = initeial;
    setHeadValue(head);
    
    const { payment_cond, multi_payments, price_total } = head;
    setPaysValue(multi_payments?.length > 0 ? multi_payments : [{
      uuid: uuidv4(),
      payment: payment_cond,
      money: Number(price_total),
      remark: "-"
    }]);
    // const newList = list?.flatMap( m => {
    //   let seq = 1;
    //   const { cat_stock_by_product, amount, stcode } = m
    //   if( Number( cat_stock_by_product || 0 ) === 1) {
    //       const newObj = {
    //           ...m,
    //           amount: 1, 
    //       } 
    //       return [...Array(Number(amount || 0 )).keys()].map( m => ({...newObj, stcode: `${stcode}/${seq++}`, id: uuidv4()})); 
    //   } else return m; 
    // });
    // setListValue([...newList]);

    const newList = list?.map( m => {
      let seq = 1;
      const { cat_stock_by_product, amount, stcode } = m
      if( Number( cat_stock_by_product || 0 ) === 1) {
        const newObj = {
            ...m,
            amount: 1,
            sub_item: true,
        }
        const child =  [...Array(Number(amount || 0 )).keys()].map( m => ({...newObj, stcode: `${stcode}/${seq++}`, uuid: uuidv4()}) );
        m["children"] = child;
        return m; 

      } else return m;       
    });
    setListValue([...newList]);

    // console.log(  newList, list );
  }, [initeial]);

  return (
    <Space direction='vertical' className='w-full desc-repair-order'>
      <Row className='!mx-0' gutter={[24, 0]}>
        <Col className='!ps-0 !pe-0 lg:!pe-5' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
          <Row className='!mx-0' gutter={[24,24]}>
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {information}
            </Col>  
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {customer}
            </Col>
          </Row>
        </Col> 
        <Col className='!ps-0 !pe-0' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row className='!mx-0' gutter={[24,0]}>
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {payments} 
            </Col>
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {car} 
            </Col>
          </Row>
        </Col> 
        {/* <Col className='!ps-0 !pe-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Row className='!mx-0' gutter={[24,0]}>
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {promotion} 
            </Col>
          </Row>
        </Col>  */}
        <Col className='!ps-0 !pe-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Row className='!mx-0' gutter={[24,0]}>
            <Col className='!ps-0' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {lists} 
            </Col>
          </Row>
        </Col> 
      </Row> 
    </Space>
  )
}
