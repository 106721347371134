/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Form, Input, Select, Space, Tag } from 'antd';
import { QctPageLayout } from '../../components/layout';
import { FormSearchValue, TableSearchValue  } from "../../components/form";
// import { Typography } from 'antd';
import { accessColumn } from './model';
import useSearch from '../../store/hook/use-search.hook';
import PurchaseOrderService from "../../service/PurchaseOrder.service.js"; 
import OptionService from '../../service/Options.service.js';
import { Authenticate } from '../../service/Authenticate.service.js';

import dayjs from "dayjs";
import { TbTags } from 'react-icons/tb';
import { SelectPurchaseStatus } from '../../components/select/index.js';
 
const apirequest = PurchaseOrderService();
const optrequest = OptionService();
const auth = Authenticate();
function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("purchase-order-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria, 
    purdate: cria?.purdate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 

  const [optionsTag, setOptionsTag] = useState([]);

  const [viewOnly, setViewOnly] = useState(false);
  const [editOnly, setEditOnly] = useState(false);
  const [printOnly, setPrintOnly] = useState(false);
  const [selected, setSelected] = useState(undefined);


  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบสั่งซื้อ" name="purcode" >
          <Input placeholder="ใส่เลขใบสั่งซื้อ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสผู้ขาย" name="supcode" >
          <Input placeholder="ใส่รหัสผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ขาย" name="supname" >
          <Input placeholder="ใส่ชื่อผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่สั่งซื้อ' name='purdate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่รับของ' name='duedate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}> 
        <Form.Item label='ประเภทรายการซื้อ' name='tags'>
            <Select 
              mode='multiple'
              tagRender={(props) => <Tag {...props} color='var(--primary)' icon={<TbTags />} className='flex gap-1 items-center' >{props.label}</Tag>}
              allowClear
              autoClearSearchValue={false}
              style={{ width: '100%', height: '40px' }}
              placeholder="เลือกประเภทรายการซื้อ"
              maxTagCount= 'responsive'
              options={optionsTag.map( m => ({ label:m.text, value:m.text }))}
              getPopupContainer={() => document.getElementById('area')}
            ></Select>
        </Form.Item>   
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}> 
        <Form.Item label='สถานะใบสั่งซื้อ' name='status'>
            <SelectPurchaseStatus />
        </Form.Item>   
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [purdate_form, purdate_to] = criteria?.purdate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [duedate_form, duedate_to] = criteria?.duedate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 

    const newPram = {...criteria, purdate_form, purdate_to, duedate_form, duedate_to};
    if( newPram?.status?.length < 1 ) delete newPram?.status;
    const parm = { criteria:newPram, tbparams };
    
    apirequest.search(parm, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleRowSelect = (record, key) => {  
    setPrintOnly( ( !['N', 'J'].includes( record?.approved_status ) && !!key) ); 
    setViewOnly( (record?.approved_status !== 'N' && !!key) ); 
    setEditOnly( 
      (record?.approved_status === 'N' && ['รออนุมัติ','รอรับสินค้า','ไม่อนุมัติ'].includes( record?.status) && !!key) || 
      (auth.allow("role_allow_edit") && ['รออนุมัติ','รอรับสินค้า','ไม่อนุมัติ'].includes( record?.status)) 
    )
    setSelected( !!key ? record : undefined );
  }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, record) => { 
    const config = { mode:"update", acname: `แก้ไข #${record?.purcode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = (code, record) => { 
    const config = { mode:"view", acname: `ใบสั่งซื้อ #${record?.purcode}`, record, code };
    navigate("manage", {state:{config}});
  }
  
  const handlePrintsData = (code, record) => { 
    // const url = `/purchase-order-print-pdf/${code}`;
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = url;
    const url = `/purchase-order-print/${code}`;
    // const newWindow = window.open('', url, url);
    const newWindow = window.open(url, '_blank', 'width=960,height=820' ); 
    // const url = `/purchase-order-print/${code}/1`;
    // const newWindow = window.open('', url, url);
    newWindow.location.href = url;
  }

  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
      const [ tagRes ] = await Promise.all([
        optrequest.optionsSupplierTag({p:"tag-type"})
    ]);

    const {data:tags} = tagRes.data; 
    setOptionsTag( tags );

    const { order, field } = tbparamsDefault;
    const cols = accessColumn();
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  return (<>
  <QctPageLayout title={"ใบสั่งซื้อสินค้า"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการข้อมูลใบสั่งซื้อสินค้า'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        onSelectedRow={handleRowSelect}
        onCreate={handleCreatData}
        onUpdate={ editOnly && !!selected && handleUpdateData}
        onReView={ viewOnly && !!selected && handleReviewData}
        onPrints={ printOnly && !!selected && handlePrintsData}
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"purcode",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access