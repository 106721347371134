import { Button } from 'antd'
import React from 'react'
import MyDrawer from './MyDrawer'
import { TbPlus } from 'react-icons/tb';

export default function MyDrawerButton({ value, selects=[], text="เลือกสต๊อกสินค้า", disabled=false, buttonProp = {}, container = undefined }) {
    const [show, setShow] = React.useState( false );
    
    const hancleOpen = () => {
        // console.log( 111  )
        setShow(true);
    }
    
    const hancleConfirmed = (v) => { 
        if( typeof value === 'function'){
            value( v ); 
        }
    }
    return (
        <div id="btn-billing-payment" > 
            <Button 
                icon={<TbPlus style={{fontSize:'1.4rem'}}/>} 
                className='bn-center bn-success-outline'
                onClick={() => hancleOpen()}
                disabled={disabled}
                {...buttonProp}
            >{text}</Button> 

            { !!show && <MyDrawer 
                show={show} 
                close={()=>setShow(false)} 
                values={hancleConfirmed} 
                selected={selects} 
                getContainer={()=>!!container && document.getElementById(container)} 
            /> }
        </div>
    )
}
