import { requestService as api} from "./Request.service"  
const API_URL = { 
  API_MANAGE_NORMAL: `/adjust-stock/normal.php`,
  API_MANAGE_TIRES: `/adjust-stock/tires.php`,
};

const AdjustStockService = () => { 
  
  const adjustNormal = (parm = {}, config = {}) => api.post(`${API_URL.API_MANAGE_NORMAL}`, parm, config);
  const adjustTires = (parm = {}, config = {}) => api.post(`${API_URL.API_MANAGE_TIRES}`, parm, config);
  return {
    adjustNormal,
    adjustTires,
  };
};

export default AdjustStockService;