/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Dropdown, Flex, Form, Input, message, Popover, Space, Typography } from 'antd';
import { QctPageLayout } from '../../components/layout/index.js';
import { FormSearchValue, TableSearchValue  } from "../../components/form/index.js";
// import { Typography } from 'antd';
import { 
  accessColumn, 
} from './model.js';
import useSearch from '../../store/hook/use-search.hook.js';
// import BillingNoteService from "../../service/BillingNote.service.js"; 
import RepairOrderService from "../../service/RepairOrder.service.js"; 
import dayjs from "dayjs"; 
import { BillingPaymentButton } from '../../components/drawer/billing-payment/index.js';
import { TbSearchProvider } from '../../store/context/table-search.context.js';
import { TbCaretDownFilled, TbFilePencil, TbFileSearch, TbHelpCircle, TbPencil } from 'react-icons/tb';
import { comma } from '../../utils/util.js';
import useConfirm from '../../store/hook/use-confirm.hook.js';
import useStorage from '../../store/hook/use-storage.hook.js';
import { RepairOrderBillingPaymentButtonView } from '../../components/drawer/repair-order-billing-payment';

// const apirequest = BillingNoteService();

const reprequest = RepairOrderService();
function Access() {
  const confirms = useConfirm();
  const navigate = useNavigate(); 
  const stSearch = useSearch("billing-note-access");
  const stStorage = useStorage("billing-note-print");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria,
    bndate: cria?.bndate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 
 
  const [viewOnly, setViewOnly] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const [dataSelected, setDataSelected] = useState([]); 

  const handleMenuClick = (e) => {
    const { key } = e;
    switch(key) {
      case 'all': 
        handleCreatData([]); 
      break;
      case 'select': 
        handleCreatData(dataSelected);      
      break;
      case 'print-all':
        handlePrintAllData();
      break;
      case 'print-select':
        stStorage.updateStorage(dataSelected);


        const a = stStorage.gettingStorage(false);
        console.log( a );
        handlePrintSlcData();
      break;
      default: //
    }
    
    console.log('click', e);
  }

  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบวางบิล" name="bncode" >
          <Input placeholder="ใส่เลขใบวางบิล" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label='วันที่ใบวางบิล' name='bndate'>
          <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label='วันที่กำหนดชำระ' name='payment_duedate'>
            <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสลูกค้า" name="cuscode" >
          <Input placeholder="ใส่รหัสลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อลูกค้า" name="cusname" >
          <Input placeholder="ใส่ชื่อลูกค้า" />
        </Form.Item>
      </Col>
            
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col> 
    </FormSearchValue>
  );


  const renderBncodeCell = (v, rec) => {
    const { bncode } = rec
    return <>
      { !!bncode
        ? 
        <Popover 
          placement="topRight" 
          title="แนะนำการใช้งาน" 
          content={<>คลิกเพื่อดูรายละเอียดข้อมูลใบวางบิล</>}
          arrow={{
            pointAtCenter: true,
          }}
        >
          <Typography.Link className='!m-0 hover:!underline' onClick={()=>handleViewData(v, rec)} >{bncode}</Typography.Link>
        </Popover>
        : <>{'\u00A0'}</> 
      }
    
    </>
  }

  const renderRpocodeCell = (v, rec) => {
    const { rpocode } = rec
    return <>
      { !!rpocode
        ? 
        <Popover 
          placement="topRight" 
          title="แนะนำการใช้งาน" 
          content={<>คลิกเพื่อดูรายละเอียดข้อมูลใบรายการซ่อม</>}
          arrow={{
            pointAtCenter: true,
          }}
        >
          <Typography.Link className='!m-0 hover:!underline' onClick={()=>handleReviewData(v, rec)} >{rpocode}</Typography.Link>
        </Popover>
        : <>{'\u00A0'}</> 
      }
    
    </>
  }

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [bndate_form, bndate_to] = criteria?.bndate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [duedate_form, duedate_to] = criteria?.duedate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 
    const billing_status = true;
    const newPram = {...criteria, bndate_form, bndate_to, duedate_form, duedate_to};
    const parm = { criteria:newPram, tbparams };
    const customPayload = { criteria:{...newPram, billing_status}, tbparams };
    reprequest.search( customPayload, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleMultiForCustomer = ( list, cuscode ) =>  {
    if( list?.length >= 1 && !list.some( s => s?.cuscode === cuscode ) ) {
      confirms.warn("การเลือกหลายรายการ ต้องเลือกรายการที่เป็นลูกค้าเดียวกันเท่านั้น!");
      return false;
    }    

    return true;
  }

  const handleRowSelect = (event, record, key) => { 

    let newSelectedRecord = [...dataSelected];
    const rowKey = key || "id";
    const recordKey = record[rowKey];
    // console.log( newSelectedRecord, rowKey, recordKey, record);
    const { cuscode } = record;



    if (event.ctrlKey && event.button === 0) { 
      if( !handleMultiForCustomer(newSelectedRecord, cuscode) ) return;

      newSelectedRecord = ( newSelectedRecord?.some( s => s[rowKey] === record[rowKey]) ) 
      ? newSelectedRecord.filter(rec => rec[rowKey] !== recordKey)
      : newSelectedRecord ;


      newSelectedRecord = [ ...new Set(
        ([...newSelectedRecord, record].map( s => JSON.stringify(s)))
      )].map( m => JSON.parse(m));


    } 
    else {  
      newSelectedRecord = !( newSelectedRecord?.some( s => s[rowKey] === record[rowKey]) ) 
      ? [record] 
      : newSelectedRecord?.length > 1 ? newSelectedRecord.filter(rec => rec[rowKey] === recordKey) : [];  
      
    }

 
    // console.log( newSelectedRecord );
    setDataSelected(newSelectedRecord);
    setSelected( !!key ? record : undefined ); 
    setViewOnly( !!record?.rpocode && !!key );
  }

  const handleCreatData = (data) => {
    const config = { mode:"create", acname: "สร้าง", select: data };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, bncode) => {
    const config = { mode:"update", acname: `แก้ไข #${bncode?.bncode}`, bncode, code };
    navigate("manage", {state:{config}});
  }
  
  const handleViewData = (code, bncode) => {
    const config = { mode:"view", acname: `ใบวางบิล #${bncode?.bncode}`, bncode, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = (v, rec) => {
    // const [rec] = dataSelected;
    const config = { mode:"view", acname: `ใบวางบิล #${rec?.rpocode}`, rec, code:rec?.rpocode, backTo:"/billing-note" };
    navigate("/repair-orders/manage", {state:{config}});
  }

  const handleViewPayment = (rec) => {
    const config = { mode:"view", acname: `ใบวางบิล #${rec?.rpocode}`, rec, code:rec?.rpocode };
    navigate("/billing-payment/manage", {state:{config}});
  }

  const handleDetailPayment = (rec) => {
    const config = { mode:"view", acname: `ใบวางบิล #${rec?.rpocode}`, rec, code:rec?.rpocode };
    navigate("/billing-payment/detail", {state:{config}});
  }
  
  const handlePrintsData = (code, bncode) => {
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print/${code}`;
    const url = `/billing-note-print/${code}`;
    const newWindow = window.open('', url, url, 'width=960,height=820');
    newWindow.location.href = url;
  }
  
  const handlePrintAllData = () => {
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print-all`;
    // const url = `/billing-note-print-all/1`;
    const url = `/billing-note-print-all`;
    // const newWindow = window.open('', url, url);
    const newWindow = window.open(url, '_blank', 'width=960,height=820' ); 
    newWindow.location.href = url;
  }
  
  const handlePrintSlcData = () => {
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print-all`;
    const url = `/billing-note-print-slc/${dataSelected?.length}`;
    // const newWindow = window.open('', url, url);
    const newWindow = window.open(url, '_blank', 'width=960,height=820' ); 
    newWindow.location.href = url;
  }

  const handleBillingPayment = async ( res ) => {
    try { 
      handleSearch();
      setDataSelected([]); 
    } catch ( e ) {
      message.error("เกิดข้อผิดพลาดทำรายการไม่สำเร็จ");
    }

  }
  
  const handleGetPopoverViewMessage = () => {
      if( dataSelected?.length < 1)  return ""
      else if( dataSelected?.length > 1)  return <>สามารถดูข้อมูลได้ทีละรายการเท่านั้น</>
      else return ""
  }

  const handleCustom = ( key, rec, iconStyle, bntStyle ) => {
    // console.log(  key, rec, iconStyle, bntStyle );
    return (<>
        <div style={bntStyle} >
          <Dropdown.Button menu={{
            items: [
              { label: 'สร้าง จากรายการทั้งหมด', key: 'all', },
              { label: 'สร้าง จากรายการที่เลือก', key: 'select', disabled: dataSelected?.length < 1, },
              { type: 'divider' },
              { label: 'พิมพ์ จากรายการทั้งหมด', key: 'print-all', },
              { label: 'พิมพ์ จากรายการที่เลือก', key: 'print-select', disabled: dataSelected?.length < 1, },
            ],
            onClick: handleMenuClick,
          }} 
          placement="bottomRight"  arrow icon={<TbCaretDownFilled  />} className='w-full btn-drop'>
            <TbFilePencil  style={{fontSize:'clamp(25px, 0.9vw, 1.7rem)'}}  /> สร้าง/พิมพ์ ใบวางบิล
          </Dropdown.Button>
        </div>

      <Popover
        placement="topRight" 
        title={
          !!handleGetPopoverViewMessage() && "แนะนำการใช้งาน" 
        }
        content={handleGetPopoverViewMessage()}
        arrow={{
          pointAtCenter: true,
        }} 
        trigger={"hover"}
      >
        <div>
          <RepairOrderBillingPaymentButtonView 
            buttonProp={{ 
              className:'bn-warning-outline', 
              icon:<TbFileSearch style={iconStyle} />,
            }} 
            selects={dataSelected[0]}
            text='ดูข้อมูล' disabled={dataSelected?.length !== 1}
            close={() => { 
              handleSearch();
             }}
          />          
        </div>
 
      </Popover>



      <BillingPaymentButton 
        selects={dataSelected} 
        disabled={dataSelected?.some( s => ['ชำระเงินครบแล้ว'].includes(s?.status) ) || dataSelected?.length < 1 } 
        value={handleBillingPayment}
      />
    </>)
  }

  const isSelected = (record) => dataSelected?.some( s => !!record?.rpocode && s?.rpocode === record?.rpocode );


  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
      

    const { order, field } = tbparamsDefault;
    const cols = accessColumn(renderBncodeCell, renderRpocodeCell);
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  const tableFooter = (r) => { 
    const total = dataSelected?.reduce( (a, v) => a += Number( v?.price_total ), 0 );
    return (<>
    <Flex className='w-full gap-10'>
      <Typography.Text strong className='block !min-w-40'>จำนวนรายการที่เลือก</Typography.Text>
      <Typography.Text strong className='block !min-w-16 text-end'> { comma( Number( dataSelected?.length || 0 ) ) }</Typography.Text>
      <Typography.Text strong >รายการ</Typography.Text>
    </Flex>
    <Flex className='w-full gap-10'>
      <Typography.Text strong className='block !min-w-40'>ยอดรวม</Typography.Text>
      <Typography.Text strong className='block !min-w-16 text-end'> { comma( total ) }</Typography.Text>
      <Typography.Text strong > บาท</Typography.Text>
    </Flex> 
    </>)
  }

  return (<>
  <TbSearchProvider>
    <QctPageLayout title={"การวางบิล"}>
        <Space direction='vertical' className='flex gap-4'>
          { formSearch }
            <TableSearchValue
              title={<>
                <span className='flex gap-1 items-center'>
                  รายการ 
                  <Popover 
                    placement="topLeft" 
                    title="แนะนำการใช้งาน" 
                    content={<>สามารถเลือกหลายรายการได้โดย กดปุ่ม <b>control + click</b> </>}
                    arrow={{
                      pointAtCenter: true,
                    }} 
                  >
                    <TbHelpCircle style={{fontSize: '1.2rem', cursor:'pointer'}} /> 
                  </Popover>
                </span>
              </>}
              pagingDefault={tbparamsDefault?.pagination}
              onPageChange={handlePaging} 
              onCustom={handleCustom}
              notSelect={true}
              tbProps={{
                rowKey:"rpocode",
                dataSource:dataSource,
                columns:columns,
                pagination: pageValue,
                rowClassName:(record) => (isSelected(record) ? 'ant-table-row-selected' : 'asdasd'),
                onRow:(record) => ({
                  onClick: (e) => handleRowSelect(e, record, "rpocode"), 

                }),
                footer:tableFooter
              }}
              />
        </Space>
    </QctPageLayout>
  </TbSearchProvider>
  </>)
}

export default Access