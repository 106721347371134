import { Button } from 'antd';
import React, { forwardRef } from 'react';
import MyDrawerView from './MyDrawerView';
import { TbReceiptDollar } from 'react-icons/tb';

const MyDrawerButtonView = forwardRef(({ value, close, selects = {}, text = "ดูรายละเอียด", disabled = false, buttonProp = {} }, ref) => {
    const [show, setShow] = React.useState(false);
    
    const handleOpen = () => {
        setShow(true);
    };
    
    const handleConfirmed = (v) => {
        if (typeof value === 'function') {
            value(v);
        }
    };

    return (
        <div id="btn-billing-payment-view">
            <Button
                ref={ref}
                icon={<TbReceiptDollar style={{ fontSize: '1.4rem' }} />}
                className='bn-center bn-info-outline'
                onClick={handleOpen}
                disabled={disabled}
                {...buttonProp}
            >
                {text}
            </Button>

            {!!show && (
                <MyDrawerView
                    show={show}
                    close={(v) => {
                        setShow(false);
                        if (typeof close === "function") close(v);
                    }}
                    value={handleConfirmed}
                    source={selects}
                />
            )}
        </div>
    );
});

export default MyDrawerButtonView;
