import React from 'react';
import { Descriptions, Divider, Space, Table, Typography } from 'antd';

import { info, columnRepairOrder, } from './model'; 
import { comma } from '../../utils/util';
import { TableSearchValue } from '../../components/form';
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูลใบรายการซ่อม</span> };

export default function ManageView({initeial}) {

  const [headValue, setHeadValue] = React.useState({});
  const [listValue, setListValue] = React.useState([]);
  

  const information = (<>
    <Divider {...dividerProp}>ข้อมูลใบรายการซ่อม</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={info(headValue)} />
  </>);


  const lists = (<>
      <Divider {...dividerProp}>รายละเอียด</Divider>
      <TableSearchValue
          title='รายการใบรายการซ่อม' 
          notSelect={true}
          tbProps={{ 
              rowKey:"rpocode",
              dataSource:listValue,
              columns:columnRepairOrder(),
              pagination: false,
              locale:{...emptyText},
              summary: (record) => totalValue(record)
          }}
      />
  </>);

const totalValue = (source) => {
  const totalPrice = source.reduce( (acc, val) => acc + Number(val.price_total), 0 );
  return (<>
    <Table.Summary.Row> 
        <Table.Summary.Cell colSpan={7} align='start' className='!pe-4 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
        <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
          <Typography.Text type="danger">{ comma(Number(totalPrice || 0),2,2) }</Typography.Text>
        </Table.Summary.Cell> 
    </Table.Summary.Row> 
  </>)  
} 

  React.useEffect(() => {
    // console.log( initeial );
    const { head, list } = initeial;
    setHeadValue(head);
    setListValue(list);
  }, [initeial]);

  return (
    <Space direction='vertical' className='w-full desc-billing-note'>
      {information}
      {lists} 
    </Space>
  )
}
