/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout'; 
import { Button, Form, message, Typography } from 'antd';
import dayjs from "dayjs";
import ManageForm from './MyManageForm';
import ManageView from './MyManageView';

import BillingNoteService from "../../service/BillingNote.service"; 
// import BillingPaymentService from "../../service/BillingPayment.service.js"; 
import OptionService from "../../service/Options.service.js"; 
import { TbSearchProvider } from '../../store/context/table-search.context';

import { formData } from "./model";
import { CancelButton } from '../../components/drawer/cancel';
import { ButtonCreateBililnNote } from '../../components/modal/billing-note';
import useSearch from '../../store/hook/use-search.hook.js';
import { TbPrinter } from 'react-icons/tb';
import useStorage from '../../store/hook/use-storage.hook.js';
 
const blnrequest = BillingNoteService();
// const apirequest = BillingPaymentService();
const optrequest = OptionService();

function Manage() {
  const stSearch = useSearch("billing-note-access");
  const stStorage = useStorage("billing-note-print");
  const { criteria, tbparams } = stSearch.gettingSearch();

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code, select } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData); 

  const [source, setSource] = React.useState([]);
  const [choose, setChoose] = React.useState([]);
  // const [header, setHeader] = React.useState({});

  const handleSubmit = async (value) =>{ 
    try {  
      const {head, list} = value;
      // setHeader(head);
      setChoose(list); 
  
      await blnrequest[mode]({head, list});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (v) => {

    try { 
      await blnrequest.deleted({comment:v, code});
      navigate(-1);
      message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    }   
  }

  const handleChoosed = (value) => {
    setSource(value);
    setChoose(value);
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( ["view", "update"].includes( mode) && !!code){
          const res = await blnrequest.get(code);
          const { data:{ head, list } } = res.data; 
          // console.log( res );
          if( !head ) return;
          head['bndate'] = !!head?.bndate ? dayjs(head?.bndate) : null;
          head['payment_duedate'] = !!head?.payment_duedate ? dayjs(head?.payment_duedate) : null;
          // head.payment_duedate = !!head.payment_duedate ? dayjs(head?.payment_duedate) : null;
          // setHeader(head);  

          setChoose(list);
          setFormValue({ head, list}); 
        } else if ( mode === "create" && select?.length > 0 ) {
          setFormValue({list: select});
        } else {
          const res = await optrequest.optionsBillingNotePost( { criteria, tbparams, p:"billingnote-for-new" } );
          const { data : { list } } = res.data; 
          setFormValue({list});
        }

        
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const handlePrintsRow = () => {
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print-all`;
    stStorage.updateStorage( choose );
    const url = `/billing-note-print/${code}`;
    const newWindow = window.open('', url, url);
    newWindow.location.href = url;
  }

  const acButton = (<>
  { mode !== "view" 
    ?<ButtonCreateBililnNote value={handleSubmit} source={source} selected={choose} formSet={form} disabled={source.length < 1} />
    :<>
      <Button 
        icon={<TbPrinter style={{fontSize: 'clamp(25px, 0.9vw, 1.7rem)', fontWeight: 200}} />} 
        style={{minWidth: 102, fontSize: 'clamp(12px, 0.7vw, 1rem)', display: 'flex', alignItems: 'center', fontWeight: 200}} 
        className='bn-info-outline'
        disabled={choose.length === 0}  
        onClick={()=>handlePrintsRow()}
      >
        <Typography.Text>พิมพ์</Typography.Text>
      </Button>    
      <CancelButton value={handleCancel} />
    </>
  }
  </>); 
 

  return (<>
    <QctPageLayout title={`ใบวางบิล - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <ManageView initeial={formValue} />
      : (<TbSearchProvider>
          <ManageForm choosed={handleChoosed} initeial={formValue} formSet={form} />
        </TbSearchProvider>)
    }
 
    </QctPageLayout>  
  </>)
}

export default Manage