import { Col, Divider, Form, Input, Row, Select, Space, Badge } from "antd";
import React, { useEffect } from "react";
import { PROVINCE_OPTIONS } from "../../utils/util";
import CustomerService from "../../service/Customer.service";

const customerService = CustomerService();

export default function CustomerManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
    else
    {
      
      form.setFieldValue('title_name', 'คุณ');
      customerService
      .getcode()
      .then((res) => {
        let { data } = res;
          form.setFieldsValue({
            cuscode: data,
          });
      })
      .catch((err) => {});
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item
              label="รหัสลูกค้าบุคคล"
              name="cuscode"
              rules={[formRole]}
            >
              <Input disabled placeholder="กรอกรหัสลูกค้าบุคคล" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="คำนำหน้าชื่อ" name="title_name">
              <Select
                size="large"
                showSearch
                filterOption={filterOption}
                placeholder="เลือกคำนำหน้าชื่อ" 
                options={[
                  {
                    value: "คุณ",
                    label: "คุณ",
                  },
                  // {
                  //   value: "นาง",
                  //   label: "นาง",
                  // },
                  // {
                  //   value: "นางสาว",
                  //   label: "นางสาว",
                  // },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="ชื่อ" name="firstname" rules={[formRole]}>
              <Input placeholder="กรอกชื่อ" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="นามสกุล" name="lastname" >
              <Input placeholder="กรอกนามสกุล" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="เลขประจำตัวประชาชน" name="citizen_id">
              <Input placeholder="กรอกเลขประจำตัวประชาชน" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="เบอร์โทร" name="tel">
              <Input placeholder="กรอกเบอร์โทร" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="อีเมล" name="email">
              <Input placeholder="กรอกอีเมล" />
            </Form.Item>
          </Col>
          <Divider {...dividerProp}>ที่อยู่</Divider>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="ที่อยู่" name="address">
              <TextArea rows={3} placeholder="กรอกที่อยู่" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="province" label="จังหวัด">
              <Select
              placeholder="เลือกจังหวัด" 
                style={{ height: 40 }}
                showSearch
                filterOption={filterOption}
                options={PROVINCE_OPTIONS}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="รหัสไปรษณีย์" name="zipcode">
              <Input placeholder="กรอกรหัสไปรษณีย์" />
            </Form.Item>
          </Col>
          <Divider {...dividerProp}>เพิ่มเติม</Divider>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="หมายเหตุ" name="remark">
              <TextArea rows={3} placeholder="กรอกหมายเหตุ" />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
