import { Typography } from "antd";
import { comma } from "../../../utils/util";


const cellNoEdit = (clickCell) => ({
  onCell: (record, index) => ({
    style: { cursor:'pointer'},
    className: `field-edit`,
    onClick:()=>clickCell(record, index)
  })
});

export const columnDetail = (clickCell, options, rowSelect) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80, 
      ...cellNoEdit(clickCell),
      render: rowSelect,
    },
    {
      title: "สถานที่เก็บ",
      dataIndex: "location_code",
      key: "location_code", 
      align: "left",
      width: "25%",
      editable: true,
      required: true,
      type:'select',
      options,
      childProps: { showSearch: true },
      render: (v) => {
        const txt = options?.find( f  => f.value === v )?.label;
        return !!txt ? txt : <Typography.Text className="m-0 text-gray-300 font-normal">เลือกข้อมูล</Typography.Text>
      },
    },
    {
      title: "จำนวน",
      dataIndex: "qty",
      key: "qty", 
      width: "15%",
      align: "right",
      className: "!pe-4",
      ...cellNoEdit(clickCell),
      render: (_, rec) => <>{ comma( Number(rec?.qty ||  0), 2, 2 )}</>,
    },
    {
      title: "จำนวนที่ต้องการปรับปรุง",
      dataIndex: "adjust_qty",
      key: "adjust_qty", 
      width: "15%",
      align: "right",
      className: "!pe-4",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.adjust_qty ||  0), 2, 2 )}</>,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark", 
      align: "left", 
      editable: true,
    },
];

export const columnsDetailsEditable = ( handleSave, column ) => {
    const cols = column;
    const cmm  =  cols.map((col, ind) => { 
      if (!col.editable) return col;  

      return {
        ...col,
        onCell: (record) => {
          // console.log(record);
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            fieldType: !!col?.textArea,
            required: !!col?.required,
            type: col?.type || "input",
            className: '',
            optionsItems: col.options,
            childProps: col?.childProps || {},
          };
        },
      };
  
    });
    // console.dir( cmm );
    return cmm;
  };