import { Flex, Typography } from "antd";
import { comma } from "../../../utils/util";
import { TagStockStatus } from "../../badge-and-tag";
import { AdjustStockButtonLink } from "../../drawer/adjust-stock";
import { AdjustStockVatButtonLink } from "../../drawer/adjust-stock-vat";
 
export const columnStock = () => [
    {
        title: "รหัสสินค้า",
        dataIndex: "stcode",
        key: "stcode",
        width: '10%',
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อสินค้า",
        dataIndex: "stname",
        key: "stname", 
        align: "left",
        sorter: true,  
    },    
    {
        title: "สถานที่เก็บ",
        dataIndex: "location_name",
        key: "location_name", 
        align: "left",
        sorter: true, 
        width: '20%',
    },
    {
        title: "ราคาซื้อเฉลี่ย",
        dataIndex: "price",
        key: "price", 
        align: "right",
        sorter: true, 
        width: '15%', 
        onCell: (_) => {
            return { className: '!pe-5'}
        },
        render:(v) => comma(Number( v || 0), 2, 2),
    },
    {
        title: "สต๊อก",
        dataIndex: "location_qty",
        key: "location_qty",
        align: "left",
        sorter: true,
        width: '15%',
        render:(v) => <TagStockStatus result={v} />
    },  
];

export const columnStockTire = () => [
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: '10%',
    align: "left",
    sorter: true, 
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname", 
    align: "left",
    sorter: true,  
  },    
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_name",
    key: "location_name", 
    align: "left",
    sorter: true, 
    width: '15%',
  },
  {
    title: "ปีที่ผลิต",
    dataIndex: "mfyear",
    key: "mfyear",
    width: '10%',
    render:(v) => !!v && v !== 'n/a' ? v : "N/A"
  },
  {
    title: "ไตรมาส",
    dataIndex: "quarter",
    key: "quarter",
    width: '10%',
    render:(v) => !!v && v !== 'n/a' ? `Q${v}` : "N/A"
  },  
  {
    title: "ราคา",
    dataIndex: "price",
    key: "price",
    align: "right",
    sorter: true,
    width: '10%',
    onCell: (_) => {
      return { className: '!pe-5'}
    },
    render:(v) => comma(Number( v || 0), 2, 2),
  },
  {
    title: "สต๊อก",
    dataIndex: "tires_qty",
    key: "tires_qty",
    align: "left",
    sorter: true,
    width: '10%',
    render:(v) => <TagStockStatus result={v} />
  },
];


export const totalValue = ( record, val, stock, adjustCallback) => { 
  
  const { stcode, stcode_vat } = record;
  return [
    {
      key: 'total_stock',
      label: 'สต๊อกจริง',
      children: <Flex gap={14}>
        <Typography.Text>{comma(Number(val?.total_stock) || "-")}</Typography.Text>
        <AdjustStockButtonLink value={adjustCallback} record={record} source={stock} code={stcode} />
      </Flex>,
    },
    {
      key: 'total_stock_vat',
      label: 'สต๊อก VAT',
      children: <Flex gap={14}>
        <Typography.Text>{comma(Number(val?.total_stock_vat) || "-")}</Typography.Text>
        <AdjustStockVatButtonLink value={adjustCallback} record={record} source={stock} code={stcode_vat} />
    </Flex>,  
    },
]};