import { Flex, message, Select, Typography } from 'antd'
import React from 'react'
// import { filterOption } from '../../../utils/util'
import { TagIs } from '../../badge-and-tag';

export default function MySelect({name="vat", style={}, className="", onChange, value=null }) {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() =>{ 
    const init = async () => {
      try{ 
        const optionDataRes = [
          {key: 0, label: 'ไม่มี VAT'}, 
          {key: 1, label: 'มี VAT'},  
        ]; 
        const opnNew = optionDataRes.map( v => ({
            value: v?.key, 
            label: <>
            <Flex className='flex gap-4 justify-start items-center'>
              <div className='min-w-2'>
                <TagIs result={Number(v?.key)} className={` `} />

              </div>
              <Typography.Text>{v?.label}</Typography.Text>
            </Flex>
            </>,
            record: v, 
        }));
        setOptions(opnNew);
      } catch ( e ){
        console.log(e);
        message.error("เกิดข้อผิดพลาด");
      } 
    }

    init(); 
  }, []);

  const handleChange = (e, res) => {
    if( typeof onChange === "function") {
      onChange(e, res);
    }
  }


  return <Select
    defaultValue={value}
    style={{...{ width: '100%', height: '40px' }, ...style}}
    name={name}
    placeholder="เลือกVAT"
    className={className}
    // showSearch
    allowClear
    // filterOption={filterOption}
    options={options}
    onChange={handleChange}
  />
}
