import { Typography } from "antd";
import { comma } from "../../../utils/util";
import dayjs from "dayjs";

const cellNoEdit = (clickCell) => ({
  onCell: (record, index) => ({
    style: { cursor:'pointer'},
    className: `field-edit not-input`,
    onClick:()=>clickCell(record, index)
  })
});

export const columnDetail = (clickCell, options, rowSelect) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 60,
    ...cellNoEdit(clickCell),
    render: rowSelect,
  },
  {
    title: "สัปห์ดาที่ผลิต",
    dataIndex: "mfdate",
    key: "mfdate", 
    width: 80, 
    editable: true, 
    type:'date',
    options,
    childProps: { style:{ width: "100%", height:32 }, format:'w', maxDate:dayjs(), placeholder:'N/A', picker:"week" },
    render: (v) => {
      return !!v ? <>W{dayjs(v).format("w")}</> : <>N/A</>
    },
  },
  {
    title: "ปีที่ผลิต",
    dataIndex: "mfyear",
    key: "mfyear", 
    width: 80, 
    className: "not-input",
    ...cellNoEdit(clickCell),
    render: (v) => {
      return !!v ? v : <>N/A</>
    },
  },
  {
    title: "ไตรมาส",
    dataIndex: "quarter",
    key: "quarter", 
    className: "not-input",
    width: 80,
    ...cellNoEdit(clickCell),
    render: (v) => !!v ? <>Q{v}</> : <>N/A</>
  }, 
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_code",
    key: "location_code",
    align: "left",
    width: "15%",
    editable: true,
    required: true,
    type:'select',
    options,
    id:"loc-focus",
    childProps: { showSearch: true },
    render: (v) => {
      const txt = options?.find( f  => f.value === v )?.label;
      return !!txt ? txt : <Typography.Text className="m-0 text-gray-300 font-normal">เลือกข้อมูล</Typography.Text>
    },
  },
  {
    title: "จำนวน",
    dataIndex: "qty",
    key: "qty", 
    width: 80,
    align: "right",
    className: "!pe-4",
    ...cellNoEdit(clickCell),
    render: (_, rec) => <>{ comma( Number(rec?.qty ||  0), 2, 2 )}</>,
  },
  {
    title: "จำนวนที่ปรับปรุง",
    dataIndex: "adjust_qty",
    key: "adjust_qty", 
    width: 120,
    align: "right",
    className: "!pe-4",
    editable: true,
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.adjust_qty ||  0), 2, 2 )}</>,
  },
  {
    title: "หมายเหตุ",
    dataIndex: "remark",
    key: "remark", 
    align: "left", 
    className: "asdasdasd",
    style: { minWidth: 100 },
    cellStyle: { minWidth: 100 },
    editable: true, 
  },
];

export const columnsDetailsEditable = ( handleSave, column ) => {
    const cols = column;
    const cmm  =  cols.map((col, ind) => { 
      if (!col.editable) return col;  

      return {
        ...col,
        onCell: (record) => {
          // console.log(record);
          return {
            record,
            style: col.cellStyle,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            fieldType: !!col?.textArea,
            required: !!col?.required,
            type: col?.type || "input",
            id: col?.id || "",
            // className: col?.className || "",
            optionsItems: col.options,
            childProps: col?.childProps || {},
          };
        },
      };
  
    });
    // console.dir( cmm );
    return cmm;
  };